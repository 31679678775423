import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Image,
  Container,
  Spinner,
  Modal,
} from "react-bootstrap";
import { SubmitMediaForVote } from "../../utils/vote";
import ImageModal from "./ImageModal";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "../../utils/firebase";
import { firestore, storage } from "../../utils/firebase";
import { useRouter } from "../../utils/router";
import ScoreBar from "../ScoreBar";
import "./ImageUpload.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import QualityAPILogo from "../../assets/icons/everypixel_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const QUALITY_API_URL =
  "https://us-central1-peach-314203.cloudfunctions.net/data-getPhotoQualityScore";

function ConfirmDelete(props) {
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This will delete all the images in this group and the corresponding
          ones in the Orchard if you have submitted them for community feedback.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={props.onConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ImageDetails({ uid, imgDoc, setShowGallery }) {
  const router = useRouter();
  const imgData = imgDoc.data();
  const docId = imgDoc.id;
  const { image_count, community_doc_id } = imgData;
  const [selectedImg, setSelectedImg] = useState(null);
  const [goodPercent, setGoodPercent] = useState(0);
  const [badPercent, setBadPercent] = useState(0);
  const [isLoadingQS, setLoadingQS] = useState(false);
  const [qualityScore, setQualityScore] = useState(null);
  const [qualityScoreCached, setQualityScoreCached] = useState(false);
  const [remove, setRemove] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [voteData] = useDocument(
    firebase.firestore().doc(`community_images/${community_doc_id}`)
  );

  const handleClick = (index, item) => {
    setSelectedImg(item.props.children.props?.src);
  };

  const handleConfirm = () => {
    setModalShow(false);
    setRemove(true);
  };

  const handleDelete = () => {
    [...Array(image_count).keys()].forEach((e) => {
      const imageFileName = imgData[e].file_name;
      const imageRef = storage.ref(`${uid}/${imageFileName}`);
      imageRef.delete().then(() => {
        console.log("image files permanently deleted");
      });
    });

    firestore
      .collection("images")
      .doc(`/${uid}/images/${docId}`)
      .delete()
      .then(() => {
        firestore
          .collection("community_images")
          .doc(community_doc_id)
          .delete()
          .then(() => {
            router.history.go(0);
          });
      });
  };

  const getQualityScore = async (url, uid, doc_id) => {
    const response = await axios
      .get(QUALITY_API_URL, {
        params: {
          photo_url: url,
          doc_id: doc_id,
          uid: uid,
        },
      })
      .catch((err) => {
        console.log("get photo quality score error: " + err);
      });

    if (response.data.status === "ok") {
      setQualityScore(response.data.quality.score);
      setLoadingQS(false);
    }
  };

  useEffect(() => {
    if (!qualityScoreCached && qualityScore) {
      firestore
        .collection("images")
        .doc(`/${uid}/images/${docId}`)
        .get()
        .then((doc) => {
          if ("photo_quality_score" in doc.data()) {
            setQualityScoreCached(true);
          }
        });
    }
    if (remove && qualityScoreCached) {
      handleDelete();
    }
  }, [remove, qualityScoreCached, isLoadingQS]);

  useEffect(() => {
    if (!qualityScore) {
      setLoadingQS(true);
    }
    if (uid && docId && !qualityScore) {
      if ("photo_quality_score" in imgData) {
        setQualityScore(imgData["photo_quality_score"]);
        setLoadingQS(false);
        setQualityScoreCached(true);
      } else {
        getQualityScore(imgData[0].url, uid, docId);
      }
    }
  }, [uid, docId, qualityScore, imgData]);

  useEffect(() => {
    if (voteData && voteData.data() && "votes" in voteData.data()) {
      const votes = voteData.data().votes;
      const totalVotes = Object.keys(votes).length;

      //thumbs up === 1
      setGoodPercent(
        Math.round(
          (Object.values(votes).filter((e) => e === 1).length / totalVotes) *
            100
        )
      );

      //thumbs down === 0
      setBadPercent(
        Math.round(
          (Object.values(votes).filter((e) => e === 0).length / totalVotes) *
            100
        )
      );
    }
  }, [voteData]);

  return (
    <>
      <ConfirmDelete
        show={modalShow}
        onCancel={() => setModalShow(false)}
        onConfirm={() => handleConfirm()}
      />
      <div>
        <Row className="d-flex align-items-center mx-5">
          <Col lg={6}>
            <Carousel
              showThumbs={false}
              onClickItem={(index, item) => handleClick(index, item)}
              // centerMode={true}
              className="carousel"
            >
              {[...Array(image_count).keys()].map((e) => (
                <div key={e} className="carousel-child">
                  <img src={imgData[e].url} alt="user uploaded image" />
                </div>
              ))}
            </Carousel>
          </Col>
          <Col
            lg={6}
            className="image-details-wrapper d-flex flex-column p-3 justify-content-between rounded my-5"
          >
            <div>
              <div className="d-flex flex-row align-items-baseline justify-content-between mb-n2">
                <h3>Details</h3>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  className="pointer"
                  onClick={() => setModalShow(true)}
                />
              </div>
              <hr></hr>
              <Container>
                <Row>
                  <Col>
                    <div className="my-2 h6">Impact Score:</div>
                  </Col>
                  <Col>
                    <div>
                      <em>coming soon!</em>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="my-2 h6">Photo Quality Score:</div>
                  </Col>
                  <Col>
                    <div className="quality-score-bar p-0">
                      {isLoadingQS && !qualityScore && (
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <div className="ml-2">calculating...</div>
                        </div>
                      )}
                      {!!Math.round(qualityScore * 100) && (
                        <div
                          className="quality-score"
                          style={{
                            width: `${Math.round(qualityScore * 100)}%`,
                          }}
                        >
                          <strong>{Math.round(qualityScore * 100)}%</strong>
                        </div>
                      )}
                      {!isLoadingQS && !Math.round(qualityScore * 100) && (
                        <div className="text-center">
                          <strong>{Math.round(qualityScore * 100)}%</strong>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>{}</Col>
                  <Col>
                    <div className="d-flex flex-row align-items-center">
                      <div className="my-2">powered by </div>
                      <Image className="ml-2 api-logo" src={QualityAPILogo} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            {voteData && voteData.data() ? (
              <div>
                <hr></hr>
                <p className="p-0 m-0">Based on community votes:</p>
                <ScoreBar goodPercent={goodPercent} badPercent={badPercent} />
              </div>
            ) : (
              <div>
                <hr></hr>
                <SubmitMediaForVote
                  data={imgData}
                  uid={uid}
                  collectionName="community_images"
                  docId={docId}
                  mediaType="image"
                />
              </div>
            )}
            <Button
              onClick={() => setShowGallery(true)}
              className="bg-transparent text-dark p-0 px-2"
            >
              <strong>
                <u>BACK</u>
              </strong>
            </Button>
            <div className="score-foot-note my-4">
              <small>
                * If there are more than one photo in the group, only the first
                photo&apos;s impact and quality scores are computed. Photo
                Quality Score, as its name implies, measures the{" "}
                <em>technical quality</em> of the photo, i.e., lighting,
                contrast, noisiness and so on, basically how <em>well</em> the
                photo is taken. It does <em>NOT</em> attempt to say anything
                about how attractive the subject in the photo may look.
              </small>
            </div>
          </Col>
        </Row>
        {selectedImg && (
          <ImageModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}
      </div>
    </>
  );
}
