import React from "react";
import NotFoundImg from "../assets/images/notFound.svg";

function NotFoundPage() {
  return (
    <div
      className="d-flex justify-content-center text-center align-item-center flex-column"
      style={{ height: "100vh", width: "100%" }}
    >
      <div>
        <h1>Oh no! Page not found. </h1>
        <img
          className="mt-5"
          style={{ width: "50%" }}
          src={NotFoundImg}
          alt="not found"
        />
      </div>
    </div>
  );
}
export default NotFoundPage;
