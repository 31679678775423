import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Section from "./Section";
import SectionHeader from "../components/SectionHeader";

export default function Quote(props) {
  return (
    <Section bg={props.bg} textColor={props.textColor} size={props.size}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg={3} sm={12}>
            <img src={props.image} alt="cartoon" className="img-fluid my-3" />
          </Col>
          <Col lg={6} sm={12}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={2}
              spaced={true}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
