import axios from "axios";
import { useData } from "../utils/db.js";

//cloud functions
const UPLOAD_URL =
  "https://us-central1-peach-314203.cloudfunctions.net/function-3";
const FETCH_PREDICT_MATCH_RATE =
  "https://us-central1-peach-314203.cloudfunctions.net/function-10";
const UPDATE_MESSAGE_DATA =
  "https://us-central1-peach-314203.cloudfunctions.net/edit_message";

export const COLLECTION_MAP = {
  "registered": {
    "Tinder": "registered_data",
    "Hinge": "registered_data_hinge"
  },
  "anonymous": {
    "Tinder": "anonymous_data",
    "Hinge": "anonymous_data_hinge"
  }
};

// FIRESTORE fields
const FETCH_FUNNEL_DATA = "fetch_funnel_data";
const FETCH_SMOOTH_USAGE_DATA = "fetch_smoothed_usage_data";
const FETCH_USAGE_BY_PERIOD = "fetch_daily_usage_histo_data";
const FETCH_MESSAGE_TEXT_DETAIL_HISTO_DATA =
  "fetch_message_text_detail_histo_data";
const FETCH_MESSAGE_HISTO_DATA = "fetch_message_histo_data";
const FETCH_BIFURCATE_MSGS_FIRST = "fetch_bifurcate_msgs_first";
const FETCH_BIFURCATE_MSGS_MIDDLE = "fetch_bifurcate_msgs_middle";

// Class to contain promise and response
class DataRequestContainer {
  constructor(funcName, funcField, dataQuery, successCallback) {
    this.successCallback = successCallback;
    this.data = null;
    this.pending = true;

    let self = this;

    switch (dataQuery.status) {
      case "idle":
        // Return null user until we have db data to merge
        self.pending = true;
        break;
      case "loading":
        self.pending = true;
        break;
      case "error":
        // Log query error to console
        self.pending = false;
        console.error(funcName + " Error: " + dataQuery.error);
        break;
      case "success":
        // If user data doesn't exist we assume this means user just signed up and the createUser
        // function just hasn't completed. We return null to indicate a loading state.
        if (dataQuery.data === null) {
          self.pending = true;
          console.error(funcName + " Error: data is empty");
          break;
        } else {
          self.pending = false;
          self.data = dataQuery.data[funcField];
          try {
            if (self.successCallback) successCallback(self);
          } catch (e) {
            console.log(e);
          }
          break;
        }
    }
  }

  // return true if request is still in progress
  isPending() {
    return this.pending;
  }

  // returns true if no longer pending and data exists
  isSuccessful() {
    return !this.isPending() && this.data;
  }
}

// class for cloud functions
class BackendApi {
  constructor({ user, dataType, demoUID=null }) {
    this.uid = user && user.uid;
    this.collection = COLLECTION_MAP['registered'][dataType];
    console.log(this.collection);
    this.queryData = null;
    this.demoMode = false;
    if (demoUID !== null){
      this.demoMode = true;
      this.uid = demoUID;
    }
  }

  fetchData() {
    this.queryData = useData(this.uid, this.collection);
  }
  /**** USER DATA ****/

  //upload_time, messages, usages, properties
  // user_properties: 'full_name', 'email', 'birth_date', 'gender', 'gender_filter', 'age_filter_max', 'age_filter_min', 'bio', 'ip_address', 'phone_number', 'phone_id', 'education', 'school_name','position_lat', 'position_lon', 'instagram_media_cnt', 'job_name','registration_platform', 'subscriptions', 'consumables', 'boosts','super_likes', 'super_likes_purchased', 'super_likes_used'

  static async uploadUserData(formData) {
    const response = await axios.post(UPLOAD_URL, formData).catch((err) => {
      console.log("error", err);
    });
    return response.data;
  }

  /**** MATCH RATE ****/

  //predicting match rates for the orchard
  // hard-coded predictions
  // gender_filter: 'Male' / 'Female' / 'Male and Female', age: numerical
  // still pass in u_df as a dummy argument so that we can use fetch_data_dec

  //selectedOptions = { gender: "Male", gender_filter: "Female",age: 24}
  async fetchPredictMatchRate(gender, gender_filter, age, idToken) {
    const response = await axios
      .get(FETCH_PREDICT_MATCH_RATE, {
        params: {
          doc_token: idToken,
          data_params: {
            gender,
            gender_filter,
            age,
          },
        },
      })
      .catch((err) => {
        console.log("FETCH_PREDICT_MATCH_RATE Error: " + err);
      });

    return response.data;
  }

  /**** FUNNEL DATA ****/

  //contacted matches, likes, number of dates, total matches, total swipes

  fetchFunnelData(successCallback) {
    return new DataRequestContainer(
      "FETCH_FUNNEL_DATA",
      FETCH_FUNNEL_DATA,
      this.queryData,
      successCallback
    );
  }

  /**** USAGE ****/

  //keys: date, value, variable( swipes, swipes_likes, swipes_passes, messages_received, messages_sent, match_rate, like_rate )
  fetchSmoothedUsageData(successCallback) {
    return new DataRequestContainer(
      "FETCH_SMOOTH_USAGE_DATA",
      FETCH_SMOOTH_USAGE_DATA,
      this.queryData,
      successCallback
    );
  }

  //aggregating swiping, messaging, and matching stats by weekday or by month
  //keys: month, weekday
  //values: like_rate, match_rate (in percentage/ matches per 100 swipes), matches, messages_received, messages_sent, swipes, swipes_likes, swipes_passes

  //stat cards
  fetchDailyUsageHistoData(successCallback) {
    return new DataRequestContainer(
      "FETCH_USAGE_BY_PERIOD",
      FETCH_USAGE_BY_PERIOD,
      this.queryData,
      successCallback
    );
  }

  /**** MESSAGE ****/

  //comparing successful and unsuccessful messages based on word count, emojis, punctuation, etc.
  //keys: continuing messages, first messages
  //values: successful, unsuccessful (emoji_count, exclamation_mark_cnt, msg_interval, question_mark_cnt, word_cnt)
  fetchMessageTextDetailHistoData(successCallback) {
    return new DataRequestContainer(
      "FETCH_MESSAGE_TEXT_DETAIL_HISTO_DATA",
      FETCH_MESSAGE_TEXT_DETAIL_HISTO_DATA,
      this.queryData,
      successCallback
    );
  }
  // aggregating message success metrics by time period
  // period: 'hour', 'weekday', 'month'
  //keys: hour, month, weekday
  //values: maybe_arrange_dates (number of messages where user try to set up a date), maybe_arrange_dates_rate (# of messages where user try to set up a date per 100 messages), maybe_first, maybe_middle, maybe_succ_first , maybe_succ_first_rate (per 100 first messages how many are successful), maybe_succ_middle, maybe_succ_middle_rate, maybe_succ_to_dates (# of successful messages to lead to a date), maybe_succ_to_dates_rate, message_id
  fetchMessageHistoData(successCallback) {
    return new DataRequestContainer(
      "FETCH_MESSAGE_HISTO_DATA",
      FETCH_MESSAGE_HISTO_DATA,
      this.queryData,
      successCallback
    );
  }

  // output words and success frequencies for word clouds
  // which_messages: 'first', 'middle'
  //keys: agg_fail_freq (abs number of messages that contain that word in successful matches - apple appear in three unsuccessful messages), agg_succ_freq (apple appear in 10 successful messages), fail_wd_freq, succ_wd_freq (how freq these words appear in successful (relative) ) -

  // use apple (succeed 3 out of 13 times)
  //values : hao, homeslice, ni

  //select top 30 for each list (Size and color determined by value)

  // which_messages  (param) -->first / middle-continuing
  fetchBifurcateMsgsFirst(successCallback) {
    return new DataRequestContainer(
      "FETCH_BIFURCATE_MSGS_FIRST",
      FETCH_BIFURCATE_MSGS_FIRST,
      this.queryData,
      successCallback
    );
  }

  fetchBifurcateMsgsMiddle(successCallback) {
    return new DataRequestContainer(
      "FETCH_BIFURCATE_MSGS_MIDDLE",
      FETCH_BIFURCATE_MSGS_MIDDLE,
      this.queryData,
      successCallback
    );
  }

  //edit message data
  async editMessageData(message_id, date_arranged, date_happened, idToken) {
    if (!this.demoMode) {
      const response = await axios
        .get(UPDATE_MESSAGE_DATA, {
          params: {
            doc_token: idToken,
            data_params: {
              message_id,
              date_arranged,
              date_happened,
            },
          },
        })
        .catch((err) => {
          console.log("UPDATE_MESSAGE_DATA Error: " + err);
        });
      return response.data;
    } else {
      return true;
    }
  }
}

export default BackendApi;
