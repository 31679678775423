import React from "react";
import useStorage from "../../utils/storage";
import { motion } from "framer-motion";

const ProgressBar = ({ file, uid, index, groupId, loaded }) => {
  const { progress } = useStorage(file, uid, index, groupId);

  if (!loaded) {
    return (
      <motion.div
        className="progress-bar"
        initial={{ width: 0 }}
        animate={{ width: progress + "%" }}
      ></motion.div>
    );
  } else {
    return null;
  }
};

export default ProgressBar;
