import React from "react";
import { Card } from "react-bootstrap";
import BackgroundImage from "../BackgroundImage";
import SectionHeader from "../SectionHeader";
import Section from "../Section";
import UseCaseImg1 from "../../assets/images/UseCaseCard1.svg";
import UseCaseImg2 from "../../assets/images/UseCaseCard2.svg";
import UseCaseImg3 from "../../assets/images/UseCaseCard3.svg";
import "./UseCasesSection.scss";

function UseCasesSection(props) {
  const UseCasesItems = [
    {
      text: "On which day of the week do I get the most matches per swipe? When should I ask for a date?",
      img_url: UseCaseImg1,
    },
    {
      text: "Did my new profile pictures give me a boost? What should I say to keep a conversation going?",
      img_url: UseCaseImg2,
    },
    {
      text: "Do I get more success with long messages or short ones? Am I using too much emojis?",
      img_url: UseCaseImg3,
    },
  ];
  return (
    <Section
      className="use-cases-section"
      bg={props.bg}
      size={props.size}
      textColor={props.textColor}
      title={props.title}
    >
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ flexWrap: "wrap" }}
      >
        {UseCasesItems.map((item, index) => (
          <Card className="use-case-card border-0 shadow m-3" key={index}>
            <Card.Body className="p-4 text-center">
              <Card.Text className="card-text mt-4">{item.text}</Card.Text>
              <BackgroundImage image={item.img_url} opacity="0.6" />
            </Card.Body>
          </Card>
        ))}
      </div>
    </Section>
  );
}

export default UseCasesSection;
