import React from "react";
import Section from "../Section";
import { Container, Row } from "react-bootstrap";
import HighlightCard from "./HighlightCard";
import MatchIcon from "../../assets/icons/match-icon.svg";
import ReplyIcon from "../../assets/icons/replies.png";
import WordIcon from "../../assets/icons/word-love.png";
import PageLoader from "../../components/PageLoader";
import { useSummaryStats } from "../../utils/data/sharedSummaryStats.js";
import { getRandomSubarray } from "../../utils/data/calculations";

export default function Highlights(props) {
  const {
    matchRate,
    replyRate,
    bestFirstMsgWord,
    bestUsagePeriodSearch,
    bestMsgPeriodSearch,
  } = useSummaryStats();

  const avgMatchRate = matchRate;
  const repliesRate = replyRate;
  const favoriteWord = bestFirstMsgWord;
  const favoriteWeekday =
    bestFirstMsgWord && bestUsagePeriodSearch("weekday", "match_rate");
  const favoriteHour =
    bestFirstMsgWord && bestMsgPeriodSearch("hour", "maybe_succ_first_rate");

  const totalNum = 5;
  const showNum = 4;
  const possibleIdx = [...Array(totalNum).keys()];
  const chosenIdx = getRandomSubarray(possibleIdx, showNum);

  return (
    <Section size={props.size} textColor={props.textColor}>
      <a name="peach-highlights" className="anchor">
        {/* anchor text [invisible] */}
      </a>
      <Container>
        <div className="title-header">
          <h2>{props.title}</h2>
          <hr></hr>
        </div>
        <Row className="justify-content-center p-5">
          {favoriteWord ? (
            <>
              {avgMatchRate !== undefined && chosenIdx.includes(0) && (
                <HighlightCard
                  size="small"
                  showProgressbar="true"
                  percentage={avgMatchRate}
                  title="Your Match Rate"
                  tipSubtitle="Your match rate is the percentage of people you like who also like you back."
                  // text={avgMatchRate}
                  bgColor="#8ac9f0"
                  icon={MatchIcon}
                />
              )}
              {favoriteWeekday && chosenIdx.includes(1) && (
                <HighlightCard
                  size="small"
                  bgColor="#8ac9f0"
                  showProgressbar="false"
                  title="Your Favorite Day of the Week"
                  tipSubtitle="This is the day of the week during which you have the highest match rate."
                  text={favoriteWeekday}
                />
              )}
              {repliesRate && chosenIdx.includes(2) && (
                <HighlightCard
                  size="small"
                  bgColor="#8ac9f0"
                  showProgressbar="true"
                  percentage={repliesRate}
                  title="Your Reply Rate"
                  tipSubtitle="Your reply rate is the the percentage of first messages sent by you to a match that lead to a conversation."
                  icon={ReplyIcon}
                />
              )}
              {favoriteWord && chosenIdx.includes(3) && (
                <HighlightCard
                  size="small"
                  bgColor="#8ac9f0"
                  title="Your most successful word"
                  text={favoriteWord}
                  tipSubtitle="When opening a conversation, including this word has gotten you more replies than any other word."
                  showProgressbar="false"
                  icon={WordIcon}
                />
              )}
              {favoriteHour && chosenIdx.includes(4) && (
                <HighlightCard
                  size="small"
                  bgColor="#8ac9f0"
                  showProgressbar="false"
                  title="Your Favorite Hour of the Day"
                  tipSubtitle="This is among the hours of the day during which you got the highest reply rate to your first messages."
                  text={favoriteHour}
                />
              )}
            </>
          ) : (
            <PageLoader />
          )}
        </Row>
      </Container>
    </Section>
  );
}
