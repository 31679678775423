import React from "react";
import AuthSection from "../components/AuthPageComponent/AuthSection";
import { useRouter } from "../utils/router.js";

function AuthPage() {
  const router = useRouter();

  return (
    <AuthSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      type={router.query.type}
      providers={["google", "facebook"]}
      afterAuthPath={router.query.next || "/peach"}
    />
  );
}

export default AuthPage;
