import React from "react";
import Section from "./Section";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import EmailIcon from "../assets/icons/email.svg";

import "./Footer.scss";

function Footer(props) {
  return (
    <Section
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
      style={{
        backgroundColor: props.bgColor,
      }}
    >
      <Container>
        <Row className="footer-component">
          <Col xs={12} lg={6} className="brand left mb-4">
            <Link to="/">
              <img src={props.logo} alt="Logo" />
            </Link>
            <Col xs={12} lg={6}>
              <p className="text-dark mt-3">{props.description}</p>
              <div className="copyright left">{props.copyright}</div>
            </Col>
          </Col>

          <Col xs={12} lg={6} className="d-flex footer-links flex-column">
            <div className="contact mb-3">
              <img src={EmailIcon} alt="email" />
              {"  "}
              {/* TODO: */}
              <a href="mailto:contact@peach-app.io" className="footer-link">
                contact@peach-app.io
              </a>
            </div>
            <Link className="footer-link" to="/peach">
              Launch Peach
            </Link>
            <Link className="footer-link" to="/orchard">
              The Orchard
            </Link>
            <Link className="footer-link" to="/about">
              About
            </Link>
            <Link className="footer-link" to="/PrivacyPolicy">
              Privacy Policy
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default Footer;
