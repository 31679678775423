import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../Section";
import OrchardForm from "./OrchardForm";
import Gauge from "../../utils/graphs/Gauge";
import ShareFeature from "../ShareFeature";

export default function AvgMatchRateSection(props) {
  const [selectedOptions, setSelectedOptions] = useState({
    gender: "Female",
    genderFilter: "Male",
    age: 18,
  });

  //FIXME: currently hardcoding model parameters in function
  const predictMatchRate = (props) => {
    const { gender, genderFilter, age } = props;

    let predictedRate =
      0.384609 -
      0.234291 * (gender == "Male") -
      0.004612 * age +
      0.165271 * (genderFilter == "Male") +
      0.127016 * (genderFilter == "Male and Female");

    if (predictedRate > 1) {
      predictedRate = 1;
    }
    if (predictedRate < 0.001) {
      predictedRate = 0.001;
    }
    return predictedRate * 100;
  };

  const defaultRate = predictMatchRate(selectedOptions);
  const [PredictedRate, setPredictedRate] = useState(defaultRate);

  return (
    <Section
      // bg={props.bg}
      size={props.size}
      textColor={props.textColor}
      className="predict-match-rate"
    >
      <Container>
        <div className="title-header">
          {/* <h2>{props.title}</h2> */}
          {/* <hr></hr> */}
          <h5>
            Find out the average match rates on Tinder based on the profile.
          </h5>
        </div>
        <Row>
          <Col className="orchard-form py-5" lg={5} sm={12}>
            <OrchardForm
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              setPredictedRate={setPredictedRate}
              updateFunction={predictMatchRate}
            />
          </Col>
          <Col lg={7} sm={12} className="py-5">
            <Gauge
              min={0}
              max={100}
              value={PredictedRate || 0}
              label="Average Match Rate (%)"
              units="Matches/ Like"
            />
            <div className="text-center mt-5">
              <ShareFeature
                text={`The average match rate for ${selectedOptions.gender.toLowerCase()}, age ${
                  selectedOptions.age
                }, interested in ${selectedOptions.genderFilter.toLowerCase()} is ${PredictedRate}%.`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
