import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: "AIzaSyARilREFbJ_3T3KQp7UAIpP2PQVaQdgTgg",
  authDomain: "peach-314203.firebaseapp.com",
  projectId: "peach-314203",
  storageBucket: "peach-314203.appspot.com",
  messagingSenderId: "317333602478",
  appId: "1:317333602478:web:6f4bba6d4a8f9707a46d3f",
  measurementId: "G-0RP32VCQE8",
});

export const auth = app.auth();
export const firestore = app.firestore();
export const storage = app.storage();
export const db = app.database();
export const functions = app.functions();
export const analytics = app.analytics();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export default firebase;
