import React from "react";
import { Form } from "react-bootstrap";

//check previous selected options so it doesn't call server when button is click and the options are not changed

export default function OrchardForm(props) {
  let { setSelectedOptions, selectedOptions, setPredictedRate, updateFunction } = props;

  const AllAgeOptions = [];

  const getAgeSelections = () => {
    for (let i = 18; i < 60; i++) {
      AllAgeOptions.push(i);
    }
  };

  getAgeSelections();

  const handleChange = (e, type) => {
    selectedOptions[type] = e.target.value;
    setSelectedOptions(selectedOptions);
    setPredictedRate(updateFunction(selectedOptions));
  };

  const formInputTypes = [
    { type: "gender", title: "Gender", options: ["Female", "Male"] },
    {
      type: "age",
      title: "Age",
      options: AllAgeOptions,
    },
    {
      type: "genderFilter",
      title: "Interested In",
      options: ["Male", "Female", "Male and Female"],
    },
  ];

  return (
    <>
      {formInputTypes.map((inputType) => {
        return (
          <Form.Group key={inputType.title} controlId={inputType.type}>
            <Form.Label>
              <small>
                <strong>{inputType.title}</strong>
              </small>
            </Form.Label>
            <Form.Control
              onChange={(e) => handleChange(e, inputType.type)}
              as="select"
              style={{ borderColor: "#FCDBCA", borderWidth: "2px" }}
            >
              {inputType.options.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        );
      })}
    </>
  );
}
