import React from "react";
import { Container } from "react-bootstrap";
import TopSectionHeader from "../components/TopSectionHeader";
import SectionHeader from "../components/SectionHeader";
import Section from "../components/Section";
import AboutCard from "../components/AboutPageComponent/AboutCard";
import GroupImage from "../assets/images/group.jpeg";
import TeamImage from "../assets/images/team.jpeg";
import DataImage from "../assets/images/data.jpeg";

function AboutPage() {
  return (
    <>
      <TopSectionHeader
        bgColor="#fef1e5"
        textColor="dark"
        size="md"
        title="About Peach"
        subtitle=""
      />
      <Section size="lg" bg="white" textColor="dark">
        <Container>
          <div className="mission-wrapper mb-5 pb-5">
            <SectionHeader
              title={
                "The goal of Peach is to help you gain value from the data you generate on dating apps."
              }
              size={3}
              className="text-center"
            />
          </div>
          <AboutCard
            title="Our goal"
            description="Use Peach to track your performance over time and look back to see what worked and what didn't. Ultimately, Peach aims to build a orchard of data-driven dating knowledge that can help members save time swiping and maximize matches and dates."
            image={GroupImage}
          />{" "}
          <AboutCard
            title="Our Team"
            description="Currently, Peach is a self-funded project. We are a team of engineers based in the US, Singapore, Switzerland, and France, with backgrounds in machine learning, quantum computing, quantitative finance, and behavioral science."
            image={TeamImage}
          />{" "}
          <AboutCard
            title="How Can I Contribute?"
            description="It's simple: start using Peach! You help us build more accurate recommendations and help grow the Orchard simply by using it. Oh, and feel free to tell your friends about us too!"
            image={DataImage}
          />
          {/* <EmailSubscribeSection
            buttonText={"Subscribe"}
            bgColor="#f8f2f2"
            size="md"
          />{" "} */}
          <div className="contact-wrapper py-5">
            <h3>Contact Us</h3>
            <h6>
              You can reach us at: <strong>contact@peach-app.io</strong>
            </h6>
          </div>
        </Container>
      </Section>
    </>
  );
}
export default AboutPage;
