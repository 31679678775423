import React, { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, Container } from "react-bootstrap";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import TopSectionHeader from "../components/TopSectionHeader";
import PeachIcon from "../assets/icons/peach.svg";
import Highlights from "../components/PeachDashboardComponent/HighlightsSection";
import QuizSection from "../components/PeachDashboardComponent/QuizSection";
import PerformanceSummarySection from "../components/PeachDashboardComponent/PerformanceSummarySection";
import KeyStatsSection from "../components/PeachDashboardComponent/KeyStatsSection";
import SideNavBar from "../components/SideNavBar";
import QuizIcon from "../assets/icons/quiz.svg";
import PerformanceIcon from "../assets/icons/performance.svg";
import KeyStatsIcon from "../assets/icons/stats.svg";
import WordCloud from "../utils/graphs/WordCloud";
import { SummaryProvider } from "../utils/data/sharedSummaryStats";
import firebase from "../utils/firebase";
import { useRouter } from "../utils/router";
import BackendApi from "../models/backend";
import AnonymousUserModal from "../components/PeachDashboardComponent/AnonymousUserModal";
import { useAuth } from "../utils/auth.js";
import TopNavBar from "../components/TopNavBar";

function PeachDashboardPage() {
  let { status } = useParams();
  const router = useRouter();
  const auth = useAuth();

  if (auth.user === false) {
    router.push("/");
  }

  const cachedAppType = Cookies.get("app_type");
  const [dataType, setDataType] = useState(cachedAppType);

  // these are set and may be re-set to retry api calls
  const [BifurcateMsgType, setBifurcateMsgType] = useState("first");
  // These are set only when api call succeed
  const [DailyUsageHistoData, setDailyUsageHistoData] = useState(null);
  let [funnelData, setFunnelData] = useState(null);
  const [MessageHistoData, setMessageHistoData] = useState(null);
  const [MessageTextDetailHistoData, setMessageTextDetailHistoData] =
    useState(null);
  const [BifurcateMsgsDataFirst, setBifurcateMsgsDataFirst] = useState(null);
  const [BifurcateMsgsDataMiddle, setBifurcateMsgsDataMiddle] = useState(null);
  const [SmoothedUsageData, setSmoothedUsageData] = useState(null);
  const [SmoothedUsageDataByDate, setSmoothedUsageDataByDate] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const timeoutLimit = 10 * 1000; //10 seconds

  let uid;
  if (status === "demo_mode") {
    uid = localStorage.getItem("demoUID");
  } else {
    uid = auth.user && auth.user.uid;
  }
  const [userData] = useDocument(firebase.firestore().doc(`users/${uid}`));

  let BA = new BackendApi({
    user: auth.user,
    dataType: dataType,
    demoUID: status && uid,
  });
  BA.fetchData();

  useEffect(() => {
    if (status) {
      setShowModal(true);
    }
  }, [status]);

  useEffect(() => {
    if (
      userData &&
      userData.data() !== undefined &&
      !("data_types" in userData.data())
    ) {
      var dataCheckTimer = setTimeout(() => {
        router.push({
          pathname: "/peach/upload",
          state: {
            noData: true,
          },
        });
      }, timeoutLimit);
    } else {
      if (
        userData &&
        userData.data() != undefined &&
        "data_types" in userData.data()
      ) {
        setDataType(Object.keys(userData.data()["data_types"])[0]);
      }
    }

    return () => {
      clearTimeout(dataCheckTimer);
    };
  }, [userData]);

  useEffect(() => {
    console.log("fetching daily usage histo data...");
    BA.fetchDailyUsageHistoData(function (container) {
      setDailyUsageHistoData(container.data);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("fetching funnel data...");
    BA.fetchFunnelData(function (container) {
      setFunnelData(container.data);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("fetching daily usage data...");
    BA.fetchSmoothedUsageData(function (container) {
      setSmoothedUsageData(container);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("running usage data by date...");
    if (
      SmoothedUsageData &&
      SmoothedUsageData.isSuccessful() &&
      Object.keys(SmoothedUsageData.data).length
    ) {
      fetchSmoothedUsageDataByDate();
    } else {
      setSmoothedUsageDataByDate([]);
    }
  }, [SmoothedUsageData]);

  useEffect(() => {
    console.log("fetching message histo data...");
    BA.fetchMessageHistoData(function (container) {
      setMessageHistoData(container.data);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("fetching message text detail histo data...");
    BA.fetchMessageTextDetailHistoData(function (container) {
      setMessageTextDetailHistoData(container.data);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("fetching world cloud data for first messages...");
    BA.fetchBifurcateMsgsFirst(function (container) {
      setBifurcateMsgsDataFirst(container.data);
    });
  }, [auth.user, BA.queryData]);

  useEffect(() => {
    console.log("fetching world cloud data for middle messages...");
    BA.fetchBifurcateMsgsMiddle(function (container) {
      setBifurcateMsgsDataMiddle(container.data);
    });
  }, [auth.user, BA.queryData]);

  const fetchSmoothedUsageDataByDate = () => {
    const lineGraphResults = SmoothedUsageData.data;
    const variableSet = lineGraphResults
      .map((e) => e.variable)
      .filter((v, idx, arr) => arr.indexOf(v) === idx);

    // convert retrieved data to date lookup
    let dateToDataDict = {};

    // assume keys are 0-n
    for (let i = 0; i < lineGraphResults.length; i++) {
      let key = lineGraphResults[i].date;
      let variable = lineGraphResults[i].variable;
      let value = lineGraphResults[i].value;

      let entry = {};
      if (key in dateToDataDict) {
        entry = dateToDataDict[key];
      } else {
        entry = variableSet.reduce((e, key) => ({ ...e, [key]: 0 }), {});
        dateToDataDict[key] = entry;
      }
      entry[variable] = value;
    }

    // get sorted list of dates from dict keys
    let dateArray = Object.keys(dateToDataDict).sort();

    // formulate eventual result structure
    let result = [];
    for (let i = 0; i < dateArray.length; ++i) {
      let dateKey = dateArray[i];
      let date = new Date(parseInt(dateKey));
      let formattedDateString =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

      let dataEntry = dateToDataDict[dateKey];
      dataEntry["date"] = formattedDateString;
      result.push(dataEntry);
    }
    setSmoothedUsageDataByDate(result);
  };

  const handleSelectDataType = (event) => {
    setDataType(event);
  };

  return (
    <>
      <AnonymousUserModal showModal={showModal} setShowModal={setShowModal} />
      <TopSectionHeader
        bgColor="#10162f"
        textColor="white"
        size="md"
        title="Peach"
        icon={PeachIcon}
        subtitle="Benefit from the wisdom of your own data on Peach!"
        subtitleColor="light"
      />
      <Row style={{ position: "sticky", zIndex: 1006, top: "88px" }}>
        <TopNavBar status={status} />
      </Row>
      <Row style={{ backgroundColor: "#f8f4eb" }}>
        <SideNavBar status={status} />
        <Col>
          {/* <TopNavBar status={status} /> */}
          <Container className="mt-5">
            {/* <TopNavBar status={status} /> */}
            <div className="title-header text-right mt-5">
              <h4>Select your data</h4>
              <DropdownButton
                id="dropdown-basic-button"
                title={dataType}
                onSelect={(e) => handleSelectDataType(e)}
              >
                {auth.user ? (
                  userData &&
                  userData.data()["data_types"] &&
                  Object.keys(userData.data()["data_types"]).map(
                    (dtype, idx) => (
                      <Dropdown.Item key={idx} eventKey={dtype}>
                        {dtype}
                      </Dropdown.Item>
                    )
                  )
                ) : (
                  <Dropdown.Item eventKey={cachedAppType}>
                    {cachedAppType}
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
            {/* <p>Select your data</p> */}
          </Container>
          <SummaryProvider
            appType={dataType}
            funnelData={funnelData}
            BifurcateMsgsDataFirst={BifurcateMsgsDataFirst}
            BifurcateMsgsDataMiddle={BifurcateMsgsDataMiddle}
            DailyUsageHistoData={DailyUsageHistoData}
            MessageHistoData={MessageHistoData}
            MessageTextDetailHistoData={MessageTextDetailHistoData}
          >
            <Highlights
              bg="#F8F4F2"
              bgColor="#FBA59F"
              textColor="dark"
              title="✨ Your highlights"
              size="lg"
              id="peach-highlights"
              SmoothedUsageDataByDate={SmoothedUsageDataByDate}
              DailyUsageHistoData={DailyUsageHistoData}
              BifurcateMsgsData={BifurcateMsgsDataFirst}
              MessageHistoData={MessageHistoData}
            />
            <QuizSection
              bg="#f8f4eb"
              textColor="dark"
              title="How well do you know about your own online dating?"
              size="md"
              icon={QuizIcon}
            />
            <PerformanceSummarySection
              bg="#f8f4eb"
              textColor="dark"
              title="How am I doing overall?"
              size="md"
              icon={PerformanceIcon}
              funnelData={funnelData}
              SmoothedUsageDataByDate={SmoothedUsageDataByDate}
            />
          </SummaryProvider>
          {/* <MessageVotingSection
            title="Community voting for your messages"
            size="md"
            dataType={dataType}
            BA={BA}
          /> */}
          <WordCloud
            title="Word cloud"
            size="md"
            BifurcateMsgsData={
              BifurcateMsgType == "first"
                ? BifurcateMsgsDataFirst
                : BifurcateMsgsDataMiddle
            }
            setBifurcateMsgType={setBifurcateMsgType}
            BifurcateMsgType={BifurcateMsgType}
          />
          <KeyStatsSection
            bg="#f8f4eb"
            textColor="dark"
            size="md"
            title={`Since you started ${dataType ?? ""}, here's some key stats`}
            icon={KeyStatsIcon}
            MessageTextDetailHistoData={MessageTextDetailHistoData}
            MessageHistoData={MessageHistoData}
            DailyUsageHistoData={DailyUsageHistoData}
          />
        </Col>
      </Row>
    </>
  );
}

export default PeachDashboardPage;
