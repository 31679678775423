import React from "react";
import { Container } from "react-bootstrap";
import Section from "../components/Section";
import CommunityVote from "../components/CommunityVote";
import OrchardPage from "../components/OrchardPageComponent/OrchardPage";

function Messages() {
  return (
    <Section size="lg">
      <Container>
        <CommunityVote
          mediaType="message"
          collectionName="community_messages"
        />
      </Container>
    </Section>
  );
}

function Images() {
  return (
    <Section size="lg">
      <Container>
        <CommunityVote mediaType="image" collectionName="community_images" />
      </Container>
    </Section>
  );
}

export const OrchardMessages = OrchardPage(Messages);
export const OrchardImages = OrchardPage(Images);
