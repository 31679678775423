import React from "react";
import { OverlayTrigger, Button, Tooltip, Image } from "react-bootstrap";
import HelpIcon from "../assets/icons/help.png";
import "./HelpfulTip.scss";

export default function HelpfulTip({
  iconWidth,
  tipTitle,
  tipSubtitle,
  smallText,
}) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="mytooltip">
          <div className="p-3">
            <h6 className="m-0 p-1">{tipTitle}</h6>
            <p className="m-0 p-1">{tipSubtitle}</p>
            <small>{smallText}</small>
          </div>
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          {...triggerHandler}
          className="d-inline-flex align-items-center m-0 p-0 bg-transparent"
        >
          <Image ref={ref} roundedCircle src={HelpIcon} width={iconWidth} />
        </Button>
      )}
    </OverlayTrigger>
  );
}
