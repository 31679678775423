import React, { useState, useEffect } from "react";
import { Container, Button, DropdownButton, Dropdown } from "react-bootstrap";
import BarChartComponent from "../../utils/graphs/BarChart.js";
import { weekdayKeysFull, monthKeysFull } from "../../utils/data/WeekMonth";
import PageLoader from "../PageLoader.js";
import ShareFeature from "../../components/ShareFeature";
export default function CardInsight({
  cardData,
  setShowInsight,
  cardInsightDataForRate,
}) {
  // duration = month, weekday, hour
  //show data for rate
  const inputDuration = cardData && cardData.resultPeriod;
  const [duration, setDuration] = useState(
    inputDuration !== "" ? inputDuration : "month"
  );
  const [contentType, setContentType] = useState("first");
  const [showRate, setShowRate] = useState(false);
  useEffect(() => {}, [duration, contentType, showRate]);

  if (showRate === true) {
    cardData = cardInsightDataForRate;
  }

  const availableDurations = [];
  const possibleDurations = ["month", "week", "hour"];
  possibleDurations.forEach((d) => {
    if (cardData[d] && cardData[d].length) {
      availableDurations.push(d);
    }
  });

  const handleSelect = (eventKey) => {
    if (eventKey === "month") {
      setDuration("month");
    } else if (eventKey === "week") {
      setDuration("week");
    } else if (eventKey === "hour") {
      setDuration("hour");
    } else if (eventKey === "first") {
      setContentType("first");
    } else if (eventKey === "continuing") {
      setContentType("continuing");
    }
  };

  const capFL = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleShowRate = () => {
    setShowRate(!showRate);
  };

  return (
    <Container>
      {cardData && Object.keys(cardData).length > 0 ? (
        <div>
          <div className="d-flex justify-content-between">
            <Button
              onClick={() => setShowInsight(false)}
              className=" bg-transparent text-dark p-0 px-2"
            >
              <strong>
                <u>BACK</u>
              </strong>
            </Button>

            <div className="d-flex">
              {cardData.generalType === "time" && (
                <DropdownButton
                  id="dropdown-basic-button"
                  title={capFL(duration)}
                  className="time-series-btn bg-transparent text-dark font-weight-bold p-0 ml-3 mb-4"
                  onSelect={handleSelect}
                >
                  {availableDurations.map((d, i) => (
                    <Dropdown.Item key={i} eventKey={d}>
                      {capFL(d)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}

              {cardData.generalType === "content" && (
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    contentType === "first"
                      ? "First Messages"
                      : "Continuing Messages"
                  }
                  className="time-series-btn bg-transparent text-dark font-weight-bold p-0 ml-3 mb-4"
                  onSelect={handleSelect}
                >
                  <Dropdown.Item eventKey="first">First Messages</Dropdown.Item>
                  <Dropdown.Item eventKey="continuing">
                    Continuing Messages
                  </Dropdown.Item>
                </DropdownButton>
              )}

              {cardInsightDataForRate &&
                Object.keys(cardInsightDataForRate).length > 0 && (
                  <Button
                    onClick={handleShowRate}
                    className="m-0 bg-transparent text-dark ml-3 p-0 px-2 mb-4"
                  >
                    <u>See Rate</u>
                  </Button>
                )}
            </div>
          </div>
          <h4 className="my-5">{cardData.cardInsightTitle}</h4>
          {cardData.generalType === "time" ? (
            <BarChartComponent
              barData={cardData[duration]}
              barKey={cardData.type}
              legendText={cardData.cardInsightTitle}
            />
          ) : (
            <BarChartComponent
              barData={
                contentType === "first"
                  ? cardData.first_msgs
                  : cardData.cont_msgs
              }
              barKey={cardData.type}
              legendText={cardData.cardInsightTitle}
            />
          )}

          <div className="my-3">
            {cardData.generalType === "time" &&
              cardData.highestMonthValue > 0 &&
              duration === "month" && (
                <div>
                  <p>
                    {`You are most successful` +
                      " in " +
                      monthKeysFull[cardData.highestMonthResult].name +
                      "."}
                  </p>
                  <ShareFeature
                    text={
                      "Based on your data for " +
                      cardData.cardInsightTitle +
                      ", you are most successful" +
                      " in " +
                      monthKeysFull[cardData.highestMonthResult].name +
                      " with a value of " +
                      Math.round(cardData.highestMonthValue) +
                      "."
                    }
                  />
                </div>
              )}
            {cardData.generalType === "time" &&
              cardData.highestWeekValue > 0 &&
              duration === "week" && (
                <div>
                  <p>
                    {`You are most successful` +
                      " on " +
                      weekdayKeysFull[cardData.highestWeekResult].name +
                      "."}
                  </p>
                  <ShareFeature
                    text={
                      `Based on your data for ${cardData.cardInsightTitle}, you are most successful` +
                      " on " +
                      weekdayKeysFull[cardData.highestWeekResult].name +
                      " with a value of " +
                      Math.round(cardData.highestWeekValue) +
                      "."
                    }
                  />
                </div>
              )}
            {cardData.generalType === "time" &&
              cardData.highestHourValue > 0 &&
              duration === "hour" && (
                <div>
                  <p>
                    {`You are most successful` +
                      " at " +
                      cardData.highestHourResult +
                      "."}
                  </p>
                  <ShareFeature
                    text={
                      `Based on your data for ${cardData.cardInsightTitle}, you are most successful` +
                      " at " +
                      cardData.highestHourResult +
                      " with a value of " +
                      Math.round(cardData.highestHourValue) +
                      "."
                    }
                  />
                </div>
              )}
            {cardData.generalType === "content" &&
              contentType === "first" &&
              cardData.highestFirstValue && (
                <div>
                  <p>
                    {"You have a higher average " +
                      cardData.cardInsightTitle.toLowerCase() +
                      " with " +
                      cardData.highestFirstResult +
                      " messages" +
                      "."}
                  </p>
                  <ShareFeature
                    text={
                      "Based on the messages you sent, you have a higher average " +
                      cardData.cardInsightTitle.toLowerCase() +
                      " with " +
                      cardData.highestFirstResult +
                      " messages" +
                      "."
                    }
                  />
                </div>
              )}

            {cardData.generalType === "content" &&
              contentType === "continuing" &&
              cardData.highestContValue && (
                <div>
                  <p>
                    {"You have a higher average " +
                      cardData.cardInsightTitle.toLowerCase() +
                      " with " +
                      `${cardData.highestContResult} messages` +
                      "."}
                  </p>
                  <ShareFeature
                    text={
                      "You have a higher average " +
                      cardData.cardInsightTitle.toLowerCase() +
                      " with " +
                      `${cardData.highestContResult} messages` +
                      "."
                    }
                  />
                </div>
              )}
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </Container>
  );
}
