import React from "react";
import "./AnonymousUserModal.scss";

export default function AnonymousUserModal({ showModal, setShowModal }) {
  const handleAcceptTerms = () => {
    setShowModal(false);
  };

  const renderModal = () => {
    return (
      <div className="p-3">
        <h4>You are viewing Peach in demo mode</h4>
        <hr color="white"></hr>
        <p>
          Welcome to Peach! Feel free to play around and explore all the different features!  We hope that by
          looking at the analytics for our beloved Mr. Jokester from Hinge and Ms. Jokester from Tinder, you are
          also inspired to use Peach to help you discover insights for your online dating with your very own data.
          Please note that some features are limited in demo mode, for example, you will not be able to submit messages to the
          Orchard, our community page, for voting and comments. Have fun!
        </p>
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            onClick={handleAcceptTerms}
            className="mr-2 p-0 pointer"
          />
          <p className="m-0 p-0">OK</p>
        </div>
      </div>
    );
  };
  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <div className="w-100" show={showModal} className="anonymous-user-modal">
      {showModal && renderModal()}
    </div>
  );
}
