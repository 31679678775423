import React, { useEffect, useState } from "react";
import { Container, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Select from "react-select";
import Section from "../Section";
import ConversionFunnel from "../../utils/graphs/ConversionFunnel";
import LineGraph from "../../utils/graphs/LineGraph";
import "./PerformanceSummarySection.scss";
import HelpfulTip from "../HelpfulTip";

export default function PerformanceSummarySection(props) {
  let {
    bg,
    textColor,
    title,
    icon,
    size,
    SmoothedUsageDataByDate,
    funnelData,
  } = props;
  const [chartType, setChartType] = useState("Conversion Funnel");
  const [lineGraphOptions, setLineGraphOptions] = useState([]);
  const [lineGraphKeys, setLineGraphKeys] = useState([]);
  const [lineGraphData, setLineGraphData] = useState(
    SmoothedUsageDataByDate.length > 0 && SmoothedUsageDataByDate
  );
  const [lineGraphDuration, setLineGraphDuration] = useState("all");
  const keyMap = {
    all: "All",
    matches: "Matches",
    swipes: "Swipes",
    swipes_likes: "Swipes Likes",
    swipes_passes: "Swipes Passes",
    messages_received: "Messages Received",
    messages_sent: "Messages Sent",
    match_rate: "Match Rate",
    like_rate: "Like Rate",
  };

  const defaultLineGraphDurationKeys = {
    all: "All",
    year: "1 Year",
    month: "1 Month",
    week: "1 Week",
  };
  const [lineGraphDurationKeys, setLineGraphDurationKeys] = useState(
    defaultLineGraphDurationKeys
  );

  // Category keys

  let lastYearUsage = [];
  let lastMonthUsage = [];
  let lastWeekUsage = [];
  let SmoothedUsageDataByDateCopy = [...SmoothedUsageDataByDate];

  const handleSelectCategory = (option) => {
    setLineGraphOptions(option);
  };
  const computeGraph = () => {
    // TODO: backend should take in category and time duration to return exact data to be rendered
    // create a copy

    if (SmoothedUsageDataByDateCopy.length > 0) {
      const lastDateUsingTinder =
        SmoothedUsageDataByDateCopy[
          SmoothedUsageDataByDateCopy.length - 1
        ].date.split("/");

      //handle multi select for line graph options and show only those categories in line graph
      let lineGraphOptionsKeys = lineGraphOptions.map(
        (option) => (option = option.value)
      );
      if (
        lineGraphOptionsKeys.length > 0 &&
        !lineGraphOptionsKeys.includes("all")
      ) {
        let res = [];
        for (let data of SmoothedUsageDataByDateCopy) {
          let dict = {};
          for (let key in data) {
            dict["date"] = data["date"];
            if (lineGraphOptionsKeys.includes(key)) {
              dict[key] = data[key];
            }
          }
          res.push(dict);
        }
        SmoothedUsageDataByDateCopy = res;
      }

      //get last year data
      lastYearUsage = SmoothedUsageDataByDateCopy.filter(
        (data) => data.date.split("/")[2] === lastDateUsingTinder[2]
      );

      //get last month data
      lastMonthUsage = SmoothedUsageDataByDateCopy.filter(
        (data) =>
          data.date.split("/")[0] === lastDateUsingTinder[0] &&
          data.date.split("/")[2] === lastDateUsingTinder[2]
      );

      //get last week data
      lastWeekUsage = SmoothedUsageDataByDateCopy.filter(
        (data) =>
          data.date.split("/")[1] < lastDateUsingTinder[1] &&
          data.date.split("/")[1] > lastDateUsingTinder[1] - 7 &&
          data.date.split("/")[0] === lastDateUsingTinder[0] &&
          data.date.split("/")[2] === lastDateUsingTinder[2]
      );
    }

    // Set duration keys
    if (lastYearUsage.length > 0) {
      lineGraphDurationKeys["year"] = defaultLineGraphDurationKeys["year"];
    } else {
      delete lineGraphDurationKeys["year"];
    }

    if (lastMonthUsage.length > 0) {
      lineGraphDurationKeys["month"] = defaultLineGraphDurationKeys["month"];
    } else {
      delete lineGraphDurationKeys["month"];
    }

    if (lastWeekUsage.length > 0) {
      lineGraphDurationKeys["week"] = defaultLineGraphDurationKeys["week"];
    } else {
      delete lineGraphDurationKeys["week"];
    }

    setLineGraphDurationKeys(lineGraphDurationKeys);

    // Set data to display
    if (lineGraphDuration === "year") {
      setLineGraphData(lastYearUsage);
    } else if (lineGraphDuration === "month") {
      setLineGraphData(lastMonthUsage);
    } else if (lineGraphDuration === "week") {
      setLineGraphData(lastWeekUsage);
    } else {
      setLineGraphData(SmoothedUsageDataByDateCopy);
    }
  };

  //fetch funnel data
  useEffect(() => {
    if (SmoothedUsageDataByDate && SmoothedUsageDataByDate.length > 0) {
      let fields = Object.keys(SmoothedUsageDataByDate[0]).filter(
        (v) => v != "date"
      );
      fields = [...fields, "all"];
      setLineGraphKeys(fields.map((f) => ({ value: f, label: keyMap[f] })));
      computeGraph();
    } else {
      setLineGraphData(null);
    }
  }, [
    SmoothedUsageDataByDate,
    funnelData,
    lineGraphDuration,
    lineGraphOptions,
  ]);

  //switch between funnel and line graph
  const switchGraph = async () => {
    if (chartType === "Conversion Funnel") {
      //if current graph is a funnel graph
      setChartType("Line Graph");
    } else {
      //if current graph is a line graph
      setChartType("Conversion Funnel");
    }
  };

  //switch between year, month, weekday, all from dropdown
  const handleSelectDuration = (key) => {
    setLineGraphDuration(key);
  };
  return (
    <Section bg={bg} textColor={textColor} size={size}>
      <a name="peach-summary" className="anchor">
        {/* anchor text [invisible] */}
      </a>
      <Container>
        <div className="title-header d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img width="35" src={icon} alt={title} className="mr-2 mb-2" />{" "}
            <h2>{title}</h2>
          </div>
        </div>
        <hr></hr>
        <div>
          <div className="d-flex justify-content-between my-5">
            <div>
              <Button
                className="switch-graph-btn m-0 bg-transparent text-dark "
                style={{ border: "1px solid #FBA59F" }}
                onClick={switchGraph}
              >
                <u>
                  See{" "}
                  {chartType === "Conversion Funnel"
                    ? "Trends"
                    : "Conversion Funnel"}
                </u>
              </Button>
              <HelpfulTip
                iconWidth="30"
                tipSubtitle={`${
                  chartType === "Line Graph"
                    ? "This line graph shows your performance since you started the app. Use them to identify time periods when you had the highest match rates and find periods when you were more or less selective."
                    : "This funnel summarizes your performance on the app so far. Use it to get a sense of your time spent swiping, how selective you are, how frequently someone likes you back, and how many of your matches lead to conversations and dates."
                }`}
                smallText={`${
                  chartType === "Line Graph"
                    ? "This plot is generated by applying a 30-day rolling average to the metric . *Hence the numbers you see are not integers and you might not see any number at all, if you have less than 30 days of data.The data point you see on a particular day is the average over its value on that day and previous 29 days when you have data."
                    : "NOTE: 'Number of Dates' is estimated by counting the number of occurences of words like 'meet', 'coffee', 'drink', or messages that involve mentions of social media like 'whatsapp', 'insta', 'facebook', and so on. If the number of times these words are mentioned exceeds a threshold, that conversation is counted as having led to a date."
                }`}
              />
            </div>
            {chartType === "Line Graph" && (
              <DropdownButton
                id="dropdown-menu-align-right"
                align="end"
                title={`Duration: ${lineGraphDuration}`}
                className="time-series-btn bg-transparent text-dark font-weight-bold mr-3"
                onSelect={handleSelectDuration}
                multiple
              >
                {Object.keys(lineGraphDurationKeys).map((key) => (
                  <Dropdown.Item key={key} eventKey={key}>
                    {lineGraphDurationKeys[key]}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
          </div>
          {chartType === "Line Graph" && (
            <>
              <div className="d-block">
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  className="w-100"
                  name="lineGraphCategory"
                  placeholder="All"
                  value={lineGraphOptions}
                  options={lineGraphKeys}
                  onChange={handleSelectCategory}
                />
              </div>
            </>
          )}
        </div>
        {chartType === "Conversion Funnel" ? (
          funnelData &&
          Object.keys(funnelData).length > 0 && (
            <ConversionFunnel conversionFunnelData={funnelData} />
          )
        ) : (
          <LineGraph width={730} height={400} LineGraphData={lineGraphData} />
        )}
        {/* <HelpfulFeature /> */}
      </Container>
    </Section>
  );
}
