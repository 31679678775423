import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import ImageUploadSection from "../components/ImageUploadComponent/ImageUploadSection";
import ImageIcon from "../assets/icons/image.png";
import TopSectionHeader from "../components/TopSectionHeader";
import PeachIcon from "../assets/icons/peach.svg";
import SideNavBar from "../components/SideNavBar";

function ImagesPage() {
  return (
    <>
      <TopSectionHeader
        bgColor="#10162f"
        textColor="white"
        size="md"
        title="Peach"
        icon={PeachIcon}
        subtitle="Benefit from the wisdom of your own data on Peach!"
        subtitleColor="light"
      />
      <Row style={{ backgroundColor: "#f8f4eb" }}>
        <SideNavBar />
        <Col>
          <Container className="mt-5">
            <ImageUploadSection
              bg="#f8f4eb"
              textColor="dark"
              title="Upload images to get feedback"
              icon={ImageIcon}
            />
          </Container>
        </Col>
      </Row>
    </>
  );
}

export default ImagesPage;
