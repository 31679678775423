import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import "../style.scss";
import ReactTimeAgo from "react-time-ago";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faEllipsisH,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import PeachBadge from "../../../assets/icons/peach_badge.svg";
import VerifiedBadge from "../../../assets/icons/verified-user.svg";
import TinderLogo from "../../../assets/icons/tinder-icon.png";
import HingeLogo from "../../../assets/icons/hinge-icon.png";
import { ActionContext } from "./ActionContext";

const app_style_map = {
  Tinder: { logo: TinderLogo, style: "app-stats-tinder" },
  Hinge: { logo: HingeLogo, style: "app-stats-hinge" },
};

const CommentStructure = ({ i, reply, parentId }) => {
  const actions = useContext(ActionContext);
  const edit = true;

  return (
    <div className="halfDiv">
      <div
        className="userInfo"
        style={reply && { marginLeft: 15, marginTop: "6px" }}
      >
        <div className="commentsTwo align-items-center">
          {/* <div>
            <img
              src={i.avatarUrl}
              style={{ width: 30, height: 30, borderRadius: 30 / 2 }}
              alt="userIcon"
            />
          </div> */}
          <div className="d-flex flex-row align-items-center">
            <img
              src={
                actions.profiles[i.userId] &&
                actions.profiles[i.userId]?.avatarUrl
                  ? actions.profiles[i.userId].avatarUrl
                  : i.avatarUrl
              }
              // src={i.avatarUrl}
              style={{ width: 35, height: 35, borderRadius: 35 / 2 }}
              alt="userIcon"
            />
            <div>
              <div className="fullName d-flex align-items-center">
                <div className="ml-2" style={{ fontWeight: "bold" }}>
                  {i.fullName}
                </div>
                <div
                  className="ml-2"
                  style={{ fontSize: "0.85em", color: "gray" }}
                >
                  <ReactTimeAgo
                    date={new Date(i.timestamp)}
                    locale="en-US"
                    timeStyle="round-minute"
                  />
                </div>
              </div>
              <div className="d-flex flew-row text-light align-items-center">
                {actions.profiles[i.userId] &&
                  actions.profiles[i.userId]?.summary_scores && (
                    <div
                      className={`${
                        app_style_map[
                          actions.profiles[i.userId].summary_scores.data_type
                        ].style
                      } d-flex flex-row ml-2`}
                    >
                      <Image
                        className="app-logo ml-2"
                        src={
                          app_style_map[
                            actions.profiles[i.userId].summary_scores.data_type
                          ].logo
                        }
                      />
                      <div className="match-score ml-1 px-1">
                        {"match:"}{" "}
                        <strong>
                          {Math.round(
                            actions.profiles[i.userId].summary_scores
                              ?.match_score
                          )}
                          %
                        </strong>
                      </div>
                      <div className="reply-score ml-1 pr-2">
                        {"reply:"}{" "}
                        <strong>
                          {Math.round(
                            actions.profiles[i.userId].summary_scores
                              ?.reply_score
                          )}
                          %
                        </strong>
                      </div>
                    </div>
                  )}
                {i.userId !== "removed" &&
                  actions.profiles[i.userId] &&
                  actions.profiles[i.userId]?.comments_karma && (
                    <div className="orchard-points d-flex flex-row ml-2 px-2">
                      <Image className="peach-badge" src={PeachBadge} />
                      <div>{actions.profiles[i.userId].comments_karma}</div>
                    </div>
                  )}
                {i.userId !== "removed" &&
                  actions.profiles[i.userId] &&
                  actions.profiles[i.userId]?.status && (
                    <div className="d-flex flex-row ml-2">
                      <Image className="verified-badge" src={VerifiedBadge} />
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column ml-5">
            <p className="comment-text">{i.text}</p>
            <div className="d-flex flex-row justify-content-between">
              <div className="mr-auto">
                <button
                  className="up-vote-button p-0 m-0"
                  onClick={() => actions.onVote(i.comId, parentId, 1)}
                  disabled={!actions.user}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    size="1x"
                    color={
                      i.votes && i.votes[actions.userId] > 0 ? "red" : "#a5a5a5"
                    }
                  />{" "}
                </button>
                <span className="vote-number mx-2">{i.totalUpvotes ?? 0}</span>
                <button
                  className="down-vote-button p-0 m-0"
                  onClick={() => actions.onVote(i.comId, parentId, -1)}
                  disabled={!actions.user}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    size="1x"
                    color={
                      i.votes && i.votes[actions.userId] < 0
                        ? "blue"
                        : "#a5a5a5"
                    }
                  />{" "}
                </button>
              </div>
              <div className="mx-5">
                <button
                  className="replyBtn mr-auto p-0 m-0"
                  // style={{ fontSize: "14px" }}
                  onClick={() => actions.handleAction(i.comId)}
                  disabled={!actions.user}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faReply}
                    size="sm"
                    color="#a5a5a5"
                  />{" "}
                  Reply
                </button>
              </div>
              {actions.userId === i.userId && actions.user && (
                <div>
                  <button
                    onClick={() => actions.handleAction(i.comId, edit)}
                    className="p-1 border-0 bg-transparent d-inline-flex align-items-center gray-text"
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      size="sm"
                      color="#a5a5a5"
                      className="mr-1"
                    />{" "}
                    Edit
                  </button>
                  <button
                    onClick={() => actions.onDelete(i.comId, parentId)}
                    className="p-1 border-0 bg-transparent d-inline-flex align-items-center gray-text"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="sm"
                      color="#a5a5a5"
                      className="mr-1"
                    />{" "}
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <DropdownButton
            id="dropdown-basic-button"
            className="dropdown-toggle bg-transparent text-dark font-weight-bold p-0 ml-3 mr-2"
            title = {<FontAwesomeIcon icon={faEllipsisV} size='1x' color='#b9b9b9' />}
            onSelect={() => actions.handleAction(i.comId, edit)}
          >
            <Dropdown.Item eventKey="edit">edit</Dropdown.Item>
          </DropdownButton> */}
      </div>
    </div>
  );
};

export default CommentStructure;

//
// <div className="halfDiv">
//       <div
//         className="userInfo"
//         style={reply && { marginLeft: 15, marginTop: "6px" }}
//       >
//         <div>{i.text}</div>
//         <div className="commentsTwo align-items-center">
//           <div>
//             <img
//               src={i.avatarUrl}
//               style={{ width: 30, height: 30, borderRadius: 30 / 2 }}
//               alt="userIcon"
//             />
//           </div>
//           <div className="fullName flex-column">
//             <div className="ml-2" style={{ fontWeight: "bold" }}>
//               {i.fullName}
//             </div>
//             <div className="ml-2" style={{ fontSize: "0.85em", color: "gray" }}>
//               <ReactTimeAgo
//                 date={new Date(i.timestamp)}
//                 locale="en-US"
//                 timeStyle="round-minute"
//               />
//             </div>
//           </div>
//           <div>
//             <button
//               className="replyBtn d-flex align-items-center justify-content-center"
//               style={{ fontSize: "14px" }}
//               onClick={() => actions.handleAction(i.comId)}
//               disabled={!actions.user}
//             >
//               {" "}
//               <FontAwesomeIcon
//                 icon={faReply}
//                 size="sm"
//                 color="#a5a5a5"
//                 className="mx-1"
//               />{" "}
//               Reply
//             </button>
//           </div>
//         </div>
//       </div>
//       <div>
//         {actions.userId === i.userId && actions.user && (
//           <Button
//             onClick={() => actions.handleAction(i.comId, edit)}
//             className="p-1 border-0 bg-transparent d-inline-flex"
//           >
//             <Image className="mx-4" src={editIcon} width="20" />
//           </Button>
//         )}
//         {/* <DropdownButton
//             id="dropdown-basic-button"
//             className="dropdown-toggle bg-transparent text-dark font-weight-bold p-0 ml-3 mr-2"
//             title = {<FontAwesomeIcon icon={faEllipsisV} size='1x' color='#b9b9b9' />}
//             onSelect={() => actions.handleAction(i.comId, edit)}
//           >
//             <Dropdown.Item eventKey="edit">edit</Dropdown.Item>
//           </DropdownButton> */}
//       </div>
//     </div>
