import React from "react";
import Section from "../Section";
import { calcReplyRate } from "../../utils/data/calculations";

export default function AppAccountInfo({
  textColor,
  bg,
  size,
  title,
  userAppData,
  bgColor,
  dataType,
  handleSelectCheckBox,
  selected,
}) {
  const { fetch_message_histo_data, fetch_funnel_data } = userAppData;

  const replyRate =
    fetch_message_histo_data && calcReplyRate(fetch_message_histo_data);

  const avgMatchRate =
    fetch_funnel_data &&
    Object.keys(fetch_funnel_data).length &&
    Math.round(
      (fetch_funnel_data.Total_Matches / fetch_funnel_data.Likes) * 100
    );

  const lastUpdated =
    userAppData &&
    userAppData.upload_time &&
    userAppData.upload_time.toDate().toDateString();

  return (
    <Section bg={bg} textColor={textColor} size={size}>
      <div className="p-5 rounded" style={{ backgroundColor: bgColor }}>
        <div className="title-header d-flex justify-content-between">
          <h2>{title}</h2>
        </div>
        <hr></hr>
        <div className="account-details">
          <p>
            <strong>Match Rate: </strong>
            {avgMatchRate}%
          </p>
          <p>
            <strong>Reply Rate: </strong>
            {replyRate}%
          </p>
          <p>
            <strong>Last Updated: </strong>
            {lastUpdated}
          </p>
          <hr></hr>
          <input
            type="checkbox"
            checked={selected}
            onChange={() => handleSelectCheckBox(dataType)}
            className="mr-2 p-0 pointer"
          />
          <span className="m-0 p-0">
            Use my summary stats from {dataType} when stats sharing is enabled.
          </span>
        </div>
      </div>
    </Section>
  );
}
