const conversionFunnelDataMap = {
  "Total_Swipes": {
    type: "Total_Swipes",
    label: "Swipes",
    amount: 0,
    text: "",
  },
  "Likes":
  {
    type: "Likes",
    label: "Likes",
    amount: 0,
    text: "",
  },
  "Total_Matches":
  {
    type: "Total_Matches",
    label: "Matches",
    amount: 0,
    text: "",
  },
  "Contacted_Matches":
  {
    type: "Contacted_Matches",
    label: "Contacted Matches",
    amount: 0,
    text: "",
  },
  "Number_of_Dates":
  {
    type: "Number_of_Dates",
    label: "Dates",
    amount: 0,
    text: "",
  },
};

export default conversionFunnelDataMap;
