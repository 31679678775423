import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import DoorImg from "../../assets/images/door.svg";

function IntroSection(props) {
  return (
    <Section
      size={props.size}
      textColor={props.textColor}
      style={{ backgroundColor: `${props.bg}` }}
    >
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={6}>
            <div className="text-center text-lg-left">
              <img src={DoorImg} alt="door" width="400" />
            </div>
          </Col>
          <Col lg={6} className="text-left">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              subtitleSize={4}
              spaced={true}
            />
            <div className="text-left">
              <p>Know yourself better</p>
              <p>
                Empower yourself with patterns and actionable insights from your
                very own usage data
              </p>
              <p>Make your data work for your learning and growth</p>
            </div>
          </Col>
        </Row>
        <Row className="text-right mt-3">
          <Col>
            <Link to={props.buttonPath}>
              <Button
                renderas="button"
                variant={props.buttonColor}
                size="md"
                className="mt-4"
              >
                {props.buttonText}
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
export default IntroSection;
