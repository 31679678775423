import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  Legend,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PageLoader from "../../components/PageLoader";

export default function LineGraph(props) {
  const { width, height, LineGraphData } = props;

  const strokeColors = [
    "#E93B81",
    "#FBA59F",
    "#A03C78",
    "#CCCBF8",
    "#022E57",
    "#3EDBF0",
  ];

  const renderLines = () => {
    const lines = Object.keys(LineGraphData[0])
      .filter((e) => e !== "date")
      .map((value, index) => (
        <Line
          key={value}
          name={value.replaceAll("_", " ")}
          type="linear"
          dataKey={value}
          stroke={strokeColors[index]}
          strokeWidth={2}
          dot={false}
        />
      ));
    return lines;
  };

  const renderLegendText = (value) => {
    const legend = value.includes("rate") ? value + " (in %)" : value;
    return <span style={{ fontSize: "14px" }}>{legend}</span>;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip shadow-custom p-2 bg-white">
          <p className="label">{label}</p>
          {payload.map((category) => (
            <div key={category.name}>
              <small style={{ color: category.color }}>
                {category.name}: {category.value}
              </small>
              <br></br>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className="my-5"
      style={{
        position: "relative",
        height: "400px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      >
        {LineGraphData && LineGraphData.length > 0 ? (
          <ResponsiveContainer width="95%" height="100%" className="mx-auto">
            <LineChart
              width={width}
              height={height}
              data={LineGraphData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="date" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend formatter={renderLegendText} iconType="6" />
              {renderLines()}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <PageLoader />
        )}
      </div>
    </div>
  );
}
