export const weekdayKeysFull = {
  Mon: { name: "Monday" },
  Tue: { name: "Tuesday" },
  Wed: { name: "Wednesday" },
  Thu: { name: "Thursday" },
  Fri: { name: "Friday" },
  Sat: { name: "Saturday" },
  Sun: { name: "Sunday" },
};

export const monthKeysFull = {
  Jan: { name: "January" },
  Feb: { name: "February" },
  Mar: { name: "March" },
  Apr: { name: "April" },
  May: { name: "May" },
  Jun: { name: "June" },
  Jul: { name: "July" },
  Aug: { name: "August" },
  Sep: { name: "September" },
  Oct: { name: "October" },
  Nov: { name: "November" },
  Dec: { name: "December" },
};
