import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Spinner,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Section from "../Section";
import correctAnswerIcon from "../../assets/icons/correct-answer.svg";
import incorrectAnswerIcon from "../../assets/icons/wrong-answer.svg";
import DiceIcon from "../../assets/icons/dice.png";
import QuizWinnerImg from "../../assets/images/quizCongrats.svg";
import ShareFeature from "../../components/ShareFeature";
import { useSummaryStats } from "../../utils/data/sharedSummaryStats.js";
import GetQuestions from "../../utils/data/QuizQuestions.js";
import { getRandomSubarray } from "../../utils/data/calculations";
import "./QuizSection.scss";

const Q_SHOWN_NUM = 6;

export default function QuizSection({ bg, textColor, title, size, icon }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isCorrectAnswer, setCorrectAnswer] = useState(null);
  const [isIncorrectAnswer, setIncorrectAnswer] = useState(null);
  const [points, setPoints] = useState([]);
  const [quizResult, setQuizResult] = useState("");
  const [isAnswered, setIsAnswered] = useState(false);
  const [QuestionsList, setQuestionList] = useState([]);
  const totalCorrect = points.filter((point) => point === true).length;
  const [shuffleState, setShuffle] = useState(false);

  const summaryStats = useSummaryStats();

  useEffect(() => {
    const { bestFirstMsgWord } = summaryStats;
    if (bestFirstMsgWord) {
      const allQs = GetQuestions(summaryStats);
      setQuestionList(
        getRandomSubarray(allQs, Math.min(Q_SHOWN_NUM, allQs.length))
      );
    }

    setCurrentQuestionIndex(0);
    setCorrectAnswer(null);
    setIncorrectAnswer(null);
    setPoints([]);
    setIsAnswered(false);
  }, [summaryStats, shuffleState]);

  const percentCorrect = (totalCorrect / QuestionsList.length).toFixed(2) * 100;

  // go to next question
  const setNextQuestion = () => {
    if (currentQuestionIndex < QuestionsList.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (currentQuestionIndex === QuestionsList.length) {
      resetQuiz();
    }
    setCorrectAnswer(null);
    setIncorrectAnswer(null);
    setQuizResult("");
    setIsAnswered(false);
  };

  //return to previous question
  const goBackQuestion = () => {
    if (
      currentQuestionIndex <= QuestionsList.length &&
      currentQuestionIndex > 0
    ) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    setCorrectAnswer(null);
    setIncorrectAnswer(null);
    setQuizResult("");
    setIsAnswered(false);
  };

  //check answers and show correct or not
  const checkAnswer = (e, index) => {
    if (isAnswered === true) return;
    if (QuestionsList[currentQuestionIndex].answer === e.target.value) {
      setCorrectAnswer(index);
      setQuizResult("Correct!");
      points[currentQuestionIndex] = true;
      setPoints(points);
    } else {
      const answer = QuestionsList[currentQuestionIndex].answer;
      let correctAnswerIndex =
        QuestionsList[currentQuestionIndex].options.indexOf(answer);
      setCorrectAnswer(correctAnswerIndex);
      setIncorrectAnswer(index);
      setQuizResult("Not quite:");
      points[currentQuestionIndex] = false;
      setPoints(points);
    }
    setIsAnswered(true);
  };

  const resetQuiz = () => {
    if (currentQuestionIndex === QuestionsList.length - 1) {
      const tempArr = new Array(QuestionsList.length);
      setPoints(tempArr);
    }
  };

  return (
    <Section size={size} bg={bg} textColor={textColor}>
      <a name="peach-quiz" className="anchor">
        {/* anchor text [invisible] */}
      </a>
      <Container>
        <div className="title-header">
          <div className="d-flex align-items-center">
            <img width="40" src={icon} alt={title} className="mr-2 mb-2" />
            <h2>{title}</h2>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="mt-3 mr-3">
              Question{" "}
              {currentQuestionIndex < QuestionsList.length
                ? currentQuestionIndex + 1
                : QuestionsList.length}{" "}
              of {QuestionsList.length}
            </h6>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="mytooltip">
                  <h6 className="m-0 p-1">shuffle to see new questions</h6>
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  {...triggerHandler}
                  onClick={() => setShuffle(!shuffleState)}
                  className="p-1 border-0 bg-transparent d-inline-flex"
                >
                  <Image ref={ref} src={DiceIcon} width="30" />
                </Button>
              )}
            </OverlayTrigger>
          </div>
        </div>
        <hr></hr>
        <div className="quiz-wrapper p-5 d-flex flex-column justify-content-between text-center">
          {currentQuestionIndex !== 0 &&
            QuestionsList.length > 0 &&
            currentQuestionIndex === QuestionsList.length && (
              <div className="last-page-quiz">
                <h5>Congrats! You have answered all questions. 🚀</h5>
                <h4 className="mt-5">
                  You got <strong>{percentCorrect}% </strong> of the answers
                  correct. ( {totalCorrect} / {QuestionsList.length} )
                </h4>{" "}
                <div className="my-5">
                  <img width="300" src={QuizWinnerImg} alt="winner" fluid />
                </div>
                <h5
                  className="text-dark font-weight-bold mr-5 mt-5"
                  onClick={goBackQuestion}
                >
                  <u>BACK</u>
                </h5>
              </div>
            )}
          {currentQuestionIndex !== QuestionsList.length &&
            QuestionsList.length > 0 && (
              <>
                <div className="questions">
                  <h4>{QuestionsList[currentQuestionIndex].question}</h4>
                  <div className="d-flex mx-auto flex-wrap py-5 justify-content-around">
                    {QuestionsList[currentQuestionIndex].options.map(
                      (optionItem, index) => (
                        <div className="quiz-btn" key={optionItem}>
                          <Button
                            id="option-btn"
                            className="my-2"
                            value={optionItem}
                            onClick={(e) => checkAnswer(e, index)}
                          >
                            {optionItem}
                          </Button>
                          {isCorrectAnswer !== null &&
                            index === isCorrectAnswer && (
                              <img
                                className="quiz-icon"
                                src={correctAnswerIcon}
                                alt="correct-answer"
                              />
                            )}
                          {isIncorrectAnswer !== null &&
                            index === isIncorrectAnswer && (
                              <img
                                className="quiz-icon"
                                src={incorrectAnswerIcon}
                                alt="incorrect-answer"
                              />
                            )}
                        </div>
                      )
                    )}
                  </div>
                  <h5 className="mt-5">
                    {quizResult}
                    {"  "}
                    {quizResult &&
                      QuestionsList[currentQuestionIndex].explanation && (
                        <div>
                          <p>
                            {QuestionsList[currentQuestionIndex].explanation}
                          </p>
                          <ShareFeature
                            text={`You learned from your Peach quiz that, ${QuestionsList[
                              currentQuestionIndex
                            ].explanation.toLowerCase()}`}
                          />
                        </div>
                      )}
                  </h5>
                </div>
                <div className="questions-nav d-flex justify-content-center mt-5">
                  <h5
                    className="text-dark font-weight-bold mr-5 pointer"
                    onClick={goBackQuestion}
                  >
                    <u>BACK</u>
                  </h5>
                  <h5
                    className="text-dark font-weight-bold pointer"
                    onClick={setNextQuestion}
                  >
                    <u>NEXT QUESTION</u>
                  </h5>
                </div>
              </>
            )}
          {QuestionsList && QuestionsList.length === 0 && (
            <div
              className="d-flex flex-column justify-content-center align-item-center"
              style={{ height: "450px", width: "100%" }}
            >
              <div>
                <Spinner
                  animation="border"
                  size="lg"
                  role="status"
                  variant="primary"
                  aria-hidden={true}
                />
              </div>
            </div>
          )}
        </div>
      </Container>
    </Section>
  );
}
