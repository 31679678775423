import React, { useContext, useState, useEffect, useRef } from "react";
import "../style.scss";
import { ActionContext } from "./ActionContext";

const InputField = ({ cancellor, parentId, child, value, edit, main }) => {
  const [text, setText] = useState("");
  const ref = useRef();

  const handleChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref]);

  const actions = useContext(ActionContext);
  return (
    <form
      className="comments-form mb-5"
      style={
        !child && !edit && main === undefined
          ? { marginLeft: 36 }
          : { marginLeft: 8 }
      }
    >
      <div className="userImg">
        <img
          src={actions.userImg}
          style={{ width: 38, height: 38, borderRadius: 38 / 2 }}
          alt="userIcon"
        />
      </div>
      <input
        className="postComment"
        type="text"
        placeholder="Type your reply here."
        component="input"
        value={text}
        onChange={handleChange}
      />
      <div className="inputActions">
        <button
          className="postBtn"
          onClick={() =>
            edit === true
              ? actions.submit(cancellor, text, parentId, true, setText)
              : actions.submit(cancellor, text, parentId, false, setText)
          }
          type="button"
          disabled={!text}
          style={
            !text
              ? { backgroundColor: "rgb(170, 218, 246)" }
              : { backgroundColor: "#d0e0f6;" }
          }
        >
          Post
        </button>
        {(text || parentId) && (
          <button
            ref={ref}
            className="cancelBtn"
            onClick={() =>
              edit
                ? actions.handleCancel(cancellor, edit)
                : actions.handleCancel(cancellor)
            }
          >
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

export default InputField;
