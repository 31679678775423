import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import conversionFunnelDataMap from "../data/ConversionFunnelData";
import PageLoader from "../../components/PageLoader";
import "./ConversionFunnel.scss";
import ShareFeature from "../../components/ShareFeature";
import { useSummaryStats } from "../data/sharedSummaryStats";

export default function ConversionFunnel({ conversionFunnelData }) {
  const [isShown, setIsShown] = useState(undefined);
  const { appType } = useSummaryStats();

  const bgColors = ["#FBA59F", "#FAC4C0", "#F8E8E7", "#E4E4F8", "#CCCBF8"];

  const convertData = () => {
    const funnelResult = [];
    for (let [funnelField, entry] of Object.entries(conversionFunnelDataMap)) {
      if (!(funnelField in conversionFunnelData)) {
        continue;
      }
      entry.amount = conversionFunnelData[funnelField];
      if (funnelField == "Total_Swipes") {
        entry.text =
          "You can't get those matches without these swipes. Keep them coming!";
      } else if (funnelField == "Likes") {
        if ("Total_Swipes" in conversionFunnelData) {
          const rate = Math.round(
            (conversionFunnelData["Likes"] /
              conversionFunnelData["Total_Swipes"]) *
              100
          );
          entry.text = `You like ${rate}% of the profiles you come across.`;
        } else {
          entry.text =
            "Liking what you see is the simple first step towards those dates!";
        }
      } else if (funnelField == "Total_Matches") {
        const rate = Math.round(
          (conversionFunnelData["Total_Matches"] /
            conversionFunnelData["Likes"]) *
            100
        );
        entry.text = `${rate}% of profiles you like like you back!`;
      } else if (funnelField == "Contacted_Matches") {
        const rate = Math.round(
          (conversionFunnelData["Contacted_Matches"] /
            conversionFunnelData["Total_Matches"]) *
            100
        );
        entry.text = `You started conversations with ${rate}% of your matches.`;
      } else if (funnelField == "Number_of_Dates") {
        const rate = Math.round(
          (conversionFunnelData["Number_of_Dates"] /
            conversionFunnelData["Contacted_Matches"]) *
            100
        );
        entry.text = `Around ${rate}% of your conversations led to dates.`;
      }
      funnelResult.push(entry);
    }
    return funnelResult;
  };

  const funnelResult = convertData();

  const calcWidth = (amountPerItem) => {
    const max = Math.max(...funnelResult.map((e) => e.amount));
    const widthPercent = (amountPerItem / max) * 100;
    return widthPercent;
  };

  return (
    <Container className="my-5">
      {conversionFunnelData && Object.keys(conversionFunnelData).length > 0 ? (
        <>
          {funnelResult.map((item, index) => {
            return (
              <Row
                className="funnel-wrapper d-flex justify-content-center"
                key={item.type}
              >
                <Col lg={3} sm={2} className="m-0 p-0">
                  <div className="d-flex justify-content-center p-4">
                    {item.type && (
                      <>
                        {/* <Badge
                      width="20"
                      height="20"
                      pill
                      className="badge d-flex justify-content-center align-items-center"
                    >
                      <span>{index + 1}</span>
                    </Badge> */}
                        <img
                          width="60"
                          alt="funnel-category"
                          src={
                            require(`../../assets/icons/${item.type.toLowerCase()}.svg`)
                              .default
                          }
                        />
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={6} sm={4}>
                  <div
                    onMouseEnter={() => setIsShown(index)}
                    onMouseLeave={() => setIsShown(undefined)}
                    key={item.type}
                    className="funnel-level py-3 px-1 justify-content-center text-center my-2 mx-auto pointer"
                    style={{
                      backgroundColor: `${bgColors[index % bgColors.length]}`,
                      width: `${calcWidth(item.amount)}%`,
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        minWidth: `${
                          calcWidth(item.amount) < 50 ? "300px" : "auto"
                        }`,
                        textAlign: `${
                          calcWidth(item.amount) < 50 ? "left" : "center"
                        }`,
                        marginLeft: `${
                          calcWidth(item.amount) < 50 ? "3rem" : ""
                        }`,
                      }}
                    >
                      <h4 className="funnel-text">
                        {item.amount} {item.label}
                      </h4>
                      {isShown === index && (
                        <small className="p-0 m-0">{item.text}</small>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
          <div className="text-right mt-5">
            <ShareFeature
              text={
                `Here's your conversion funnel for ${appType}: ` +
                funnelResult.map(
                  (category) => ` ${category.label} (${category.amount})`
                ) +
                "."
              }
            />
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </Container>
  );
}
