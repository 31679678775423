import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useListVals } from "react-firebase-hooks/database";
import { useCollection } from "react-firebase-hooks/firestore";
import "./style.scss";
import DisplayComments from "./components/DisplayComments";
import { ActionProvider } from "./components/ActionContext";
import Input from "./components/Input";
import firebase from "../../utils/firebase";

const commentsReducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", path: undefined, payload: undefined };
    case "new":
    case "edit":
    case "delete":
      return {
        status: action.type,
        path: action.path,
        payload: action.payload,
      };
    case "delete child":
      return {
        status: action.type,
        path: action.path,
      };
    case "vote":
      return {
        status: action.type,
        path: action.path,
        vote: action.vote,
      };
    default:
      throw new Error("invalid action");
  }
};

const snapshotsToCommentsArray = (snapshots) => {
  const commentsArray = [];
  for (const parent of snapshots) {
    if (typeof parent !== "object") {
      continue;
    }
    const newParent = {};
    const replies = [];
    for (const [field, val] of Object.entries(parent)) {
      if (typeof val === "object" && val !== null && field !== "votes") {
        replies.push(val);
      } else {
        newParent[field] = val;
      }
    }
    if (replies.length > 0) {
      newParent["replies"] = replies;
    }
    commentsArray.push(newParent);
  }
  return commentsArray;
};

export const CommentSection = ({ currentUser, sectionId }) => {
  const initialState = {
    status: "idle",
    path: undefined,
    payload: undefined,
  };
  const [comments, setComment] = useState([]);
  const [state, dispatch] = useReducer(commentsReducer, initialState);
  const sectionRef = firebase.database().ref(`comments/${sectionId}`);
  const query = sectionRef.orderByChild("adjusted_score").limitToFirst(100);
  const [snapshots, loading] = useListVals(query);
  const [publicSnaps] = useCollection(
    firebase.firestore().collection(`public_profiles`)
  );

  const updateTotalUpvotes = (commentRef, uid, vote) => {
    commentRef.transaction((comment) => {
      if (comment) {
        if (
          comment.totalUpvotes != null &&
          comment.votes &&
          comment.votes[uid]
        ) {
          if (comment.votes[uid] === vote) {
            comment.votes[uid] = null;
            comment.totalUpvotes += -vote;
          } else {
            comment.votes[uid] = vote;
            comment.totalUpvotes += 2 * vote;
          }
        } else {
          if (!comment.totalUpvotes) {
            comment.totalUpvotes = 0;
          }
          comment.totalUpvotes += vote;
          if (!comment.votes) {
            comment.votes = {};
          }
          comment.votes[uid] = vote;
        }
      }
      return comment;
    });
  };

  useEffect(() => {
    if (!loading && snapshots.length) {
      const commentsArray = snapshotsToCommentsArray(snapshots);
      setComment(commentsArray);
    } else {
      setComment([]);
    }
  }, [snapshots]);

  useEffect(() => {
    switch (state.status) {
      case "new":
        sectionRef.child(state.path).update(state.payload);
        sectionRef.update({
          comments_count: firebase.database.ServerValue.increment(1),
        });
        break;
      case "edit":
      case "delete":
        sectionRef.child(state.path).update(state.payload);
        break;
      case "delete child":
        sectionRef.child(state.path).set({});
        sectionRef.update({
          comments_count: firebase.database.ServerValue.increment(-1),
        });
        break;
      case "vote":
        updateTotalUpvotes(
          sectionRef.child(state.path),
          currentUser.userId,
          state.vote
        );
        break;
    }
  }, [state]);

  const profiles = useMemo(() => {
    const results = {};
    if (publicSnaps) {
      publicSnaps.docs.forEach((doc) => {
        results[doc.id] = doc.data();
      });
    }
    return results;
  }, [publicSnaps]);

  return (
    <ActionProvider
      currentUser={currentUser}
      setComment={setComment}
      comments={comments}
      dispatch={dispatch}
      profiles={profiles}
    >
      <div className="comments-section">
        <div className="inputBox">
          <Input />
        </div>
        <div className="displayComments">
          <DisplayComments comments={comments} />
        </div>
      </div>
    </ActionProvider>
  );
};
