import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useAuth } from "../utils/auth.js";
import CommunityVoteCard from "./CommunityVoteCard";
import { CommentsSidePanel } from "./CommunityVoteCard";
import { firestore } from "../utils/firebase";
import { toggleScoreSharing } from "../utils/db.js";
import InfiniteScroll from "react-infinite-scroller";

export default function CommunityVote({ mediaType, collectionName }) {
  const auth = useAuth();
  let userUID = auth.user?.uid;

  const [communityData, setCommunityData] = useState([]);
  const [latestDoc, setLatestDoc] = useState(null);
  const [empty, setEmpty] = useState(false);
  const [uid, setUid] = useState(null);
  const [commentsCount, setCommentsCount] = useState(0);
  const [commentsLoading, setCommentsLoading] = useState(0);
  const [viewComments, setViewComments] = useState(false);
  const [shareScore, setShareScore] = useState(false);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(true);
  const pageSize = 6;

  // console.log(empty);
  // console.log(latestDoc);
  // console.log(communityData);
  useEffect(() => {
    firestore
      .collection("public_profiles")
      .doc(userUID)
      .get()
      .then((doc) => {
        if (doc.exists && "summary_scores" in doc.data()) {
          setShareScore(true);
        }
      });

    firestore
      .collection("profile_details")
      .doc(userUID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCheckBoxDisabled(false);
        }
      });
  }, [userUID]);

  const handleShareScore = () => {
    toggleScoreSharing(shareScore, setShareScore, userUID);
  };

  const fetchData = (latestDoc) => {
    let query;
    if (latestDoc) {
      query = firestore
        .collection(collectionName)
        .orderBy("adjusted_score", "desc")
        .startAfter(latestDoc)
        .limit(pageSize);
    } else {
      query = firestore
        .collection(collectionName)
        .orderBy("adjusted_score", "desc")
        .limit(pageSize);
    }
    query.get().then((querySnapshot) => {
      var data = [];
      querySnapshot.docs.forEach((doc) => {
        let d = doc.data();
        d.uid = doc.id;
        d.created_at = d.created_at.toDate().toDateString();
        data.push(d);
      });
      data &&
        data.length > 0 &&
        setCommunityData((prevData) => {
          if (prevData.length && !latestDoc) {
            return prevData;
          } else {
            return [...prevData, ...data];
          }
        });

      if (querySnapshot.empty) {
        setEmpty(true);
      } else {
        setLatestDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => fetchData(latestDoc)}
        hasMore={!empty}
        loader={<h4>Loading...</h4>}
      >
        <Row className="justify-content-between d-flex flex-wrap">
          {communityData &&
            communityData.length > 0 &&
            communityData.map((payload, index) => (
              <Col key={index} lg={6} xs={12}>
                <CommunityVoteCard
                  userUID={userUID}
                  payload={payload}
                  mediaType={mediaType}
                  collectionName={collectionName}
                  setViewComments={setViewComments}
                  setUid={setUid}
                  setCommentsCount={setCommentsCount}
                  setCommentsLoading={setCommentsLoading}
                />
              </Col>
            ))}
        </Row>
      </InfiniteScroll>
      {viewComments && (
        <CommentsSidePanel
          userUID={userUID}
          sectionUID={uid}
          setViewComments={setViewComments}
          commentsCount={!commentsLoading && commentsCount ? commentsCount : 0}
          shareScore={shareScore}
          handleShareScore={handleShareScore}
          checkBoxDisabled={checkBoxDisabled}
        />
      )}
    </>
  );
}
