import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import PeachDashboardPage from "./peachDashboard";
import PeachInstructionsPage from "./peachInstructions";
import { OrchardImages, OrchardMessages } from "./OrchardCommunity";
import { OrchardInsights } from "./OrchardInsights";
import RoleSelect from "./RoleSelect";
import ProfilePage from "./profile";
import AuthPage from "./auth";
import ImagesPage from "./images";
import MessagesPage from "./messages";
import PrivacyPolicy from "./privacyPolicy";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import NotFoundPage from "./not-found.js";
import Footer from "../components/Footer";
import { AuthProvider } from "../utils/auth.js";
import logo from "../assets/images/peachLogo.png";
import ScrollToTop from "../components/ScrollToTop";
import { useAuth } from "../utils/auth.js";

import "./../styles/global.scss";

function App() {
  const auth = useAuth();
  return (
    <AuthProvider>
      <Router>
        <>
          <NavbarCustom bg="white" variant="light" expand="lg" logo={logo} />
          <div className="main-wrapper">
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route
                exact
                path="/peach/insights/:status?"
                component={PeachDashboardPage}
              />

              <Route exact path="/peach/images" component={ImagesPage} />
              <Route
                exact
                path="/peach/messages/:status?"
                component={MessagesPage}
              />
              <Route
                exact
                path="/peach/upload"
                component={PeachInstructionsPage}
              />
              <Route
                exact
                path="/orchard/insights"
                component={OrchardInsights}
              />
              <Route
                exact
                path="/orchard/messages"
                component={OrchardMessages}
              />
              <Route exact path="/orchard" component={OrchardInsights} />
              <Route exact path="/orchard/images" component={OrchardImages} />
              <Route exact path="/select_role" component={RoleSelect} />
              {auth && auth.user && (
                <Route exact path="/profile" component={ProfilePage} />
              )}
              <Route exact path="/profile" component={ProfilePage} />
              <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
              <Route exact path="/auth/:type" component={AuthPage} />
              <Route component={NotFoundPage} />
            </Switch>
            {!window.location.pathname.includes("/auth") && (
              <Footer
                bgColor="#FAFAFA"
                size="sm"
                description="Peach help you gain value from the data you generate on dating apps. We are an independent team not affiliated with Tinder or Match Group.
          "
                copyright="© 2021 Peach"
                logo={logo}
              />
            )}
          </div>
        </>
      </Router>
    </AuthProvider>
  );
}

export default App;
