import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import InsightIcon from "../assets/icons/insights.png";
import ImageIcon from "../assets/icons/image.png";
import UploadIcon from "../assets/icons/upload-data.png";
import MessageIcon from "../assets/icons/message.png";
import "./SideBarNav.scss";

export default function SideNavBar({ status }) {
  return (
    <div className="side-navbar p-0">
      <Navbar
        // variant="light"
        // bg="transparent"
        className="ml-auto d-flex flex-column h-100 "
        style={{ textAlign: "left" }}
      >
        {/* <button
          onClick={() => showSideNav(!isShowingSidenav)}
          className="ml-auto close-btn border-0 bg-transparent"
        >
          <h3>x</h3>
        </button> */}
        <hr></hr>
        <Nav.Link
          className="navbar-link"
          href={`/peach/insights/${status ? status + "/" : ""}`}
        >
          <span className="icon-wrapper mr-2">
            <img src={InsightIcon} width="30" alt="insights" />
          </span>
          Insights
        </Nav.Link>
        <Nav.Link className="navbar-link" href={`/peach/images`}>
          <span className="icon-wrapper mr-2">
            <img src={ImageIcon} width="30" alt="images" />
          </span>
          Images
        </Nav.Link>
        <Nav.Link
          className="navbar-link"
          href={`/peach/messages/${status ? status + "/" : ""}`}
        >
          <span className="icon-wrapper">
            <img className="icon" src={MessageIcon} alt="vote message" />
          </span>
          In-app Messages
        </Nav.Link>
        <Nav.Link className="navbar-link" href={`/peach/upload`}>
          <span className="icon-wrapper">
            <img className="icon" src={UploadIcon} alt="upload" />
          </span>
          Upload App Data
        </Nav.Link>
      </Navbar>
    </div>
  );
}
