import React from "react";
import { Container, Row, Col, Image, Button, Nav } from "react-bootstrap";
import Section from "../Section";
import SectionHeader from "../SectionHeader";

function HeroSection(props) {
  return (
    <Section
      id="hero-section"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="mt-5 justify-content-center">
        <Row className="align-items-center">
          <Col lg={6} sm={12} className="text-center text-lg-left pl-5">
            <SectionHeader
              title={props.title}
              coloredTitle={props.coloredTitle}
              subtitle={props.subtitle}
              subtitleColor={"#393a3d"}
              size={1}
              subtitleSize={4}
              spaced={true}
            />
            <Nav.Link href="/auth/signup" className="p-0">
              <Button size="lg" className="btn-primary p-3 mt-4 m-0">
                Sign up for Peach
              </Button>
            </Nav.Link>
          </Col>
          <Col className="mt-5 mt-lg-0 d-flex justify-content-center align-items-center ">
            <figure className="HeroSection__image-container mx-auto">
              <Image src={props.image} fluid="true" />
            </figure>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default HeroSection;
