import React from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "../utils/router";

import TopSectionHeader from "../components/TopSectionHeader";
import Section from "../components/Section";
import "./PrivacyPolicy.scss";
function PrivacyPolicyPage() {
  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <>
      <TopSectionHeader
        bgColor="#fef1e5"
        textColor="dark"
        size="md"
        title="Privacy Policy"
        subtitle=""
      />
      <Section>
        <Container className="privacy-policy">
          {" "}
          <Button className="my-5 d-flex ml-auto" onClick={goToPreviousPath}>
            Go back
          </Button>
          <p>
            Our Privacy Policy explains how Peach (“Company”, “we”, “us”, or
            “our”) uses the personal data we collect from you through our
            website. We do not keep personally identifiable data, unless you
            voluntarily provide it. You will be asked to explicitly consent
            before providing any personally identifiable or anonymous data. If
            you have any questions or concerns relating to the collection or
            usage of your data, please e-mail us at{" "}
            <a href="mailto:contact@peach-app.io">
              <strong>contact@peach-app.io</strong>
            </a>
            .
          </p>
          <br></br>
          <strong>
            If you disagree with any of the terms in this Privacy Policy, please
            discontinue your usage of Peach.
          </strong>
          <br></br>
          <p>
            Please carefully read this policy to decide whether you wish to use
            our services.
          </p>
          <br></br>
          <h5>1. Policy Applicability </h5>{" "}
          <p>
            This Privacy Policy is applicable to websites, apps, events and any
            other services operated by Peach. These are all referred to as our
            “services” and our “site” in this Privacy Policy.
          </p>
          <h5>2. What Data We Collect </h5>
          <p>
            Effective usage of our site necessarily requires the uploading of
            your personal data. However, you have complete control of the data
            you upload to our site.
            <br></br>
            <br></br>We collect three main types of data. The first is dating
            app usage data which you may choose to upload to our site.<br></br>{" "}
            <br></br>
            <strong>
              If you choose to register with Peach by creating an account, we
              retain your data on our servers while you remain a registered
              user. This data may contain personally identifiable information
              such as your phone number, email, name, address, and any other
              information you may have provided to the dating app or included in
              your messages. We do not use your personally identifiable
              information in any way. We do not sell or share your personally
              identifiable information with any third parties. Any personally
              identifiable information is automatically deleted if you delete
              your Peach account.
            </strong>{" "}
            <br></br>
            <br></br>
            The second type of data we collect are e-mail addresses through
            subscription to our mailing list. Likewise, we will only collect
            your e-mail address with your explicit consent. We only collect
            e-mail addresses for the purposes of marketing and developing our
            services.
            <br></br>
            <br></br>Lastly, we collect anonymized information generated from
            your usage of our services, for instance, how much time you spend on
            the site and which tabs you use.
            <br></br>
            <br></br> We use all of these data in line with our services. Such
            usages can include, but are not limited to, analysis, storage, and
            disclosure of aggregated anonymized data, such as summary plots and
            summary statistics of dating app usage by age and gender. We do not
            share data with any third parties (other companies) but only use it
            for the purposes of providing services to users through Peach.{" "}
            <br></br>If we have processed your data based on your consent and
            you wish to revoke your consent, please contact us. If you are a
            registered Peach user, we automatically delete your personally
            identifiable information when you delete your account. If you wish
            to also delete the anonymized data that we retain, please note that
            the deletion of your anonymized data may require a varying amount of
            personal information to reliably locate the dataset to be deleted.
            <br></br>
            <br></br>Please contact us if you have subscribed to our e-mail list
            and would like your contact information removed.
          </p>
          <h5>3. How We Use Information</h5>
          <div>
            <p>
              We mainly use your information to provide and develop our
              services. We also use your information for your safety. Below is a
              more detailed explanation of some of the reasons we use your
              information.
            </p>
            <br></br>
            <br></br>
            <ul>
              <li>Provide customer support and respond to requests</li>
              <li>Communicate to you information about our services </li>
              <li>Analyze your activity and preferences </li>
              <li>Ensure that we are complying with relevant laws</li>
              <li> Provide new services </li>
              <li> Prevent fraud and other illegal activities</li>
              <li>Improve and develop existing and new services</li>
            </ul>
          </div>
          <h5>4. How We Share Information</h5>
          <p>
            Your data will remain confidential. We do not share any personal
            information with third parties. If you consent to sharing your data
            with us, we may share summary statistics and plots based on your
            anonymized information with you, other users of Peach, and the
            public after aggregating it with data from other users. We will
            never share any personally identifiable information. For instance,
            we may offer insights developed from analyzing many of our users’
            anonymized and contributed datasets. Your specific information would
            not be recognizable in such reports.
          </p>
          <h5>5. How Long We Retain Your Information</h5>
          <p>
            We will only keep your information for as long as it is necessary to
            effectively perform our services and within legal restrictions.{" "}
            <br></br>
            You may also request that we provide you with, modify, or delete any
            information we have relating to you. Should you desire any of these
            actions, please e-mail us and we will reply within 30 days.
          </p>
          <h5>6. Children&apos;s Privacy </h5>
          <p>
            We restrict access to our services to individuals aged 18 years or
            older. We do not permit users under the age of 18 to use our
            services and do not knowingly collect information from them. Please
            report any suspicions of a user under the age of 18 to us.
          </p>
          <h5>7. Your Privacy Rights </h5>
          <p>
            Certain areas give you rights under data protection laws. These may
            give you the right to (i) data portability, (ii) restrict our rights
            to process your data, (iii) access and/or obtain a copy of your
            private data, (iv) requisition erasure and/or rectification. To
            exercise any of these rights, please contact us at
            <a href="mailto:contact@peach-app.io">
              <strong> contact@peach-app.io</strong>
            </a>
            . We will handle any such request in line with the relevant laws.
            <br></br>
            <br></br>While you may request us to delete your consensually
            provided data at any time, this does not impact our right to process
            your data prior to any such request.
          </p>
          <h5>8. Policy Updates</h5>
          <p>
            We reserve the right to revise this Privacy Policy. Any revisions
            will indicate at which date they were revised. You will be notified
            via a notice on the site should any material changes take place to
            this policy. Any revisions will take effect immediately upon
            posting. Please review this policy regularly.
          </p>
          <Button className="my-5" onClick={goToPreviousPath}>
            Go back
          </Button>
        </Container>
      </Section>
    </>
  );
}

export default PrivacyPolicyPage;
