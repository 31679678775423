import { useReducer, useEffect, useRef } from "react";
import firebase from "./firebase";
import { firestore, db } from "./firebase";
// const serverTimestamp = firestore.FieldValue.serverTimestamp;

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(uid) {
  return useQuery(uid && firestore.collection("users").doc(uid));
}

export function useData(uid, collection) {
  return useQuery(uid && firestore.collection(`${collection}`).doc(uid));
}

// fetch data from realtime database
export function useRDBData(uid, field) {
  return useDBQuery(uid && db.ref(`${field}/${uid}`));
}

// Create a new user
export async function createUser(uid, data) {
  const bgColor = "99d98c";
  const name = uid.slice(0, 3) + uid.slice(-3);
  const avatarUrl = `https://ui-avatars.com/api/name=${name[0]}&background=${bgColor}&color=fff`;
  await firestore
    .collection("public_profiles")
    .doc(uid)
    .set({ avatarUrl, uid }, { merge: true });
  return firestore
    .collection("users")
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return firestore.collection("users").doc(uid).update(data);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data (hook)
export function useItem(id) {
  return useQuery(id && firestore.collection("items").doc(id));
}

// Fetch all items by owner (hook)
export function useItemsByOwner(owner) {
  return useQuery(
    owner &&
      firestore
        .collection("items")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
}

// // Create a new item
// export function createItem(data) {
//   return firestore.collection("items").add({
//     ...data,
//     createdAt: serverTimestamp(),
//   });
// }

// Update an item
export function updateItem(id, data) {
  return firestore.collection("items").doc(id).update(data);
}

// Delete an item
export function deleteItem(id) {
  return firestore.collection("items").doc(id).delete();
}

/**** HELPERS ****/

// Reducer for useQuery hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
};

// Custom React hook that subscribes to a Firestore query
function useQuery(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.onSnapshot(
      (response) => {
        // Get data for collection or doc
        const data = response.docs
          ? getCollectionData(response)
          : getDocData(response);

        dispatch({ type: "success", payload: data });
      },
      (error) => {
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Custom React hook that subscribes to a Realtime Database query
function useDBQuery(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.on(
      "value",
      (snapshot) => {
        // Get data for collection or doc
        const data = snapshot.val();

        dispatch({ type: "success", payload: data });
      },
      (error) => {
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Get doc data and merge doc.id
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map(getDocData);
}

// Used by useQuery to store Firestore query object reference
function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

export function toggleScoreSharing(shareScore, setShareScore, userUID) {
  if (shareScore) {
    setShareScore(false);
    firestore
      .collection("public_profiles")
      .doc(userUID)
      .update({ summary_scores: firebase.firestore.FieldValue.delete() })
      .then(() => {
        console.log("Score sharing is disabled.");
      });
  } else {
    setShareScore(true);
    firestore
      .collection("profile_details")
      .doc(userUID)
      .get()
      .then((doc) => {
        const app_type = doc.data()["data_type_to_share"];
        firestore
          .collection("public_profiles")
          .doc(userUID)
          .set(
            {
              summary_scores: { ...doc.data()[app_type], data_type: app_type },
            },
            { merge: true }
          )
          .then(() => {
            console.log("Score sharing is enabled.");
          });
      });
  }
}
