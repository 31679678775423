import React, { useState } from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import shareIcon from "../assets/icons/idea.svg";
import FemaleMentor from "../assets/images/female_coach1.png";
import MaleMentor from "../assets/images/male_coach1.png";
import "./MentorProfile.scss";

const photo = (src) => (
  <div className="d-flex align-items-center me-3" style={{ width: "80px" }}>
    <img
      src={src}
      style={{ borderRadius: "80px" }}
      alt="User profile"
      className="w-100"
    />
  </div>
);

function MentorItem(props) {
  const [showStatus, setShowStatus] = useState(false);

  const handleMentorSelect = () => {
    setShowStatus(true);
  };

  return (
    <ListGroup.Item
      action
      onClick={handleMentorSelect}
      className="mentor-profile"
    >
      <div className="d-flex flex-row">
        {photo(props.imageSrc)}
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="ml-3">{props.name}</div>
            <div className="mentor-name ml-3">{props.title}</div>
          </div>
          {showStatus && <div className="ml-4 text-info">{"coming soon!"}</div>}
        </div>
      </div>
    </ListGroup.Item>
  );
}

export default function ShareFeature({ text }) {
  const [showModal, setShowModal] = useState(false);

  const renderModal = () => {
    return (
      <Modal
        style={{ marginTop: "170px" }}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <div>
            <Modal.Title>
              Peach recommends these mentors to help you break down this
              insight:
            </Modal.Title>
            <small style={{ fontStyle: "italic" }}>{text}</small>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="mb-3">
            <MentorItem
              imageSrc={FemaleMentor}
              name="Harumi Kobayashi"
              title="Creative influencer"
            />
            <MentorItem
              imageSrc={MaleMentor}
              name="Yanis Petros"
              title="Articulate data nerd"
            />
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <div className="pointer">
      <button
        className="border-0 p-0 m-0 bg-transparent"
        onClick={() => setShowModal(true)}
      >
        <img src={shareIcon} alt="share-icon" width="40" /> {"  "}
        <small>Get advice</small>
      </button>
      {showModal && renderModal()}
    </div>
  );
}
