import React, { useMemo, useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import Cookies from "js-cookie";
import { useRouter } from "../../utils/router";
import { Link } from "../../utils/router";
import { useAuth } from "../../utils/auth.js";
import BackendApi from "../../models/backend";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#bdbdbd",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const textStyle = {
  fontSize: "1em",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  padding: "0 20px",
  textAlign: "center",
  width: "100%",
  color: "black",
};

const buttonStyle = {
  width: "200px",
};
export default function FileUploader(props) {
  const auth = useAuth();
  const router = useRouter();
  const [isAccept, setIsAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrivacyAlert, setShowPrivacyAlert] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [idToken, setIdToken] = useState(null);

  if (auth.user) {
    auth.user.getIdToken(true).then((token) => {
      setIdToken(token);
    });
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: [".json", ".txt"], maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const file = acceptedFiles.map((f, idx) => (
    <p style={textStyle} key={idx}>
      {f.path}
    </p>
  ));

  useEffect(() => {
    if (file && file.length > 0 && isAccept) {
      setIsBtnDisabled(false);
    }
    if (file && file.length > 0 && !isAccept) {
      setShowPrivacyAlert(true);
    }
  }, [file, isAccept, isLoading]);

  const handleAcceptTerms = () => {
    setIsAccept(!isAccept);
  };

  const handleSubmission = async () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    formData.append("id_token", idToken);
    formData.append("timezone", tz);
    if (!isAccept) {
      setShowPrivacyAlert(true);
      return;
    }
    if (!acceptedFiles[0] && acceptedFiles.length === 0) {
      return;
    }
    if (acceptedFiles[0] && acceptedFiles.length > 0 && isAccept) {
      setIsLoading(true);
    }
    const res = await BackendApi.uploadUserData(formData);
    console.log("loading");
    if (res) {
      //returns doc key inside sessions collection
      res && Cookies.set("app_type", res["app_type"]);
      console.log("File uploaded successfully");
      setIsLoading(false);
      router.push("/peach/insights");
    }
  };
  return (
    <div>
      <div className="d-flex align-items-start mt-4 mb-2">
        <input
          type="checkbox"
          onClick={handleAcceptTerms}
          className="mr-2 p-0 mt-1 pointer"
        />
        <Link to="/PrivacyPolicy" className="text-dark">
          I have read and agree to the <strong>Privacy Policy</strong>
        </Link>
      </div>
      {showPrivacyAlert && (
        <div className="text-left">
          <small className="text-primary">
            Note that your uploaded data may contain Personal Identifiable
            Information. Please read our privacy policy and make sure you agree
            with it before uploading your data.
          </small>
        </div>
      )}
      <div className="uploader-container mt-4">
        <div className="pointer" {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <span className="text-dark">
            Drag &apos;n&apos; drop some files here, or click to select files
          </span>
          {file}
        </div>
        <Button
          renderas="button"
          onClick={handleSubmission}
          size="md"
          className="mt-5"
          style={buttonStyle}
          variant={props.buttonColor}
          disabled={isBtnDisabled}
        >
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            props.buttonText
          )}
        </Button>{" "}
        {isLoading && (
          <p className="my-2 text-primary">
            This might take less than a minute to load.
          </p>
        )}
      </div>
    </div>
  );
}
