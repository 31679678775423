import React from "react";
import { Navbar, Nav, NavDropdown, Button, Container } from "react-bootstrap";
import userIcon from "../assets/icons/user.png";
import { useAuth } from "../utils/auth.js";
import { useRouter } from "../utils/router";
import padlockIcon from "../assets/icons/lock.png";
import "./NavbarCustom.scss";

function NavbarCustom(props) {
  const router = useRouter();
  const auth = useAuth();

  const onHandleLogout = () => {
    auth.signout();
    router.push("/");
  };

  return (
    <Navbar
      collapseOnSelect
      bg={props.bg}
      variant={props.variant}
      expand={props.expand}
      style={{
        backgroundColor: `${props.bgColor ? props.bgColor : ""}`,
      }}
      className="top-nav"
      fixed="top"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            className="d-inline-block align-top"
            src={props.logo}
            alt="Logo"
            height="60"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="d-lg-flex justify-content-between"
        >
          <Nav className="me-auto">
            <Nav.Link className="navLink" href="/">
              Home
            </Nav.Link>
            {auth.user && (
              <Nav.Link className="navLink" href="/peach/insights">
                Peach
              </Nav.Link>
            )}
            {/* <NavDropdown
              className="nav-dropdown"
              title="Peach"
              id="collasible-nav-dropdown"
            >
              {auth.user && (
                <NavDropdown.Item href="/peach/insights">
                  Dashboard
                </NavDropdown.Item>
              )}
              {/*
              <NavDropdown.Item href="/peachInstructions">
                Instructions
              </NavDropdown.Item>
            </NavDropdown> */}
            {auth.user && (
              <Nav.Link className="navLink" href="/orchard">
                Orchard
              </Nav.Link>
            )}
            <Nav.Link className="navLink" href="/about">
              About
            </Nav.Link>
            <Nav.Link className="navLink" href="/PrivacyPolicy">
              Privacy
            </Nav.Link>
          </Nav>
          <>
            {auth.user ? (
              <>
                <Nav className="ml-auto d-none d-lg-block">
                  {/* <Nav className="mr-auto mx-4 d-none d-lg-block"> */}
                  <NavDropdown
                    title={
                      <img
                        height="40"
                        src={userIcon}
                        fluid="true"
                        alt="profile"
                      />
                    }
                    alignRight
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onHandleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                {/* when small screen */}
                <Nav className="d-lg-none d-sm-block">
                  <Nav.Link className="navLink" href="/profile">
                    Profile
                  </Nav.Link>
                  <Nav.Link className="navLink" onClick={onHandleLogout}>
                    Logout
                  </Nav.Link>
                </Nav>
              </>
            ) : (
              <Nav className="mx-lg-5">
                <Nav.Link href="/auth/signup">
                  <Button size="sm" className="auth-btn btn-outline p-0">
                    Sign Up
                  </Button>
                </Nav.Link>
                <span className="ml-3"> </span>
                <Nav.Link href="/auth/signin">
                  <Button
                    size="sm"
                    className="auth-btn btn-outline p-0 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src={padlockIcon}
                      width="18"
                      alt="sign in"
                      className="mr-2"
                    />
                    Log In
                  </Button>
                </Nav.Link>
              </Nav>
            )}
          </>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavbarCustom;
