import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import UserImg from "../../assets/icons/user.png";
import firebase from "../../utils/firebase";
import { useAuth } from "../../utils/auth.js";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ImageDetails from "./ImageDetails";
import ProgressBar from "./ProgressBar";
import Section from "../Section";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare as faPlusSquareSolid,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import Multiple from "../../assets/icons/copy.svg";
// import { faPlusSquare as faPlusSquareReg } from "@fortawesome/free-regular-svg-icons";

import "./ImageUpload.scss";

export default function ImageUploadSection({
  size,
  bg,
  textColor,
  icon,
  title,
}) {
  const [selectedImgDoc, setSelectedImgDoc] = useState({});
  const [showGallery, setShowGallery] = useState(true);
  const auth = useAuth();
  let uid = auth.user?.uid;
  const [snapShots] = useCollection(
    firebase.firestore().collection(`images/${uid}/images`)
  );

  const userImgData = [
    { imgUrl: UserImg, attractivenessScore: 75, qualityScore: 98 },
    { imgUrl: UserImg, attractivenessScore: 32, qualityScore: 39 },
    { imgUrl: UserImg, attractivenessScore: 49, qualityScore: 60 },
    { imgUrl: UserImg, attractivenessScore: 49, qualityScore: 60 },
  ];

  const [files, setFiles] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [error, setError] = useState(null);
  const [expectedCount, setExpectedCount] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [uploadedData] = useDocument(
    firebase.firestore().doc(`images/${uid}/images/${groupId}`)
  );
  const types = ["image/png", "image/jpeg"];

  const handleFileChange = (e) => {
    let selected = e.target.files;
    setLoaded(false);

    const added = [];
    if (selected) {
      for (const file of selected) {
        if (types.includes(file.type)) {
          added.push(file);
          setError("");
        } else {
          setError("Please select an image file (png or jpg)");
        }
      }
    } else {
      setError("Please select an image file (png or jpg)");
    }
    setFiles(added);
    setExpectedCount(added.length);
    setGroupId(uuid());
  };

  const showImageDetails = (doc) => {
    setSelectedImgDoc(doc);
    setShowGallery(false);
  };

  useEffect(() => {
    if (
      uploadedData &&
      uploadedData.data() &&
      "image_count" in uploadedData.data()
    ) {
      if (uploadedData.data().image_count === expectedCount) {
        setLoaded(true);
        setFiles([]);
      }
    }
  }, [uploadedData]);

  return (
    <Section size={size} bg={bg} textColor={textColor} id="peach-stats">
      <Container>
        <div className="title-header">
          <div className="d-flex align-items-start">
            <img width="45" src={icon} alt={title} className="mr-3" />
            <h2>{title}</h2>
          </div>
          <hr></hr>
        </div>
        {showGallery ? (
          <>
            {userImgData.length > 0 ? (
              <>
                <form className="upload-form">
                  <label>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      multiple="multiple"
                    />
                    <span>
                      <FontAwesomeIcon
                        icon={faPlusSquareSolid}
                        size="3x"
                        color="#a5a5a5"
                        className="mx-0 fa-icon"
                      />
                    </span>
                    {/* <span>+</span> */}
                  </label>
                  <div className="output">
                    {error && <div className="text-danger">{error}</div>}
                    {files &&
                      !loaded &&
                      files.map(
                        (f, index) => f && <div key={index}>{f.name}</div>
                      )}
                    {files &&
                      files.map(
                        (f, index) =>
                          f && (
                            <ProgressBar
                              key={index}
                              file={f}
                              uid={uid}
                              index={index}
                              groupId={groupId}
                              loaded={loaded}
                            />
                          )
                      )}
                  </div>
                  {!loaded && (
                    <div style={{ paddingBottom: `${30 * files.length}px` }}>
                      {""}
                    </div>
                  )}
                </form>
                <Row className="gallery justify-content-start mx-5">
                  {snapShots &&
                    snapShots.docs.map((doc, index) => (
                      <Col lg={4} sm={6} key={index}>
                        <div
                          onClick={() => showImageDetails(doc)}
                          style={{ cursor: "pointer" }}
                          className="bg-dark rounded-corners d-flex align-items-center my-3 justify-content-center image-container"
                        >
                          <img
                            src={doc.data()[0]?.url}
                            alt="user uploaded image"
                            className="rounded-corners border-0"
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                          />
                          {/* {true && (
                            <div className="voted-alert d-flex justify-content-center align-items-center">
                              <small>votes received!</small>
                            </div>
                          )} */}
                          {doc.data().votes_received && (
                            <FontAwesomeIcon
                              icon={faFlag}
                              size="2x"
                              className="voted-alert"
                            />
                          )}
                          {doc.data().image_count > 1 && (
                            <img src={Multiple} className="multiple-status" />
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
              </>
            ) : (
              "Please upload a photo"
            )}
          </>
        ) : (
          <ImageDetails
            uid={uid}
            imgDoc={selectedImgDoc}
            setShowGallery={setShowGallery}
          />
        )}
      </Container>
    </Section>
  );
}
