import React, { useState } from "react";
import "d3-transition";
import { select } from "d3-selection";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import Section from "../../components/Section";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import PageLoader from "../../components/PageLoader";
import WordCloudIcon from "../../assets/icons/wordCloud.png";
import HelpfulTip from "../../components/HelpfulTip.js";
import "./WordCloud.scss";
import ShareFeature from "../../components/ShareFeature";

const resizeStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
};

const options = {
  rotations: 1,
  rotationAngles: [0],
  fontSizes: [25, 50],
};

const WORD_NUM = 20;

function WordCloud({
  BifurcateMsgsData,
  title,
  size,
  subTitle,
  setBifurcateMsgType,
  BifurcateMsgType,
}) {
  const [showMsg, setShowMsg] = useState("");
  let succAggWordCloudData = null;
  let FailAggWordCloudData = null;
  let topSuccessful = null;
  let topFail = null;

  const sortDictByValues = (data) => {
    if (data && Object.keys(data).length >= 0) {
      let items = Object.keys(data).map(function (key) {
        return [key, data[key]];
      });

      // Sort the array based on the second element
      items.sort(function (first, second) {
        return second[1] - first[1];
      });
      return items;
    }
  };

  const getWordCloudData = (data) => {
    let successWords = [];
    let failWords = [];
    //success words
    const successfulWordsFreqRes = data.succ_wd_freq;
    const successfulWordsAggRes = data.agg_succ_freq;
    if (successfulWordsAggRes) {
      succAggWordCloudData = successfulWordsAggRes;
    }
    // successfulWordsAggRes && Object.keys(successfulWordsAggRes).length && setSuccAggWordCloudData(successfulWordsAggRes);
    if (
      successfulWordsFreqRes &&
      Object.keys(successfulWordsFreqRes).length > 0
    ) {
      for (let word in successfulWordsFreqRes) {
        successWords.push({
          text: word,
          value: Math.round(successfulWordsFreqRes[word] * 25),
          agg_value: successfulWordsAggRes[word],
          success: true,
        });
      }
    }

    //fail words
    const FailWordFreqRes = data.fail_wd_freq;
    const FailWordAggRes = data.agg_fail_freq;
    if (FailWordAggRes) {
      FailAggWordCloudData = FailWordAggRes;
    }
    //FailWordAggRes && Object.keys(FailWordAggRes).length && setFailAggWordCloudData(FailWordAggRes);

    if (FailWordFreqRes && Object.keys(FailWordFreqRes).length > 0) {
      for (let word in FailWordFreqRes) {
        failWords.push({
          text: word,
          value: Math.round(FailWordFreqRes[word] * 25),
          agg_value: FailWordAggRes[word],
          success: false,
        });
      }
    }
    //get top most successful/ fail to show recommendations
    if (!topSuccessful && Object.keys(successfulWordsFreqRes).length > 0) {
      const sortedSuccArray = sortDictByValues(successfulWordsFreqRes);
      if (sortedSuccArray && sortedSuccArray.length > 0) {
        topSuccessful = sortedSuccArray.slice(0, 5);
      }
    }
    if (!topFail && Object.keys(FailWordFreqRes).length > 0) {
      const sortedFailArray = sortDictByValues(FailWordFreqRes);
      if (sortedFailArray && sortedFailArray.length > 0) {
        topFail = sortedFailArray.slice(0, 5);
      }
    }

    successWords.sort((a, b) => b.value - a.value);
    failWords.sort((a, b) => b.value - a.value);

    return [
      ...successWords.slice(0, WORD_NUM),
      ...failWords.slice(0, WORD_NUM),
    ];
  };

  let wordCloudData =
    BifurcateMsgsData &&
    Object.keys(BifurcateMsgsData).length > 0 &&
    getWordCloudData(BifurcateMsgsData);
  return (
    <Section size={size}>
      <a name="peach-word-cloud" className="anchor">
        {/* anchor text [invisible] */}
      </a>
      <Container>
        <div className="title-header d-flex">
          <img
            src={WordCloudIcon}
            width="40"
            className="mr-2 mb-2"
            alt="word cloud"
          />
          <h2>{title}</h2>
        </div>
        <hr></hr>
        <div className="mx-auto">
          <h5>{subTitle}</h5>
          <div className="d-flex align-items-center">
            <h5 className="mr-3 mt-2">
              {BifurcateMsgType === "first"
                ? "First messages"
                : "Continuing messages"}
            </h5>
            <HelpfulTip
              iconWidth="25"
              tipSubtitle={
                BifurcateMsgType === "first"
                  ? "The words in green are associated with your first messages that lead to an actual conversation. The words in red are associated with messages that do not lead to an actual conversation. The size of the word shows how frequently it appears in one category compared to the other. Use this plot to identify words and topics that lead to replies to your first messages, and avoid words and topics that appear to not lead to replies."
                  : "The words in green are associated with your messages that extended a conversation. The words in red towards the bottom are associated with messages that ended a conversation. The size of the word shows how frequently it appears in one category compared to the other. Use this plot to identify words and topics that extended your conversations and avoid words and topics that appear to end them."
              }
            />
          </div>
          <Button
            className="switch-graph-btn p-0 py-3 m-0 bg-transparent text-dark"
            style={{ border: "1px solid #FBA59F" }}
            onClick={() =>
              setBifurcateMsgType(
                BifurcateMsgType === "first" ? "middle" : "first"
              )
            }
          >
            <u>
              See words for{" "}
              {BifurcateMsgType !== "first"
                ? "first messages"
                : "continuing messages"}
            </u>
          </Button>
          <div className="word-cloud-wrapper d-flex justify-content-center">
            {wordCloudData && wordCloudData.length > 0 && (
              <Row className="p-0 my-5">
                <Col xs={12} lg={9}>
                  <Resizable
                    defaultSize={{
                      width: 600,
                      height: 300,
                    }}
                    style={resizeStyle}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <ReactWordcloud
                        className="word-cloud pointer"
                        words={wordCloudData}
                        options={options}
                        callbacks={callbacks}
                      />
                    </div>
                  </Resizable>
                </Col>
                <Col>
                  <Card
                    className="key-map border-0"
                    style={{
                      width: "10rem",
                      height: "100px",
                      backgroundColor: "#f8f4eb",
                    }}
                  >
                    <Card.Body>
                      <div className=" mt-5 d-flex align-items-center">
                        <div
                          className="map-key"
                          style={{ backgroundColor: "#4FA64F" }}
                        ></div>
                        <p className="p-0 m-0 ml-2">Successful</p>
                      </div>
                      <div className="d-flex  align-items-center">
                        <div
                          className="map-key"
                          style={{
                            backgroundColor: "#FBA59F",
                          }}
                        ></div>
                        <p className="p-0 m-0 ml-2">Unsuccessful</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {wordCloudData && wordCloudData.length === 0 && (
              <p>Insufficient data.</p>
            )}
          </div>
          {!wordCloudData && <PageLoader />}
        </div>
        <Row className="my-5">
          <Col xs={12} lg={6}>
            {topSuccessful && topSuccessful.length > 0 && (
              <div>
                <h5>Explore using more of these words!</h5>
                <div className="d-flex flex-column mt-3">
                  <small>Click to view your success with these words</small>
                  {topSuccessful && topSuccessful.length > 0 ? (
                    topSuccessful.map((succWord) => {
                      return (
                        succWord[0] && (
                          <div key={succWord[0]}>
                            <button
                              onClick={() => setShowMsg(succWord[0])}
                              className="avoid-word-btn my-2 w-50 p-2 d-flex justify-content-center flex-column text-dark bg-white"
                            >
                              {succWord[0]}
                            </button>
                            {showMsg && showMsg === succWord[0] && (
                              <small className="text-success my-1 mb-4">
                                When you used the word {succWord[0]} in your
                                messages, you were successful{" "}
                                {succAggWordCloudData[succWord[0]] || 0}/
                                {FailAggWordCloudData[succWord[0]] +
                                  succAggWordCloudData[succWord[0]]}{" "}
                                times.
                              </small>
                            )}
                          </div>
                        )
                      );
                    })
                  ) : (
                    <PageLoader />
                  )}
                </div>
                <ShareFeature
                  text={`Based on the results of the messages you sent, these words (${topSuccessful
                    .filter((word) => /^[a-zA-Z]+$/.test(word[0]) === true)
                    .map((word) => word[0])
                    .join(
                      ", "
                    )}) are your most successful words. You should try using more of these words.`}
                />
              </div>
            )}
          </Col>

          <Col xs={12} lg={6}>
            {topFail && topFail.length > 0 && (
              <div>
                <h5>See why you should maybe avoid these words!</h5>
                <div className="d-flex flex-column mt-3">
                  <small>Click to view your success with these words</small>
                  {topFail && topFail.length > 0 ? (
                    topFail.map((failWord) => {
                      return (
                        failWord[0] && (
                          <div key={failWord[0]}>
                            <button
                              onClick={() => setShowMsg(failWord[0])}
                              className="my-2 w-50 p-2 d-flex justify-content-center flex-column text-dark bg-white avoid-word-btn"
                            >
                              {failWord[0]}
                            </button>
                            {showMsg && showMsg === failWord[0] && (
                              <small className="text-danger my-1 mb-4">
                                When you used the word {failWord[0]} in your
                                messages, you were successful{" "}
                                {succAggWordCloudData[failWord[0]] || 0} /{" "}
                                {FailAggWordCloudData[failWord[0]] +
                                  succAggWordCloudData[failWord[0]]}{" "}
                                times.
                              </small>
                            )}
                          </div>
                        )
                      );
                    })
                  ) : (
                    <PageLoader />
                  )}
                </div>
                <ShareFeature
                  text={`Based on the results of the messages you sent, these words (${topFail
                    .filter((word) => /^[a-zA-Z]+$/.test(word[0]) === true)
                    .map((el) => el[0])
                    .join(
                      ", "
                    )}) are your less successful words. You should avoid using these words.`}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

// const rgbToHex = (r, g, b) => {
//   return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
// };

function getCallback(callback) {
  return function (word, event) {
    const isActive = callback !== "onWordMouseOut";
    const element = event.target;
    const text = select(element);
    text
      .on("click", () => {
        if (isActive) {
          window.open(
            `https://wordhippo.com/what-is/sentences-with-the-word/${word.text}.html`,
            "_blank"
          );
        }
      })
      .transition()
      .attr("background", "white")
      .attr("text-decoration", isActive ? "underline" : "none");
  };
}

const callbacks = {
  getWordColor: (word) => (word.success === true ? "#4FA64F" : "#F88980"),
  getWordTooltip: (word) =>
    `${word.text} - ${word.agg_value} ${
      word.success ? "successful" : "unsuccessful"
    } messages include this word`,
  onWordClick: getCallback("onWordClick"),
  onWordMouseOut: getCallback("onWordMouseOut"),
  onWordMouseOver: getCallback("onWordMouseOver"),
  //   getWordColor: (word) => {
  //     let failColorLess = [0xf8, 0x89, 0x80]; //#F88980
  //     let failColorMore = [0xd5, 0x12, 0x00]; //#D51200
  //     let succColorLess = [0xc8, 0xe6, 0xc9]; //#C8E6C9
  //     let succColorMore = [0x1c, 0x5e, 0x21]; //#4FA64F
  //
  //     // Returned a scaled color array between two input color array, scale from 0 to 100
  //     let getScaledColor = (lessColor, moreColor, scale) => {
  //       let resultColor = [0, 0, 0];
  //       for (let i = 0; i < 3; ++i) {
  //         let diff = moreColor[i] - lessColor[i];
  //         resultColor[i] = Math.round(lessColor[i] + (diff * scale) / 100);
  //       }
  //
  //       return resultColor;
  //     };
  //
  //     if (word.success === false) {
  //       let successColor = getScaledColor(
  //         failColorLess,
  //         failColorMore,
  //         word.value
  //       );
  //       return rgbToHex(successColor[0], successColor[1], successColor[2]);
  //     } else {
  //       let successColor = getScaledColor(
  //         succColorLess,
  //         succColorMore,
  //         word.value
  //       );
  //       return rgbToHex(successColor[0], successColor[1], successColor[2]);
  //     }
  //   },

  // onWordClick: console.log,
  // onWordMouseOver: console.log,
};

export default WordCloud;
