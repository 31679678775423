import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function BarChartComponent({
  barData,
  secondBar,
  barKey,
  secondBarKey,
  legendText,
}) {
  const renderLegendText = () => {
    return (
      <span style={{ fontSize: "14px", color: "black" }}>
        {legendText.toLowerCase()}
      </span>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip shadow p-2 bg-white">
          <p className="label">{label}</p>
          {payload.map((category) => (
            <div key={category.name}>
              <small style={{ color: category.color }}>
                {category.value % 1
                  ? category.value.toFixed(2)
                  : category.value.toFixed()}
              </small>
              <br></br>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className="my-5"
      style={{
        position: "relative",
        height: "350px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      >
        {barData.length > 0 && (
          <ResponsiveContainer width="80%" height="100%" className="mx-auto">
            <BarChart data={barData}>
              <XAxis dataKey="name" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend formatter={renderLegendText} />
              <Bar className="pointer" dataKey={barKey} fill="#f9a59f" />
              {secondBar && (
                <Bar
                  className="pointer"
                  dataKey={secondBarKey}
                  fill="#8884D8"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
