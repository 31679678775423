import React from "react";
import { Card } from "react-bootstrap";
import "./HighlightCard.scss";
import CircleProgressBar from "../CircleProgressBar";
import HelpfulTip from "../../components/HelpfulTip.js";
import ShareFeature from "../../components/ShareFeature";
import { useSummaryStats } from "../../utils/data/sharedSummaryStats";

export default function HighlightCard({
  size,
  bgColor,
  icon,
  title,
  percentage,
  text,
  totalNumber,
  showNumStats,
  showProgressbar,
  tipTitle,
  tipSubtitle,
}) {
  const { appType } = useSummaryStats();
  // const [showRes, setShowRes] = useState(false);
  return (
    <div>
      <Card
        // onMouseEnter={() => setShowRes(true)}
        // onMouseLeave={() => setShowRes(false)}
        className={`highlight-card ${size} mr-4 border-0 my-3 p-0`}
        style={{ backgroundColor: bgColor }}
      >
        <Card.Body className="card-body d-flex flex-column">
          <div className="mx-auto my-4">
            {icon ? (
              <span className="icon-wrapper">
                <img src={icon} alt={title} className="icon" fluid="true" />
                {"  "}
              </span>
            ) : (
              <span className="icon-wrapper">
                <h4 className="p-0 m-0">😊</h4>
              </span>
            )}
          </div>
          <h5 className="title p-0 m-0 text-center">{title}</h5>
          {true && (
            <div className="px-3 p-0 m-0 mt-4 text-center h-75 d-flex justify-content-center align-items-center pointer">
              {showProgressbar === "true" && (
                <CircleProgressBar percentage={percentage} />
              )}
              {showNumStats === "true" && (
                <div className="num-stats d-flex mx-auto justify-content-center align-items-center">
                  <h1>{totalNumber}</h1>
                </div>
              )}
              {text && <h5 className="text-light">{text}</h5>}
            </div>
          )}
        </Card.Body>
        <div className="d-flex ml-auto m-3">
          <HelpfulTip
            iconWidth="20"
            tipTitle={tipTitle}
            tipSubtitle={tipSubtitle}
          />
        </div>
      </Card>
      <div className="share">
        <ShareFeature
          text={`Based on your ${appType ?? ""} usage data, ${title} is ${
            text ? text : percentage + "%"
          }.`}
        />
      </div>
    </div>
  );
}
