import { getRandomSubarray, getRandomInt } from "./calculations";
import { weekdayKeysFull, monthKeysFull } from "./WeekMonth";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const HOURS = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
];

const PCT_OPTIONS = [...Array(101).keys()];

const OPTION_NUM = 5;

const questionGenerator = (q, options, answer, explCallable, isRate) => {
  const choices = getRandomSubarray(
    options,
    Math.min(OPTION_NUM, options.length)
  );
  const answerIdx = choices.indexOf(answer);
  if (answerIdx < 0) {
    const randIdx = getRandomInt(OPTION_NUM);
    choices[randIdx] = answer;
  }

  return {
    question: q,
    options: !isRate
      ? choices.map((c) => c.toString())
      : choices.map((c) => c + "%"),
    answer: !isRate ? answer.toString() : answer + "%",
    explanation: explCallable(!isRate ? answer.toString() : answer + "%"),
  };
};

const GetQuestions = (summaryStats) => {
  const {
    likesToDates,
    matchesToDates,
    replyRate,
    matchRate,
    bestFirstMsgWord,
    bestMiddleMsgWord,
    fiveRandFirstMsgWords,
    fiveRandMiddleMsgWords,
    bestUsagePeriodSearch,
    bestMsgPeriodSearch,
    findFirstMsgOutcome,
    findMiddleMsgOutcome,
  } = summaryStats;

  const questionsBank = [
    {
      question:
        "About what percentage of the profiles you like do you think lead to dates?",
      options: PCT_OPTIONS,
      explanation: (ans) =>
        `About ${ans} of the profiles you like lead to dates.`,
      answer: likesToDates,
      isRate: true,
    },
    {
      question:
        "About what percentage of the matches you have had lead to dates?",
      options: PCT_OPTIONS,
      explanation: (ans) =>
        `About ${ans} of the matches you have lead to dates.`,
      answer: matchesToDates,
      isRate: true,
    },
    {
      question:
        "Which word has given you the most success in your opening messages?",
      options: fiveRandFirstMsgWords,
      explanation: (
        ans
      ) => `You have gotten replies with your first messages when you
        included the word "${ans}" more than any other word.`,
      answer: bestFirstMsgWord,
      isRate: false,
    },
    {
      question:
        "Which word has given you the most success in your messages while you are continuing a conversation?",
      options: fiveRandMiddleMsgWords,
      explanation: (
        ans
      ) => `After a conversation has started, including the word "${ans}" has gotten you more replies
        than any other word.`,
      answer: bestMiddleMsgWord,
      isRate: false,
    },
    {
      question: "What percentage of profiles you like also like you back?",
      options: PCT_OPTIONS,
      explanation: (ans) =>
        `About ${ans} of profiles you like also like you back.`,
      answer: matchRate,
      isRate: true,
    },
    {
      question: "About what percentage of your opening messages get a reply?",
      options: PCT_OPTIONS,
      explanation: (ans) =>
        `About ${ans} of the matches you contacted responded back to you.`,
      answer: replyRate,
      isRate: true,
    },
  ];

  const toFullName = (p) => {
    if (WEEKDAYS.includes(p)) {
      return weekdayKeysFull[p].name + "s";
    } else {
      if (MONTHS.includes(p)) {
        return monthKeysFull[p].name;
      }
      return p;
    }
  };

  const periods = ["month", "weekday", "hour"];
  for (const period of periods) {
    let preposition;
    let periodOptions;
    let periodTitle;
    switch (period) {
      case "month":
        preposition = "in";
        periodOptions = MONTHS;
        periodTitle = "month";
        break;
      case "weekday":
        preposition = "on";
        periodOptions = WEEKDAYS;
        periodTitle = "day of the week";
        break;
      case "hour":
        preposition = "at";
        periodOptions = HOURS;
        periodTitle = "hour of the day";
    }

    let bestPeriod = bestUsagePeriodSearch(period, "match_rate");
    if (bestPeriod != null) {
      questionsBank.push({
        question: `During which ${periodTitle} do you have the highest match rate?`,
        options: periodOptions,
        explanation: (ans) =>
          `You have the highest match rate ${preposition} ${toFullName(ans)}.`,
        answer: bestPeriod,
        isRate: false,
      });
    }

    bestPeriod = bestUsagePeriodSearch(period, "swipes_likes");
    if (bestPeriod != null) {
      questionsBank.push({
        question: `During which ${periodTitle} are you most active on the app?`,
        options: periodOptions,
        explanation: (ans) =>
          `You liked more profiles ${preposition} ${toFullName(
            ans
          )} than any other ${periodTitle}.`,
        answer: bestPeriod,
        isRate: false,
      });
    }

    questionsBank.push({
      question: `During which ${periodTitle} do you have the most success with your opening messages?`,
      options: periodOptions,
      explanation: (ans) =>
        `You have the highest reply rate ${preposition} ${toFullName(ans)}.`,
      answer: bestMsgPeriodSearch(period, "maybe_succ_first_rate"),
      isRate: false,
    });
    questionsBank.push({
      question: `During which ${periodTitle} do you have the most success with continuing a conversation
          that you have already started?`,
      options: periodOptions,
      explanation: (
        ans
      ) => `When trying to continue a conversation, you have the highest reply rate to
          your messages ${preposition} ${toFullName(ans)}.`,
      answer: bestMsgPeriodSearch(period, "maybe_succ_middle_rate"),
      isRate: false,
    });
    questionsBank.push({
      question: `During which ${periodTitle} are you most active in terms of
          asking out your matches for dates?`,
      options: periodOptions,
      explanation: (ans) =>
        `You have sent the most messages asking out your matches ${preposition} ${toFullName(
          ans
        )}`,
      answer: bestMsgPeriodSearch(period, "maybe_arrange_dates"),
      isRate: false,
    });
    questionsBank.push({
      question: `During which ${periodTitle} are you most effective with your messages?`,
      options: periodOptions,
      explanation: (
        ans
      ) => `A higher proportion of your messages eventually led to a date
          ${preposition} ${toFullName(
        ans
      )} than ${preposition} any other ${periodTitle}.`,
      answer: bestMsgPeriodSearch(period, "maybe_succ_to_dates_rate"),
      isRate: false,
    });
  }

  const textMetricMap = {
    word_cnt: "words",
    question_mark_cnt: "question marks",
    exclamation_mark_cnt: "exclamation marks",
    emoji_cnt: "emojis",
  };

  const msgExplMap = {
    "first messages": {
      successful: "got replies",
      unsuccessful: "did't get replies",
    },
    "continuing messages": {
      successful: "continued the conversation",
      unsuccessful: "ended the conversation",
    },
  };

  const typeSwitch = (t) =>
    t === "successful" ? "unsuccessful" : "successful";

  for (const [metric, metricParsed] of Object.entries(textMetricMap)) {
    questionsBank.push({
      question: `Do you use a higher number of ${metricParsed} in your successful or
          unsuccessful opening messages?`,
      options: ["successful", "unsuccessful"],
      explanation: (ans) => `For your opening messages, the ones that ${
        msgExplMap["first messages"][ans]
      } used more ${metricParsed}
          than the ones that ${msgExplMap["first messages"][typeSwitch(ans)]}.`,
      answer: findFirstMsgOutcome(metric),
      isRate: false,
    });
    questionsBank.push({
      question: `Do you use a higher number of ${metricParsed} in your successful or
          unsuccessful messages when trying to continue a conversation with a match?`,
      options: ["successful", "unsuccessful"],
      explanation: (ans) => `While chatting with a match, your messages that ${
        msgExplMap["continuing messages"][ans]
      }
          used more ${metricParsed} than the ones that ${
        msgExplMap["continuing messages"][typeSwitch(ans)]
      }.`,
      answer: findMiddleMsgOutcome(metric),
      isRate: false,
    });
  }

  const validQuestions = questionsBank.filter(
    (s) =>
      !(s.answer == null) &&
      !s.options.includes(null) &&
      !s.options.includes(undefined)
  );
  const QuestionsList = validQuestions.map((s) =>
    questionGenerator(s.question, s.options, s.answer, s.explanation, s.isRate)
  );

  return QuestionsList;
};

export default GetQuestions;
