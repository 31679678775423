import React, { useState, useEffect } from "react";
import { Button, Container, Tab, Tabs, Row, Col } from "react-bootstrap";
import { useDocument } from "react-firebase-hooks/firestore";
import TopSectionHeader from "../components/TopSectionHeader";
import SectionHeader from "../components/SectionHeader";
import PeachIcon from "../assets/icons/peach.svg";
import DownloadInfoImg from "../assets/images/instructionsDownload.png";
import DownloadInfoImg2 from "../assets/images/instructionsDownload2.png";
import InstructionsEmail from "../assets/images/InstructionsEmail.png";
import InstructionsFile from "../assets/images/instructionsFile.png";
import HingeInstruction1 from "../assets/images/hinge-instr-1.jpg";
import HingeInstruction2 from "../assets/images/hinge-instr-2.jpg";
import HingeInstruction3 from "../assets/images/hinge-instr-3.jpg";
import HingeInstruction4 from "../assets/images/hinge-instr-4.jpg";
import FileUploader from "../components/LandingPageComponent/FileUploader";
import SideNavBar from "../components/SideNavBar";
import firebase from "../utils/firebase";
import { useRouter } from "../utils/router";
import { useAuth } from "../utils/auth";

import "./tabs.scss";

function PeachInstructionsPage() {
  const [key, setKey] = useState("tinder");
  const [showAlert, setShowAlert] = useState(false);
  const router = useRouter();
  const auth = useAuth();
  const [demoAccounts] = useDocument(firebase.firestore().doc(`demo/accounts`));
  let demoUID;

  useEffect(() => {
    if (
      router &&
      router.location &&
      router.location.state &&
      (router.location.state.history === "/auth/signup" ||
        router.location.state.noData)
    ) {
      setShowAlert(true);
    }

    if (demoAccounts && demoAccounts.data() != undefined) {
      console.log(`fetching demo mode uid...`);
      demoUID = demoAccounts.data()["uid"];
    }
  });

  const goToDemoDashboard = () => {
    router.push({
      pathname: "/peach/insights/demo_mode",
      state: {
        demoUID: demoUID,
      },
    });
    localStorage.setItem("demoUID", demoUID);
  };

  return (
    <>
      <TopSectionHeader
        bg="white"
        bgColor="#10162f"
        textColor="white"
        size="md"
        title="You are almost there!"
        icon={PeachIcon}
        subtitle="Benefit from the wisdom of your own data on Peach!"
        subtitleColor="light"
      />

      <Row style={{ backgroundColor: "#f8f4eb" }}>
        <SideNavBar />
        <Col>
          <div className="peach-instructions">
            <Container>
              {showAlert && (
                <div className="bg-danger text-white mt-5 rounded p-4">
                  <h5>Please upload data before proceeding.</h5>
                  <p>
                    You have not uploaded your data yet so there&apos;s nothing
                    to show...
                  </p>
                </div>
              )}
              {auth.user && (
                <div
                  style={{ backgroundColor: "#FFEACA" }}
                  className="upload-file-wrapper mt-5 text-center rounded px-5 pb-5 mx-auto"
                >
                  <SectionHeader
                    title={"Upload Your Tinder or Hinge Data"}
                    subtitle={
                      "For personalized insights and more, follow the instructions below to download your dating app's data. Then upload the data here and start exploring!"
                    }
                    subtitleColor="dark"
                    className="text-left mt-5 p-4 rounded"
                    size={2}
                    spaced={true}
                  />
                  <div className="px-5">
                    <FileUploader buttonColor="primary" buttonText="Upload" />
                  </div>
                  <div>
                    <Button
                      onClick={goToDemoDashboard}
                      className="bg-transparent border-0 px-0"
                    >
                      <u className="text-primary px-0">
                        Still waiting for your own data? Check out demo mode!
                      </u>
                    </Button>
                  </div>
                </div>
              )}
              <Tabs
                id="instructions"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mt-5"
              >
                <Tab eventKey="tinder" title="Tinder">
                  <div className="steps p-4">
                    <h3 className="mt-5 mb-2 pt-5">STEP 1</h3>
                    <p>
                      Visit{" "}
                      <a href="https://account.gotinder.com/data">Go Tinder</a>{" "}
                      on a desktop computer and login to your account.
                    </p>
                    <h3 className="mt-5 mb-2 pt-5">STEP 2</h3>
                    <p>Click on the “DOWNLOAD MY INFORMATION” button.</p>
                    <img
                      className="mt-3 img-fluid"
                      src={DownloadInfoImg}
                      alt="Download info"
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 3</h3>
                    <p>
                      Enter the email address where you wish to receive the data
                      and click the “SUBMIT” button.
                    </p>
                    <img
                      className="mt-3 img-fluid"
                      src={DownloadInfoImg2}
                      alt="Download info"
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 4</h3>
                    <p>
                      Wait for the following email. Usually the data will arrive
                      in the following email within 24 hours, but sometimes it
                      can take up to 4 days to receive the email.
                    </p>
                    <img
                      className="mt-3 img-fluid"
                      src={InstructionsEmail}
                      alt="Instructions Email"
                      fluid
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 5</h3>
                    <p>
                      Make sure to check your email and spam folder frequently,
                      and download the data by clicking on the “Download Here”
                      link in the email immediately after it arrives as Tinder
                      will make the file inaccessible 24 hours after sending it.
                      Unzip the folder and upload the data.json file to start
                      exploring!
                    </p>
                    <img
                      className="mt-3 mb-5 pb-5 img-fluid"
                      src={InstructionsFile}
                      alt="Instructions File"
                    />
                  </div>
                </Tab>
                <Tab eventKey="hinge" title="Hinge">
                  <div className="steps p-4">
                    <h3 className="mt-5 mb-2 pt-5">STEP 1</h3>
                    <p>Open the Hinge App on your phone.</p>
                    <h3 className="mt-5 mb-2 pt-5">STEP 2</h3>
                    <p>
                      Navigate to the Settings page by tapping on the icon in
                      the bottom right corner. Select “Account”.
                    </p>
                    <img
                      className="mt-3 img-fluid"
                      src={HingeInstruction1}
                      alt="Select Account"
                      width="400"
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 3</h3>
                    <p>Tap on “Download My Data” in the Account Page.</p>
                    <img
                      className="mt-3 img-fluid"
                      src={HingeInstruction2}
                      alt="Download Data"
                      width="400"
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 4</h3>
                    <p>
                      Enter your country and state. Select “Download My Data”.
                      Confirm the Email where you want to receive the data and
                      submit the request!
                    </p>
                    <img
                      className="mt-3 img-fluid"
                      src={HingeInstruction3}
                      alt="Download Data"
                      width="400"
                    />
                    <h3 className="mt-5 mb-2 pt-5">STEP 5</h3>
                    <p>
                      Wait for the following email. Usually the data will arrive
                      in the following email within 24 hours, but sometimes it
                      can take up to 4 days to receive the email.
                    </p>
                    <img
                      className="mt-3 mb-5 pb-5 img-fluid"
                      src={HingeInstruction4}
                      alt="Access your data"
                      width="500"
                    />
                    <h3 className="mt-5 mb-2">STEP 6</h3>
                    <p className="mb-5">
                      Make sure to check your email and spam folder frequently,
                      and follow the instructions in the email to download the
                      data. Unzip the folder and upload the matches.json file to
                      start exploring!
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default PeachInstructionsPage;
