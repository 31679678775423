import React from "react";
import { Col, Row, Image } from "react-bootstrap";

export default function AboutCard({ title, description, image, index }) {
  return (
    <Row className="align-items-center my-5" key={index}>
      <Col xs={12} lg={6} className="mb-3">
        <h1>{title}</h1>
        <p>{description}</p>
      </Col>
      <Col>
        <figure>
          <Image src={image} alt={title} fluid="true" className="rounded" />
        </figure>
      </Col>
    </Row>
  );
}
