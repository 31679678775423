import React, { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MessageVotingSection from "../components/PeachDashboardComponent/MessageVotingSection";
import TopSectionHeader from "../components/TopSectionHeader";
import { useDocument } from "react-firebase-hooks/firestore";
import Cookies from "js-cookie";
import firebase from "../utils/firebase";
import { useAuth } from "../utils/auth.js";
import { useRouter } from "../utils/router";
import PeachIcon from "../assets/icons/peach.svg";
import SideNavBar from "../components/SideNavBar";
import BackendApi from "../models/backend";
import AnonymousUserModal from "../components/PeachDashboardComponent/AnonymousUserModal";

export default function MessagesPage() {
  let { status } = useParams();
  const router = useRouter();
  const cachedAppType = Cookies.get("app_type");
  const [dataType, setDataType] = useState(cachedAppType);
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);
  const timeoutLimit = 10 * 1000; //10 seconds

  if (auth.user === false) {
    router.push("/");
  }

  let uid;
  if (status === "demo_mode") {
    uid = localStorage.getItem("demoUID");
  } else {
    uid = auth.user && auth.user.uid;
  }
  const [userData] = useDocument(firebase.firestore().doc(`users/${uid}`));

  let BA = new BackendApi({
    user: auth.user,
    dataType: dataType,
    demoUID: status && uid,
  });
  BA.fetchData();

  useEffect(() => {
    if (status) {
      setShowModal(true);
    }
  }, [status]);

  useEffect(() => {
    if (
      userData &&
      userData.data() !== undefined &&
      !("data_types" in userData.data())
    ) {
      var dataCheckTimer = setTimeout(() => {
        router.push({
          pathname: "/peach/upload",
          state: {
            noData: true,
          },
        });
      }, timeoutLimit);
    } else {
      if (
        userData &&
        userData.data() != undefined &&
        "data_types" in userData.data()
      ) {
        setDataType(Object.keys(userData.data()["data_types"])[0]);
      }
    }

    return () => {
      clearTimeout(dataCheckTimer);
    };
  }, [userData]);

  const handleSelectDataType = (event) => {
    setDataType(event);
  };

  return (
    <>
      <AnonymousUserModal showModal={showModal} setShowModal={setShowModal} />
      <TopSectionHeader
        bgColor="#10162f"
        textColor="white"
        size="md"
        title="Peach"
        icon={PeachIcon}
        subtitle="Benefit from the wisdom of your own data on Peach!"
        subtitleColor="light"
      />
      <Row style={{ backgroundColor: "#f8f4eb" }}>
        <SideNavBar status={status} />
        <Col>
          <Container className="mt-5">
            <div className="title-header text-right">
              <h4>Select your data</h4>
              <DropdownButton
                id="dropdown-basic-button"
                title={dataType}
                onSelect={(e) => handleSelectDataType(e)}
              >
                {auth.user ? (
                  userData &&
                  userData.data()["data_types"] &&
                  Object.keys(userData.data()["data_types"]).map(
                    (dtype, idx) => (
                      <Dropdown.Item key={idx} eventKey={dtype}>
                        {dtype}
                      </Dropdown.Item>
                    )
                  )
                ) : (
                  <Dropdown.Item eventKey={cachedAppType}>
                    {cachedAppType}
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
            <MessageVotingSection
              title="Select your messages to get community feedback"
              size="md"
              dataType={dataType}
              BA={BA}
            />
          </Container>
        </Col>
      </Row>
    </>
  );
}
