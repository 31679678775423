import React from "react";
import "./MessageBubble.scss";
import deleteIcon from "../assets/icons/delete.svg";

const messageColorMap = (appSource) => {
  switch (appSource) {
    case "Tinder":
      return "#1ac4f8";
    case "Hinge":
      return "#6c45eb";
    default:
      return "#1ac4f8";
  }
};

export default function MessageBubble({
  lastMsg,
  message,
  handleDelete,
  msgId,
  showDelete,
  appSource,
}) {
  return (
    <div className="w-75 ml-auto position-relative">
      {showDelete && (
        <img
          src={deleteIcon}
          width="25"
          className="delete-icon pointer position-absolute"
          onClick={() => handleDelete(msgId)}
        />
      )}
      <p
        className={`p-3 m-0 mb-3 ${
          lastMsg ? "message-bubble-last" : "message-bubble"
        }`}
        style={{backgroundColor: messageColorMap(appSource)}}
      >
        {message}
      </p>{" "}
      <div className="triangle"></div>
    </div>
  );
}
