import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TopSectionHeader from "../TopSectionHeader";
import { useAuth } from "../../utils/auth.js";
import { useRouter } from "../../utils/router";
import SideNavBar from "../OrchardSideNavBar";

function OrchardPage(Component) {
  return function WrappedComponent() {
    const auth = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (auth.user === false) {
        router.push("/");
      }
    }, [auth]);
    return (
      <>
        <TopSectionHeader
          bg="white"
          bgColor="#10162f"
          textColor="white"
          subtitleColor="white"
          size="lg"
          title="The Orchard 🌳"
          subtitle="Benefit from the collective wisdom of fellow users on Peach!
        "
        />
        <Row style={{ backgroundColor: "#f8f4eb" }}>
          <SideNavBar />
          <Col>
            <Component />
          </Col>
        </Row>
      </>
    );
  };
}
export default OrchardPage;
