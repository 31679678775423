import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import PeachFruit from "../assets/images/peach_fruit.svg";
import Forest from "../assets/images/forest.svg";
import { useRouter } from "../utils/router";

export default function RoleSelect() {
  const router = useRouter();

  const handleShareAdviceClick = () => {
    router.push({
      pathname: "/orchard",
    });
  };
  const handleGetAdviceClick = () => {
    router.push({
      pathname: "/peach/insights",
    });
  };
  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column align-items-center justify-content-center"
      style={{ backgroundColor: "#14213d" }}
    >
      <Row>
        <header>
          <p className="h1 font-weight-bold text-secondary">
            {"what's on your mind today?"}
          </p>
        </header>
      </Row>
      <Row>
        <Col lg={6} className="d-flex justify-content-center">
          <Card className="role-card my-5" onClick={handleShareAdviceClick}>
            <Card.Img
              src={Forest}
              alt="Forest Image"
              style={{ opacity: "0.4", height: "80%" }}
            />
            <Card.ImgOverlay className="role-card-body">
              <Card.Body className="d-flex justify-content-center">
                <h1 className="text-center">share advice</h1>
              </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </Col>
        <Col lg={6} className="d-flex justify-content-center">
          <Card className="role-card my-5" onClick={handleGetAdviceClick}>
            <Card.Img
              src={PeachFruit}
              alt="Peach Image"
              style={{ opacity: "0.4", height: "80%" }}
            />
            <Card.ImgOverlay className="role-card-body">
              <Card.Body className="d-flex justify-content-center">
                <h1>get advice</h1>
              </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
