import React from "react";
import { Row, Col } from "react-bootstrap";
import BackgroundImage from "../BackgroundImage";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import "./QuoteSection.scss";
import RightQuote from "../../assets/images/right-quotes-sign.svg";
import LeftQuote from "../../assets/images/left-quotes-sign.svg";

function ImageQuoteSection(props) {
  return (
    <Section size={props.size} style={{ backgroundColor: `${props.bg}` }}>
      <Row
        className="align-items-center mx-0"
        style={{
          backgroundColor: "#10162f",
          zIndex: "1001",
        }}
      >
        <Col xs={12} lg={6} className="QuoteSection__image-container">
          {props.bgImage && (
            <BackgroundImage
              image={props.bgImage}
              opacity={props.bgImageOpacity}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-center px-5 py-5 align-items-center">
          <div className="mx-auto">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              spaced={true}
              size={3}
              className="text-lg-left"
            />
            <div className="d-flex flex-row justify-content-start mb-3 ml-5">
              <img
                src={LeftQuote}
                alt="left-quote"
                width="30"
                style={{ opacity: "0.3" }}
              />
            </div>
            <p className="text-light">{props.descText}</p>
            <div className="d-flex flex-row justify-content-around">
              <img
                src={RightQuote}
                alt="right-quote"
                width="30"
                style={{ opacity: "0.3" }}
              />
            </div>
          </div>
        </Col>
      </Row>{" "}
    </Section>
  );
}
export default ImageQuoteSection;
