import React, { useState, useContext, createContext, useEffect } from "react";
import { calcReplyRate, getRandomSubarray } from "./calculations";

export const findLargestKey = (object) => {
  const keys = Object.keys(object);
  const vals = Object.values(object);
  if (vals.length == 0) {
    return null;
  }

  const arr = keys.map((k, i) => ({ k: k, val: vals[i] }));
  arr.sort((a, b) => b.val - a.val);
  return arr[0].k;
};

const findMostSuccWord = (data) => {
  return findLargestKey(data.succ_wd_freq);
};

const findBestPeriod = (data, period, metric) => {
  if (!(period in data) || !(metric in data[period])) {
    return null;
  }
  const histoData = data[period][metric];
  return findLargestKey(histoData);
};

const findLargestKeybyNestedVal = (object, nestedKey) => {
  const keys = Object.keys(object);
  const vals = Object.values(object);
  if (vals.length == 0) {
    return null;
  }

  const arr = keys.map((k, i) => ({ k: k, val: vals[i] }));
  arr.sort((a, b) => b.val[nestedKey] - a.val[nestedKey]);
  return arr[0].k;
};

const summaryContext = createContext();

// Context Provider component that wraps around component and makes summary stat object
// available to any child component that calls the useSummaryStats() hook.
export function SummaryProvider({ children, ...props }) {
  const summary = useStats(props);
  return (
    <summaryContext.Provider value={summary}>
      {children}
    </summaryContext.Provider>
  );
}

// Hook that enables any component to subscribe to summary stats
export const useSummaryStats = () => {
  return useContext(summaryContext);
};

function useStats(props) {
  const {
    appType,
    funnelData,
    BifurcateMsgsDataFirst,
    BifurcateMsgsDataMiddle,
    DailyUsageHistoData,
    MessageHistoData,
    MessageTextDetailHistoData,
  } = props;

  const [likesToDates, setLikesToDates] = useState(null);
  const [matchesToDates, setMatchesToDates] = useState(null);
  const [replyRate, setReplyRate] = useState(null);
  const [matchRate, setMatchRate] = useState(null);
  const [bestFirstMsgWord, setBestFirstMsgWord] = useState(null);
  const [bestMiddleMsgWord, setBestMiddleMsgWord] = useState(null);
  const [fiveRandFirstMsgWords, setFiveRandFirstMsgWords] = useState(null);
  const [fiveRandMiddleMsgWords, setFiveRandMiddleMsgWords] = useState(null);

  useEffect(() => {
    if (funnelData) {
      setLikesToDates(
        Math.round((funnelData.Number_of_Dates / funnelData.Likes) * 100)
      );
      setMatchesToDates(
        Math.round(
          (funnelData.Number_of_Dates / funnelData.Total_Matches) * 100
        )
      );
      setMatchRate(
        Math.round((funnelData.Total_Matches / funnelData.Likes) * 100)
      );
    }
  }, [funnelData]);

  useEffect(() => {
    if (MessageHistoData) {
      setReplyRate(calcReplyRate(MessageHistoData));
    }
  }, [MessageHistoData]);

  useEffect(() => {
    if (BifurcateMsgsDataFirst) {
      setBestFirstMsgWord(findMostSuccWord(BifurcateMsgsDataFirst));
      setFiveRandFirstMsgWords(
        getRandomSubarray(Object.keys(BifurcateMsgsDataFirst.fail_wd_freq), 5)
      );
    }
  }, [BifurcateMsgsDataFirst]);

  useEffect(() => {
    if (BifurcateMsgsDataMiddle) {
      setBestMiddleMsgWord(findMostSuccWord(BifurcateMsgsDataMiddle));
      setFiveRandMiddleMsgWords(
        getRandomSubarray(Object.keys(BifurcateMsgsDataMiddle.fail_wd_freq), 5)
      );
    }
  }, [BifurcateMsgsDataMiddle]);

  const bestUsagePeriodSearch = (period, metric) => {
    return findBestPeriod(DailyUsageHistoData, period, metric);
  };

  const bestMsgPeriodSearch = (period, metric) => {
    return findBestPeriod(MessageHistoData, period, metric);
  };

  const findFirstMsgOutcome = (metric) => {
    const metricVals = MessageTextDetailHistoData["first messages"];
    return findLargestKeybyNestedVal(metricVals, metric);
  };

  const findMiddleMsgOutcome = (metric) => {
    const metricVals = MessageTextDetailHistoData["continuing messages"];
    return findLargestKeybyNestedVal(metricVals, metric);
  };

  return {
    appType,
    likesToDates,
    matchesToDates,
    replyRate,
    matchRate,
    bestFirstMsgWord,
    bestMiddleMsgWord,
    fiveRandFirstMsgWords,
    fiveRandMiddleMsgWords,
    bestUsagePeriodSearch,
    bestMsgPeriodSearch,
    findFirstMsgOutcome,
    findMiddleMsgOutcome,
  };
}
