import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import QuizIcon from "../assets/icons/quiz.svg";
import PerformanceIcon from "../assets/icons/performance.svg";
import KeyStatsIcon from "../assets/icons/stats.svg";
import WordCloudIcon from "../assets/icons/wordCloud.png";

export default function TopNavBar({ status }) {
  return (
    <div className="w-100">
      <Navbar
        // variant="light"
        // bg="transparent"
        className="ml-auto d-flex flex-row justify-content-between bg-light"
        style={{ textAlign: "left" }}
      >
        <hr></hr>
        <Nav.Link
          className="navbar-link d-flex justify-content-center"
          href={`/peach/insights/${
            status ? status + "/" : ""
          }#peach-highlights`}
        >
          <span className="larger-icon icon-wrapper ">✨</span>
          Highlights
        </Nav.Link>
        <Nav.Link
          className="navbar-link d-flex justify-content-center"
          href={`/peach/insights/${status ? status + "/" : ""}#peach-quiz`}
        >
          <span className="icon-wrapper">
            <img className="icon" src={QuizIcon} alt="quiz" />
          </span>
          Quiz
        </Nav.Link>
        <Nav.Link
          className="navbar-link d-flex justify-content-center"
          href={`/peach/insights/${status ? status + "/" : ""}#peach-summary`}
        >
          <span className="icon-wrapper">
            <img className="icon" src={PerformanceIcon} alt="summary" />
          </span>
          Performance Summary
        </Nav.Link>
        <Nav.Link
          className="navbar-link d-flex justify-content-center"
          href={`/peach/insights/${
            status ? status + "/" : ""
          }#peach-word-cloud`}
        >
          <span className="icon-wrapper">
            <img className="icon" src={WordCloudIcon} alt="word cloud" />
          </span>
          Word Cloud
        </Nav.Link>
        <Nav.Link
          className="navbar-link d-flex justify-content-center"
          href={`/peach/insights/${status ? status + "/" : ""}#peach-stats`}
        >
          <span className="icon-wrapper">
            <img className="icon" src={KeyStatsIcon} alt="stats" />
          </span>
          Key Stats
        </Nav.Link>
      </Navbar>
    </div>
  );
}
