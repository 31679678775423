import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { firestore } from "../utils/firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import MessageBubble from "../components/MessageBubble";
import { CommentSection } from "../components/CommentsSectionComponent/CommentSection";
import firebase from "../utils/firebase";
import { useObjectVal } from "react-firebase-hooks/database";
import ImageModal from "./ImageUploadComponent/ImageModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp as faThumbsUpReg,
  faThumbsDown as faThumbsDownReg,
} from "@fortawesome/free-regular-svg-icons";
import "./CommunityVoteCard.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export function CommentsSidePanel(props) {
  const {
    userUID,
    sectionUID,
    setViewComments,
    commentsCount,
    shareScore,
    handleShareScore,
    checkBoxDisabled,
  } = props;
  const name = userUID.slice(0, 3) + userUID.slice(-3);
  const bgColor = "99d98c";
  const avatarUrl = `https://ui-avatars.com/api/name=${name[0]}&background=${bgColor}&color=fff`;
  // const [width, setWidth] = useState("530px");
  const [profileData] = useDocument(
    firebase.firestore().doc(`public_profiles/${userUID}`)
  );

  const closeComments = () => {
    // setWidth("0px");
    setViewComments(false);
  };

  return (
    <div className="sidepanel" style={{ width: "530px" }}>
      <button
        className="closebtn bg-transparent border-0"
        onClick={() => closeComments()}
      >
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          color="#a5a5a5"
          className="mx-0"
        />
      </button>
      <div className="custom-control custom-checkbox mx-2 mb-2">
        <input
          type="checkbox"
          disabled={checkBoxDisabled}
          checked={!!shareScore}
          onChange={handleShareScore}
          id="customCheck"
          className="custom-control-input mr-2 p-0 pointer"
        />
        <label
          className="custom-control-label mb-3 p-0"
          style={{ fontSize: ".9em" }}
          htmlFor="customCheck"
        >
          Show your dating app summary stats in your discussions with fellow
          Peach users to help us maintain a <i>credibility-driven </i>
          community. Go to
          <a href="/profile"> Profile </a>to change the dating app you want to
          show stats from, if you have uploaded data from multiple apps.
        </label>
      </div>
      <div className="header mx-2 mb-2">{commentsCount} Comments</div>
      <CommentSection
        currentUser={
          userUID && {
            userId: userUID,
            avatarUrl: avatarUrl,
            name: name,
            ...profileData?.data(),
          }
        }
        sectionId={sectionUID}
      />
    </div>
  );
}

function MessageBody({ payload }) {
  const { messages_list, app_source } = payload;

  return (
    <div className="message bg-white border rounded p-3 mb-3">
      {messages_list.length > 0 &&
        messages_list.map((item, index) => (
          <div key={index} className="d-flex justify-content-end">
            <div className="mb-3 pr-2 text-right">
              <MessageBubble
                lastMsg={index === messages_list.length - 1}
                message={item.message}
                appSource={app_source}
              />
              <small>{new Date(item.timestamp * 1000).toString()}</small>
            </div>
          </div>
        ))}
    </div>
  );
}

function ImageBody({ payload }) {
  const { content } = payload;
  const { image_count } = content;
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClick = (index, item) => {
    setSelectedImg(item.props.children.props?.src);
  };

  return (
    <div className="d-flex justify-content-center">
      <Carousel
        showThumbs={false}
        onClickItem={(index, item) => handleClick(index, item)}
        className="carousel"
      >
        {[...Array(image_count).keys()].map((e) => (
          <div key={e} className="carousel-child">
            <img src={content[e].url} alt="user uploaded image" />
          </div>
        ))}
      </Carousel>
      {selectedImg && (
        <ImageModal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </div>
  );
}

const ENUM_STATES = (payload) => ({
  message: <MessageBody payload={payload} />,
  image: <ImageBody payload={payload} />,
});

function CardBody({ mediaType, payload }) {
  return ENUM_STATES(payload)[mediaType];
}

export default function CommunityVoteCard({
  userUID,
  payload,
  mediaType,
  collectionName,
  setViewComments,
  setUid,
  setCommentsCount,
  setCommentsLoading,
}) {
  // const [viewComments, setViewComments] = useState(false);
  // const [commentsCount, setCommentsCount] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const { votes, uid, user_id, tag } = payload;

  const [upVoted, setUpvoted] = useState(false);
  const [downVoted, setDownVoted] = useState(false);
  const [likesNum, setLikesNum] = useState(
    Object.values(votes).filter((e) => e === 1).length
  );

  useEffect(() => {
    if (userUID in votes && votes[userUID]) {
      setUpvoted(true);
    } else {
      if (userUID in votes) setDownVoted(true);
    }
  }, [votes]);

  const [commentsCount, loading] = useObjectVal(
    firebase.database().ref(`comments/${uid}/comments_count`)
  );

  const handleRating = async (rating) => {
    await firestore
      .collection(collectionName)
      .doc(uid)
      .set({ votes: { [userUID]: rating } }, { merge: true });

    setLikesNum((prevNum) => {
      if ((upVoted && rating) || (downVoted && rating === 0)) {
        return prevNum;
      } else {
        const change = rating ? 1 : -1;
        return prevNum + change;
      }
    });

    setUpvoted(rating === 1);
    setDownVoted(rating === 0);
  };

  const handleDeleteMessage = async () => {
    setDeleted(true);
    await firestore.collection(collectionName).doc(uid).delete();
  };

  const handleOpenComments = () => {
    setViewComments(true);
    setUid(uid);
    setCommentsCount(commentsCount);
    setCommentsLoading(loading);
  };

  return (
    <>
      <Card className="vote-card-wrapper rounded mb-5 bg-light border w-100">
        {tag && (
          <p className="card-header w-100 p-3 text-light">
            {tag}
            {/* <strong className="text-light">{tag}</strong> */}
          </p>
        )}
        <Card.Body className="w-100">
          <CardBody mediaType={mediaType} payload={payload} />
          <div className="text-right mt-3">
            <span className="font-weight-bold h5">{likesNum} </span>
            <span className="text-secondary">likes</span>
          </div>
        </Card.Body>
        <Card.Footer className="w-100 bg-light">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-n2 mx-1">
              <button
                onClick={handleOpenComments}
                className="bg-transparent border-0 px-0"
              >
                <div className="d-flex align-items-center justify-content-end">
                  <FontAwesomeIcon
                    icon={faComment}
                    size="lg"
                    color="gray"
                    className="mx-0 comment-icon"
                  />
                  <span className="mx-2 small">
                    {!loading && commentsCount ? commentsCount : 0}
                  </span>
                </div>
              </button>{" "}
            </div>
            <div className="mb-0">
              <button
                className="p-0 m-0 bg-transparent border-0 mr-2"
                onClick={() => handleRating(1)}
              >
                <FontAwesomeIcon
                  icon={upVoted ? faThumbsUpSolid : faThumbsUpReg}
                  size="lg"
                  color="gray"
                  className="mx-0 vote-icon"
                />{" "}
              </button>
              <button
                className="p-0 m-0 bg-transparent border-0 ml-2"
                onClick={() => handleRating(0)}
              >
                <FontAwesomeIcon
                  icon={downVoted ? faThumbsDownSolid : faThumbsDownReg}
                  size="lg"
                  color="gray"
                  className="mx-0 vote-icon"
                />
              </button>
            </div>
            {user_id === userUID && (
              <div>
                <Button
                  onClick={handleDeleteMessage}
                  className="bg-transparent border-0 px-0"
                >
                  {!deleted ? (
                    <u className="text-primary px-0">Delete</u>
                  ) : (
                    <u className="text-success px-0">Deleted</u>
                  )}
                </Button>
              </div>
            )}
          </div>
        </Card.Footer>
      </Card>
    </>
  );
}
