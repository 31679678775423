import React, { useState, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import AppAccountInfo from "../components/ProfilePageComponent/AppAccountInfoSection";
import TopSectionHeader from "../components/TopSectionHeader";
import PeachProfile from "../components/ProfilePageComponent/PeachProfile";
import { useAuth } from "../utils/auth.js";
import { useRouter } from "../utils/router";
import { firestore } from "../utils/firebase";
import firebase from "../utils/firebase";
import { COLLECTION_MAP } from "../models/backend";
import { Container, Col, Row } from "react-bootstrap";
import { toggleScoreSharing } from "../utils/db";

const APPS = ["Tinder", "Hinge", "asdfasdfasdf"];
const COLORS = { Tinder: "rgb(248, 232, 231)", Hinge: "rgb(228, 228, 248)" };

function ProfilePage() {
  const auth = useAuth();
  const router = useRouter();
  const uid = auth && auth.finalUser && auth.finalUser.uid;
  const [userAppData, setUserAppData] = useState({});

  const appSelectInit = {};
  APPS.forEach((key) => (appSelectInit[key] = false));
  const [appSelect, setAppSelect] = useState(appSelectInit);
  const [shareScore, setShareScore] = useState(false);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(true);

  const [userData] = useDocument(firebase.firestore().doc(`users/${uid}`));
  // const [profileDetails] = useDocument(firebase.firestore().doc(`profile_details/${uid}`));

  useEffect(() => {
    if (uid) {
      firestore
        .collection("public_profiles")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists && "summary_scores" in doc.data()) {
            setShareScore(true);
          }
        });
    }
  }, [uid, shareScore]);

  const fetchData = async (dataType) => {
    try {
      const response = await firestore
        .collection(COLLECTION_MAP["registered"][dataType])
        .doc(uid)
        .get();
      if (response.exists) {
        setUserAppData((prev) => ({ ...prev, [dataType]: response.data() }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEnableSharing = () => {
    toggleScoreSharing(shareScore, setShareScore, uid);
  };

  const handleSelectCheckBox = (appType) => {
    setAppSelect(() => {
      const newSelect = {};
      for (const app of APPS) {
        if (app === appType) {
          newSelect[app] = true;
        } else {
          newSelect[app] = false;
        }
      }
      return newSelect;
    });
    firestore
      .collection("profile_details")
      .doc(uid)
      .update({ data_type_to_share: appType })
      .then(() => {
        if (shareScore) {
          toggleScoreSharing(false, setShareScore, uid);
        }
      });
  };

  useEffect(() => {
    if (uid) {
      firestore
        .collection("profile_details")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAppSelect(() => {
              const select = appSelectInit;
              select[doc.data()["data_type_to_share"]] = true;
              return select;
            });
            // toggleScoreSharing(false, setShareScore, uid);
            setCheckBoxDisabled(false);
          }
        });
    }
  }, [uid]);

  useEffect(() => {
    if (
      userData &&
      userData.data() != undefined &&
      "data_types" in userData.data()
    ) {
      for (const dataType of APPS) {
        if (Object.keys(userData.data()["data_types"]).includes(dataType)) {
          fetchData(dataType);
        }
      }
      // for (const dataType of APPS) {
      //   if (Object.keys(userData.data()["data_types"]).includes(dataType)) {
      //     setAppSelect(() => {
      //       const select = appSelectInit;
      //       select[dataType] = true;
      //       return select;
      //     });
      //     break;
      //   }
      // }
    }

    if (auth.user === false) {
      router.push("/");
    }
  }, [uid, auth, userData]);

  return (
    <>
      {auth && auth.user && (
        <>
          <TopSectionHeader
            bgColor="#10162f"
            textColor="white"
            size="md"
            title="Accounts Details"
            subtitle=""
          />
          <PeachProfile
            title="Your Peach Account Details"
            size="md"
            email={auth.user.email || ""}
            userData={auth.user}
          />
          <Container>
            <hr></hr>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                checked={shareScore}
                disabled={checkBoxDisabled}
                onChange={handleEnableSharing}
                id="customCheck"
                className="custom-control-input mr-2 p-0 pointer"
              />
              <label className="custom-control-label" htmlFor="customCheck">
                {
                  "Enable stats sharing to show your dating app's summary stats when you participate in Orchard discussions."
                }
              </label>
            </div>
          </Container>
          <Container>
            <Row className="justify-content-between">
              {Object.keys(userAppData).length ? (
                Object.keys(userAppData).map((item, idx) => (
                  <Col lg={5} key={idx}>
                    <AppAccountInfo
                      bgColor={COLORS[item]}
                      textColor="dark"
                      size="md"
                      title={`Your ${item} Summary`}
                      userAppData={userAppData[item]}
                      dataType={item}
                      handleSelectCheckBox={handleSelectCheckBox}
                      selected={appSelect[item]}
                    />
                  </Col>
                ))
              ) : (
                <div className="d-flex justify-content-start my-5">
                  <h3>You have not uploaded any data from your dating app</h3>
                </div>
              )}
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
export default ProfilePage;
