import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { firestore } from "./firebase";
import firebase from "./firebase";

// const updateMetaData = (uid, data) => {
//   const count = data["image_count"];
//   for (let i = 0; i < count; i++) {
//     const storageRef = storage.ref(`${uid}/${data[i].file_name}`);

//     var metadata = {
//       customMetadata: {
//         public: "true",
//       },
//     };

//     storageRef
//       .updateMetadata(metadata)
//       .then(() => {
//         console.log("metadata successfully updated.");
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
// };

export function SubmitMediaForVote(props) {
  const { data, uid, collectionName, docId, mediaType } = props;
  const [tag, setTag] = useState("");
  const [mediaError, setMediaError] = useState(null);
  const [voteSuccess, setVoteSuccess] = useState(null);

  const handleTag = (e) => {
    const val = e.target.value;
    setTag(val);
  };

  const handleClickVote = () => {
    if (docId) {
      firestore
        .collection(collectionName)
        .where("doc_id", "==", docId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            firestore
              .collection(collectionName)
              .add({
                content: data,
                doc_id: docId,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                votes: {},
                tag: tag,
                user_id: uid,
              })
              .then((ref) => {
                firebase
                  .firestore()
                  .doc(`images/${uid}/images/${docId}`)
                  .update({
                    community_doc_id: ref.id,
                  });

                // updateMetaData(uid, data);
              });
          } else {
            setMediaError(true);
          }
          setVoteSuccess(true);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    setMediaError(null);
  };
  return (
    <>
      {!voteSuccess && (
        <div>
          <Form.Control
            style={{ fontSize: "0.75em" }}
            size="lg"
            type="text"
            value={tag}
            as="textarea"
            placeholder="Tag any helpful information here, e.g. 28M27F, New York"
            onChange={(e) => handleTag(e)}
            className="mt-2"
          />{" "}
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="p-2 mt-3 my-1"
              onClick={handleClickVote}
            >
              Get Votes
            </Button>
          </div>
        </div>
      )}
      {voteSuccess && mediaError && (
        <p className="text-primary my-2">
          {`Sorry, your ${mediaType} have already been submitted before.`}
        </p>
      )}
      {voteSuccess && !mediaError && (
        <p>
          {`Success! your ${mediaType} is added to the Orchard Page for community
          votes.`}{" "}
          <a href={`/orchard/${mediaType}s`}>
            {" "}
            {`Click here to view community feedback on your ${mediaType}`}
          </a>
          .
        </p>
      )}
    </>
  );
}
