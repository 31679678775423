import React from "react";
import "./SectionHeader.scss";
import { Link } from "../utils/router";

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.title && (
        <p
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "")
          }
        >
          {props.title}
          {"  "}
          <span className="color-title">{props.coloredTitle}</span>
          {props.icon && (
            <span className="ml-2">
              <img
                width="30"
                alt={props.title}
                className="mb-2"
                src={props.icon}
              />
            </span>
          )}
        </p>
      )}

      {props.subtitle && (
        <h5
          style={{ color: `${props.subtitleColor}` }}
          className={
            `SectionHeader__subtitle mb-0 text-${props.subtitleColor} ` +
            (props.subtitleSize ? ` h${props.subtitleSize}` : "")
          }
        >
          {props.subtitle}
        </h5>
      )}

      {props.help && (
        <Link
          to="/peachInstructions"
          style={{ textDecoration: "none" }}
          className={`SectionHeader__subtitle mb-0 mt-2 text-${props.subtitleColor}`}
        >
          <h7>{props.help}</h7>
        </Link>
      )}
    </header>
  );
}

export default SectionHeader;
