import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import Section from "../components/Section";
import Quote from "../components/Quote";
import OrchardPage from "../components/OrchardPageComponent/OrchardPage";
import DatingImg from "../assets/images/dating.svg";
import AvgMatchRateSection from "../components/OrchardPageComponent/AvgMatchRateSection";
import "./tabs.scss";

function ComingSoon() {
  return (
    <Section size="lg" textColor="dark" style={{ backgroundColor: "#f8f9fa" }}>
      {/* <Container style={{ backgroundColor: "#f1faee"}}> */}
      <div className="d-flex justify-content-center">
        <h2>Coming Soon! Stay Tuned 🙌 </h2>
      </div>
      {/* </Container> */}
    </Section>
  );
}

function Insights() {
  const [key, setKey] = useState("average");

  return (
    <React.Fragment>
      <Section size="md">
        <Container>
          <Quote
            bg="white"
            textColor="dark"
            image={DatingImg}
            title="Orchard Fact of the Day:"
            subtitle="Did you know that men aged 24 and interested in women have a 3% match rate?"
          />
        </Container>
      </Section>
      <Section size="lg">
        <Container>
          <div className="title-header">
            <h2>{"How do I measure up to other users?"}</h2>
          </div>
          <Tabs
            id="orchard"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mt-3"
          >
            <Tab eventKey="average" title="Match rate predictor">
              <AvgMatchRateSection
                bg="light"
                textColor="dark"
                title="How do I measure up to other users?"
              />
            </Tab>
            <Tab eventKey="education" title="Similar education">
              <ComingSoon />
            </Tab>
            <Tab eventKey="location" title="Similar location">
              <ComingSoon />
            </Tab>
            <Tab eventKey="interests" title="Similar interests">
              <ComingSoon />
            </Tab>
          </Tabs>
        </Container>
      </Section>
    </React.Fragment>
  );
}
export const OrchardInsights = OrchardPage(Insights);
