import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CircleProgressBar(props) {
  return (
    <div className="mx-auto mb-2" style={{ width: 80, height: 80 }}>
      <CircularProgressbar
        strokeWidth={4}
        value={props.percentage}
        text={`${props.percentage}%`}
        styles={buildStyles({
          pathColor: `rgba(93, 80, 171)`,
          textColor: "#3A3B3C",
          textSize: "21px",
        })}
      />
    </div>
  );
}
