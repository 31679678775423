export const calcReplyRate = (MessageHistoData) => {
  let repliesRate = 0;
  if (MessageHistoData) {
    const totalFirstMsg = Object.values(
      MessageHistoData.weekday.maybe_first
    ).reduce((acc, val) => acc + val);

    if (totalFirstMsg > 0) {
      const totalSuccFirstMsg = Object.values(
        MessageHistoData.weekday.maybe_succ_first
      ).reduce((acc, val) => acc + val);

      repliesRate = Math.round((totalSuccFirstMsg / totalFirstMsg) * 100);
    }
  }
  return repliesRate;
};

//in highlight page
export const calcAvgMatchRate = (SmoothedUsageDataByDate) => {
  let avgMatchRate = 0;
  if (SmoothedUsageDataByDate.length > 0) {
    avgMatchRate =
      SmoothedUsageDataByDate.reduce((acc, val) => acc + val.matches, 0) /
      SmoothedUsageDataByDate.reduce((acc, val) => acc + val.swipes_likes, 0);
    avgMatchRate = Math.round(avgMatchRate * 100);
  }
  return avgMatchRate;
};

//in profile page
export const calcAvgMatchRateNoDate = (fetch_smoothed_usage_data) => {
  let avgMatchRate = 0;
  const matches = fetch_smoothed_usage_data
    .filter((e) => e.variable === "matches")
    .reduce((acc, val) => acc + val.value, 0);
  const swipesLikes = fetch_smoothed_usage_data
    .filter((e) => e.variable === "swipes_likes")
    .reduce((acc, val) => acc + val.value, 0);
  avgMatchRate = Math.round((matches / swipesLikes) * 100);
  return avgMatchRate;
};

//Fisher-Yates shuffle to generate a random subset from an array without
// replacement
export function getRandomSubarray(arr, size) {
  var shuffled = arr.slice(0), i = arr.length, min = i - size, temp, index;
  while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
  }
  return shuffled.slice(min);
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}