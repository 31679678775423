import React from "react";
import HeroSection from "../components/LandingPageComponent/HeroSection";
import IntroSection from "../components/LandingPageComponent/IntroSection";
import HeroImage from "../assets/images/mobileDating.svg";
import BackgroundImage from "../assets/images/HeroSectionBackgroundColor.png";
import QuoteSection from "../components/LandingPageComponent/QuoteSection";
import MaleHoldingPhone from "../assets/images/MaleHoldingPhone.png";
import OrchardIntro from "../components/LandingPageComponent/OrchardSection";
import UseCasesSection from "../components/LandingPageComponent/UseCasesSection";

function IndexPage() {
  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="lg"
        bgImage={BackgroundImage}
        bgImageOpacity={1}
        title="Peach helps you get more out of your dating apps and find ways to"
        coloredTitle="get more matches and dates"
        subtitle="Currently supporting Tinder and Hinge"
        image={HeroImage}
      />
      <IntroSection
        bg="#10162f"
        textColor="white"
        size="md"
        title="A data-driven dating advice platform"
        subtitle="Make the online dating process more transparent"
        buttonText="Sign up for Peach"
        buttonColor="primary"
        buttonPath="/auth/signup"
      />
      <OrchardIntro
        bg="#f8f4eb"
        textColor="dark"
        size="lg"
        title="Leverage the power of community"
        subtitle=""
        buttonText="Click here to sign up"
        buttonColor="primary"
        buttonPath="/auth/signup"
      />
      <QuoteSection
        bg="#f8f4eb"
        size="md"
        title=""
        bgImage={MaleHoldingPhone}
        bgImageOpacity={0.8}
        descText="I’m tired of spending so much time on the apps and not getting anywhere. I wish I knew when the best time to swipe is or what to say during our conversations"
      />
      <UseCasesSection
        bg="white"
        textColor="dark"
        size="md"
        title="Finally some answers you have been looking for"
      />
      {/* <EmailSubscribeSection
        textColor="dark"
        size="lg"
        buttonText="Subscribe"
        buttonColor="primary"
        bgColor="#f8f2f2"
      /> */}
    </>
  );
}
export default IndexPage;
