import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/_app";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "bootstrap/dist/css/bootstrap.min.css";

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
