import React from "react";
import Section from "./Section";
import SectionHeader from "../components/SectionHeader";

export default function TopSectionHeader(props) {
  return (
    <Section
      style={{ backgroundColor: `${props.bgColor}` }}
      textColor={props.textColor}
      size={props.size}
      className="text-center"
    >
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={1}
        spaced={true}
        icon={props.icon}
        subtitleColor={props.subtitleColor}
      />
    </Section>
  );
}
