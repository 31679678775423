import { useState, useEffect } from "react";
import uuid from "react-uuid";
import { storage, firestore, timestamp } from "./firebase";
import firebase from "./firebase";

const useStorage = (file, uid, index, groupId) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const newName =
      uuid() + // Concat with file extension.
      file.name.substring(file.name.lastIndexOf("."));
    // Instantiate copy of file, giving it new name.
    const fileToLoad = new File([file], newName, { type: file.type });

    // references
    const storageRef = storage.ref(`${uid}/${fileToLoad.name}`);
    const docRef = firestore
      .collection("images")
      .doc(uid)
      .collection("images")
      .doc(groupId);

    storageRef.put(fileToLoad).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        const uploaded_at = timestamp();
        await docRef.set(
          {
            [index]: {
              url,
              uploaded_at,
              file_name: fileToLoad.name,
            },
          },
          { merge: true }
        );
        await docRef.update({
          image_count: firebase.firestore.FieldValue.increment(1),
        });

        setUrl(url);
      }
    );
  }, [file]);

  return { progress, url, error };
};

export default useStorage;
