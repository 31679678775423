import React, { useEffect, useState } from "react";
import Section from "../Section";
import {
  Container,
  Dropdown,
  Button,
  DropdownButton,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import StatsCard from "./StatsCard";
import CardInsight from "./CardInsight";
import DiceIcon from "../../assets/icons/dice.png";
import PageLoader from "../../components/PageLoader";
import { weekdayKeysFull, monthKeysFull } from "../../utils/data/WeekMonth";
import { findLargestKey } from "../../utils/data/sharedSummaryStats";
import { getRandomInt } from "../../utils/data/calculations";
import "./KeyStatsSection.scss";
import HelpfulTip from "../HelpfulTip";

//shows three different categories for key stats(time, content, random(time + content))

export default function KeyStatsSection({
  DailyUsageHistoData,
  MessageTextDetailHistoData,
  MessageHistoData,
  size,
  bg,
  textColor,
  icon,
  title,
}) {
  const [showInsight, setShowInsight] = useState(null);
  const [cardInsightData, setCardInsightData] = useState({});
  const [cardInsightDataForRate, setCardInsightDataForRate] = useState({});
  const [statsCardDataType, setStatsCardDataType] = useState("random");
  const [shuffleState, setShuffle] = useState(false);

  let convertedStatsCardData = [];

  useEffect(() => {
    setShowInsight(false);
  }, [
    DailyUsageHistoData,
    MessageTextDetailHistoData,
    MessageHistoData,
    shuffleState,
  ]);

  //each column has a different color in the stats card gallery
  const bgColors = ["#EBF3F7", "#FBC5CD", "#FFE69B"];

  // keys for "time" data
  const cardKeysForTime = {
    like_rate: {
      cardInsightTitle: "Like rate(%): percentage of profiles that you like",
      statsCardTitle: "You have a higher like rate (likes per 100 swipes) ",
    },
    match_rate: {
      cardInsightTitle:
        "Match rate(%): percentage of profiles you like that also like you back",
      statsCardTitle: "You have a higher match rate (matches per 100 likes) ",
    },
    matches: {
      cardInsightTitle: "Matches",
      statsCardTitle: "You have more matches ",
    },
    messages_received: {
      cardInsightTitle: "Replies",
      statsCardTitle: "You got more replies ",
    },
    messages_sent: {
      cardInsightTitle: "Messages sent",
      statsCardTitle: "You sent the most messages ",
    },
    swipes: {
      cardInsightTitle: "Swipes",
      statsCardTitle: "You have swiped more ",
    },
    swipes_likes: {
      cardInsightTitle: "Likes",
      statsCardTitle: "You liked more profiles  ",
    },
    swipes_passes: {
      cardInsightTitle: "Passes: number of profiles that you passed on",
      statsCardTitle: "You passed on more profiles ",
    },
    maybe_arrange_dates: {
      cardInsightTitle: "Messages that tried to set up a date",
      statsCardTitle: "You are more likely to send messages to arrange dates ",
    },
    maybe_succ_first: {
      cardInsightTitle: "Your first messages that got replies",
      statsCardTitle: "You are more successful with first messages ",
    },
    maybe_succ_middle: {
      cardInsightTitle: "Your messages that extended an existing conversation",
      statsCardTitle: "You are more successful with continuing messages ",
    },
    maybe_succ_to_dates: {
      cardInsightTitle: "Messages that lead to a date",
      statsCardTitle:
        "You are more likely to send messages that eventually lead to a date ",
    },
  };

  //use to show option for rate if it contains a similar key in cardKeys
  const cardKeysForRate = {
    maybe_arrange_dates_rate: {
      cardInsightTitle:
        "Your messages that try to arrange dates per 100 messages sent (%)",
      statsCardTitle:
        "You are more likely to send messages to arrange dates (normalized) ",
    },
    maybe_succ_first_rate: {
      cardInsightTitle:
        "Your first messages that got replies per 100 first messages sent (%)",
      statsCardTitle:
        "You are more successful with first messages (normalized) ",
    },
    maybe_succ_middle_rate: {
      cardInsightTitle:
        "Messages that got replies per 100 messages sent to continue a conversation (%)",
      statsCardTitle:
        "You are more successful with continuing messages (normalized) ",
    },
    maybe_succ_to_dates_rate: {
      cardInsightTitle:
        "Messages that eventually led to a date per 100 messages sent (%)",
      statsCardTitle:
        "You are more likely to send date-leading messages (normalized) ",
    },
  };

  const monthKeys = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekdayKeys = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const hourKeys = [
    "12AM",
    "1AM",
    "2AM",
    "3AM",
    "4AM",
    "5AM",
    "6AM",
    "7AM",
    "8AM",
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
    "10PM",
    "11PM",
  ];

  //convert Time Data to graph structure
  const convertTimeDataToGraph = (statCardResults) => {
    if (!statCardResults && statCardResults.length === 0) return;
    let graphData = [];
    const AllCardKeysWithRate = Object.assign(
      {},
      cardKeysForTime,
      cardKeysForRate
    );
    for (let cardKey in AllCardKeysWithRate) {
      // define the card struct first

      let cardInfo = {
        type: cardKey,
        generalType: "time",
        barChartLabel: "",
        cardInsightTitle: AllCardKeysWithRate[cardKey].cardInsightTitle,
        statsCardTitle: AllCardKeysWithRate[cardKey].statsCardTitle,
        highestMonthValue: 0,
        highestMonthResult: "",
        highestWeekValue: 0,
        highestWeekResult: "",
        highestHourValue: 0,
        highestHourResult: "",
        month: [],
        week: [],
        hour: [],
        resultStr: "",
        resultPeriod: "",
      };

      const capFL = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const periods = ["month", "week", "hour"];
      for (const period of periods) {
        let periodKeys;
        let dataKey;
        switch (period) {
          case "hour":
            periodKeys = hourKeys;
            dataKey = "hour";
            break;
          case "week":
            periodKeys = weekdayKeys;
            dataKey = "weekday";
            break;
          case "month":
            periodKeys = monthKeys;
            dataKey = "month";
            break;
        }

        if (statCardResults[dataKey] && statCardResults[dataKey][cardKey]) {
          const highestKey = findLargestKey(statCardResults[dataKey][cardKey]);
          cardInfo[`highest${capFL(period)}Result`] = highestKey;
          cardInfo[`highest${capFL(period)}Value`] =
            statCardResults[dataKey][cardKey][highestKey];
          periodKeys.forEach((k) => {
            cardInfo[period].push({
              name: k,
              [cardKey]: statCardResults[dataKey][cardKey][k]
                ? statCardResults[dataKey][cardKey][k]
                : 0,
            });
          });
        }
      }

      const MonthSubstring =
        cardInfo.highestMonthResult &&
        monthKeysFull[cardInfo.highestMonthResult].name;
      const WeekSubstring =
        cardInfo.highestWeekResult &&
        weekdayKeysFull[cardInfo.highestWeekResult].name + "s";
      const HourSubstring = cardInfo.highestHourResult;

      const highestValues = [
        cardInfo.highestMonthValue,
        cardInfo.highestWeekValue,
        cardInfo.highestHourValue,
      ];

      const results = [MonthSubstring, WeekSubstring, HourSubstring];
      const preps = [" in ", " on ", " at "];
      const idxChoices = results
        .filter((_v, idx) => highestValues[idx] > 0)
        .map((v) => results.indexOf(v));
      const chosenIdx = idxChoices.length
        ? idxChoices[getRandomInt(idxChoices.length)]
        : getRandomInt(results.length);

      cardInfo.resultStr = results[chosenIdx];
      cardInfo.statsCardTitle += preps[chosenIdx];
      cardInfo.resultPeriod = periods[chosenIdx];

      graphData.push(cardInfo);
    }
    return graphData;
  };
  let combinedData = { month: {}, weekday: {}, hour: {} };

  // helper function to combine each of the dicts into a final dict
  let combineMonthWeekdayHour = (outputDict, historyData) => {
    // iterate through month weekday hour keys
    for (let durationKey of Object.keys(historyData)) {
      let durationStruct = historyData[durationKey];

      // get historyData duration struct, creating new key if it exists
      let outputDurationStruct = outputDict[durationKey];
      if (!outputDurationStruct) outputDurationStruct = {};

      // iterate through inner struct, there will not be duplicate keys of 'like_rate', etc
      for (let categoryKey of Object.keys(durationStruct)) {
        let categoryStruct = durationStruct[categoryKey];

        outputDurationStruct[categoryKey] = categoryStruct;
      }
    }
  };

  // combine DailyUsageHistoData and MessageHistoryData iff the data is ready
  if (DailyUsageHistoData && MessageHistoData) {
    combineMonthWeekdayHour(combinedData, DailyUsageHistoData);
    combineMonthWeekdayHour(combinedData, MessageHistoData);
  }
  const convertedStatsCardDataForTime =
    Object.keys(combinedData).length > 0 &&
    convertTimeDataToGraph(combinedData);

  //all keys for Content Type Data
  const cardKeysForContent = {
    emoji_cnt: { cardInsightTitle: "Emoji Count" },
    exclamation_mark_cnt: { cardInsightTitle: "Exclamation Mark Count" },
    msg_interval: { cardInsightTitle: "Message Interval" },
    question_mark_cnt: { cardInsightTitle: "Question Mark Count" },
    word_cnt: { cardInsightTitle: "Word Count" },
  };

  //convert Content Data to Graph
  const convertContentDataToGraph = (data) => {
    let convertedData = [];
    if (!data && !data["first messages"] && !data["continuing_messages"]) {
      return;
    } else {
      for (let cardKey in cardKeysForContent) {
        let cardInfo = {
          type: cardKey,
          generalType: "content",
          cardInsightTitle: cardKeysForContent[cardKey].cardInsightTitle,
          statsCardTitle: "",
          highestFirstResult: "",
          highestContResult: "",
          highestFirstValue: 0,
          highestContValue: 0,
          first_msgs: [],
          cont_msgs: [],
          resultStr: "",
        };

        cardInfo.first_msgs = [
          {
            name: "successful",
            [cardKey]: data["first messages"].successful[cardKey],
          },
          {
            name: "unsuccessful",
            [cardKey]: data["first messages"].unsuccessful[cardKey],
          },
        ];

        cardInfo.highestFirstResult =
          data["first messages"].successful[cardKey] >
          data["first messages"].unsuccessful[cardKey]
            ? "successful"
            : "unsuccessful";
        cardInfo.highestFirstValue =
          data["first messages"].successful[cardKey] >
          data["first messages"].unsuccessful[cardKey]
            ? data["first messages"].successful[cardKey]
            : data["first messages"].unsuccessful[cardKey];
        cardInfo.cont_msgs = [
          {
            name: "successful",
            [cardKey]: data["continuing messages"].successful[cardKey],
          },
          {
            name: "unsuccessful",
            [cardKey]: data["continuing messages"].unsuccessful[cardKey],
          },
        ];

        cardInfo.highestContResult =
          data["continuing messages"].successful[cardKey] >
          data["continuing messages"].unsuccessful[cardKey]
            ? "successful"
            : "unsuccessful";

        cardInfo.highestContValue =
          data["continuing messages"].successful[cardKey] >
          data["continuing messages"].unsuccessful[cardKey]
            ? data["continuing messages"].successful[cardKey]
            : data["continuing messages"].unsuccessful[cardKey];

        //create title for stats card
        let compareFirstOrContValue =
          cardInfo.highestFirstValue > cardInfo.highestContValue;

        if (
          cardKey === "emoji_cnt" ||
          cardKey === "exclamation_mark_cnt" ||
          cardKey === "question_mark_cnt" ||
          cardKey === "word_cnt"
        ) {
          let label = cardKey.replaceAll("cnt", "count").replaceAll("_", " ");

          if (cardInfo.highestFirstValue > 0 && cardInfo.highestContValue > 0) {
            cardInfo.statsCardTitle = `Your total ${label} is higher in `;
            cardInfo.resultStr = `${
              compareFirstOrContValue
                ? cardInfo.highestFirstResult
                : cardInfo.highestContResult
            } ${compareFirstOrContValue ? "first" : "continuing"} messages`;
          } else if (
            cardInfo.highestFirstValue > 0 ||
            cardInfo.highestContValue > 0
          ) {
            cardInfo.statsCardTitle = `Your total ${label} is higher in `;
            cardInfo.resultStr = `${
              cardInfo.highestFirstResult || cardInfo.highestContResult
            } ${
              cardInfo.highestFirstValue > 0 ? "first" : "continuing"
            } messages`;
          }
        } else if (cardKey === "msg_interval") {
          if (cardInfo.highestFirstValue > 0 && cardInfo.highestContValue > 0) {
            cardInfo.statsCardTitle = `Your interval between messages is higher in `;
            cardInfo.resultStr = `${
              compareFirstOrContValue
                ? cardInfo.highestFirstResult
                : cardInfo.highestContResult
            } ${compareFirstOrContValue ? "first" : "continuing"} messages`;
          } else if (
            cardInfo.highestFirstValue > 0 ||
            cardInfo.highestContValue > 0
          ) {
            cardInfo.statsCardTitle = `Your interval between messages is higher in `;
            cardInfo.resultStr = `${
              cardInfo.highestFirstResult || cardInfo.highestContResult
            } ${
              cardInfo.highestFirstValue > 0 ? "first" : "continuing"
            }  messages`;
          }
        }

        convertedData.push(cardInfo);
      }
    }
    return convertedData;
  };

  const convertedStatsCardDataForContent =
    MessageTextDetailHistoData &&
    Object.keys(MessageTextDetailHistoData).length > 0 &&
    convertContentDataToGraph(MessageTextDetailHistoData);

  //create random set of cards from both "content" and "time" data
  const shuffle = (array) => {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  //switch stats card general data type from dropdown
  const handleStatsCardDataType = (eventKey) => {
    setStatsCardDataType(eventKey);
  };

  //switch data when selecting a different general type from the dropdown
  if (statsCardDataType === "time") {
    convertedStatsCardData = convertedStatsCardDataForTime;
  } else if (statsCardDataType === "content") {
    convertedStatsCardData = convertedStatsCardDataForContent;
  } else if (statsCardDataType === "random") {
    if (
      convertedStatsCardDataForTime.length === 0 &&
      convertedStatsCardDataForContent.length === 0
    ) {
      return;
    }
    if (
      convertedStatsCardDataForTime &&
      convertedStatsCardDataForTime.length > 0 &&
      convertedStatsCardDataForContent &&
      convertedStatsCardDataForContent.length > 0
    ) {
      let timeData = shuffle([...convertedStatsCardDataForTime]).slice(0, 7);
      let contentData = shuffle([...convertedStatsCardDataForContent]).slice(
        0,
        4
      );
      convertedStatsCardData = [...timeData, ...contentData];
    } else if (
      convertedStatsCardDataForTime.length > 0 ||
      convertedStatsCardDataForContent.length > 0
    ) {
      let availData =
        convertedStatsCardDataForTime.length > 0
          ? convertedStatsCardDataForTime
          : convertedStatsCardDataForContent;
      convertedStatsCardData = shuffle([...availData]);
    }
  }

  //handle which insight to show
  const handleShowInsight = (index) => {
    setShowInsight(true);
    const filteredCardResult =
      convertedStatsCardData.length > 0 && convertedStatsCardData[index];
    setCardInsightData(filteredCardResult);

    const type = filteredCardResult && filteredCardResult.type;
    const filteredCardResultWithRate =
      convertedStatsCardData.length > 0 &&
      convertedStatsCardData.filter((e) => e.type === `${type}_rate`)[0];
    setCardInsightDataForRate(filteredCardResultWithRate);
  };

  return (
    <Section size={size} bg={bg} textColor={textColor}>
      <a name="peach-stats" className="anchor">
        {/* anchor text [invisible] */}
      </a>
      <Container>
        <div className="title-header">
          <div className="d-flex align-items-start">
            <img width="35" src={icon} alt={title} className="mr-3 mb-2 mt-1" />
            <h2>{title}</h2>
          </div>

          <hr></hr>
        </div>
        {!showInsight && (
          <div className="d-flex align-items-center justify-content-between my-4">
            <div className="d-flex align-items-center">
              <DropdownButton
                id="dropdown-basic-button"
                title={statsCardDataType}
                className="time-series-btn bg-transparent text-dark font-weight-bold p-0 ml-3 mr-2"
                onSelect={handleStatsCardDataType}
              >
                <Dropdown.Item eventKey="random">Random</Dropdown.Item>
                <Dropdown.Item eventKey="time">Time</Dropdown.Item>
                <Dropdown.Item eventKey="content">Content</Dropdown.Item>
              </DropdownButton>{" "}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className="mytooltip">
                    <h6 className="m-0 p-1">shuffle to see different stats</h6>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <Button
                    {...triggerHandler}
                    onClick={() => setShuffle(!shuffleState)}
                    className="p-1 border-0 bg-transparent d-inline-flex"
                  >
                    <Image ref={ref} src={DiceIcon} width="30" />
                  </Button>
                )}
              </OverlayTrigger>
            </div>
            <HelpfulTip
              iconWidth="30"
              tipSubtitle="Random collection shows both time and content cards. Time series cards show monthly, weekly and hourly usage while content cards show more quantitative data of your successful and unsuccessful messages."
            />
          </div>
        )}

        <div>
          {!showInsight ? (
            <div className="stats-card-gallery">
              {convertedStatsCardData && convertedStatsCardData.length > 0 ? (
                convertedStatsCardData.map(
                  (cardItem, index) =>
                    (cardItem.highestMonthValue > 0 ||
                      cardItem.highestWeekValue > 0 ||
                      cardItem.highestHourValue > 0 ||
                      cardItem.highestFirstValue > 0 ||
                      cardItem.highestContValue > 0) &&
                    (cardKeysForTime[cardItem.type] ||
                      cardKeysForContent[cardItem.type]) && (
                      <StatsCard
                        key={cardItem.type}
                        bgColor={bgColors[index % bgColors.length]}
                        cardData={cardItem}
                        cardIndex={index}
                        handleShowInsight={handleShowInsight}
                        setShowInsight={setShowInsight}
                      />
                    )
                )
              ) : (
                <PageLoader />
              )}
            </div>
          ) : (
            <div className="stat-data">
              {Object.keys(cardInsightData).length > 0 && (
                <CardInsight
                  cardData={cardInsightData}
                  cardInsightDataForRate={cardInsightDataForRate}
                  setShowInsight={setShowInsight}
                />
              )}
            </div>
          )}
        </div>
        {/* <HelpfulFeature /> */}
      </Container>
    </Section>
  );
}
