import React, { createContext, useEffect, useState } from "react";
import uuid from "react-uuid";

const removedAvatarUrl = `https://ui-avatars.com/api/name=r&color=fff`;

export const ActionContext = createContext();
export const ActionProvider = ({
  children,
  currentUser,
  setComment,
  comments,
  customInput,
  dispatch,
  profiles,
}) => {
  const [replies, setReplies] = useState([]);
  const [user, setUser] = useState();
  const [editArr, setEdit] = useState([]);

  useEffect(() => {
    if (currentUser) {
      setUser(true);
    } else {
      setUser(false);
    }
  });

  const handleAction = (id, edit) => {
    edit ? setEdit([...editArr, id]) : setReplies([...replies, id]);
  };
  const handleCancel = (id, edit) => {
    if (edit) {
      const list = [...editArr];
      const newList = list.filter((i) => i !== id);
      setEdit(newList);
    } else if (!edit) {
      const list = [...replies];
      const newList = list.filter((i) => i !== id);
      setReplies(newList);
    }
  };

  const onSubmit = (text, parentId, child) => {
    if (text.length > 0) {
      if (!parentId && !child) {
        const comId = uuid();
        const payload = {
          userId: currentUser.userId,
          comId: comId,
          avatarUrl: currentUser.avatarUrl,
          fullName: currentUser.name,
          text: text,
          timestamp: Date.now(),
        };
        setComment([...comments, payload]);
        dispatch({ type: "new", path: `/${comId}`, payload: payload });
      } else if (parentId && child) {
        const comId = uuid();
        const newList = [...comments];
        const index = newList.findIndex((x) => x.comId === parentId);
        const payload = {
          userId: currentUser.userId,
          comId: comId,
          avatarUrl: currentUser.avatarUrl,
          fullName: currentUser.name,
          text: text,
          timestamp: Date.now(),
        };
        newList[index].replies.push(payload);
        setComment(newList);
        dispatch({
          type: "new",
          path: `/${parentId}/${comId}`,
          payload: payload,
        });
      } else if (parentId && !child) {
        const comId = uuid();
        const payload = {
          userId: currentUser.userId,
          comId: comId,
          avatarUrl: currentUser.avatarUrl,
          fullName: currentUser.name,
          text: text,
          timestamp: Date.now(),
        };
        const newList = [...comments];
        const index = newList.findIndex((x) => x.comId === parentId);
        const newReplies =
          newList[index].replies === undefined
            ? []
            : [...newList[index].replies];
        newReplies.push(payload);
        newList[index].replies = newReplies;
        setComment(newList);
        dispatch({
          type: "new",
          path: `/${parentId}/${comId}`,
          payload: payload,
        });
      }
    }
  };

  const editText = (id, text, parentId) => {
    if (parentId === undefined) {
      const newList = [...comments];
      const index = newList.findIndex((x) => x.comId === id);
      const comId = newList[index].comId;
      newList[index].text = text;
      setComment(newList);
      dispatch({
        type: "edit",
        path: `/${comId}`,
        payload: { text: text },
      });
    } else if (parentId !== undefined) {
      const newList = [...comments];
      const index = newList.findIndex((x) => x.comId === parentId);
      const replyIndex = newList[index].replies.findIndex(
        (i) => i.comId === id
      );
      newList[index].replies[replyIndex].text = text;
      const payload = newList[index].replies[replyIndex];
      const comId = payload.comId;
      setComment(newList);
      dispatch({
        type: "edit",
        path: `/${parentId}/${comId}`,
        payload: payload,
      });
    }
  };

  const deleteText = (id, parentId) => {
    if (parentId === undefined) {
      const newList = [...comments];
      const index = newList.findIndex((x) => x.comId === id);
      newList[index].text = "deleted";
      newList[index].userId = "removed";
      newList[index].avatarUrl = removedAvatarUrl;
      setComment(newList);
      dispatch({
        type: "delete",
        path: `/${id}`,
        payload: {
          text: "deleted",
          userId: "removed",
          fullName: "removed",
          avatarUrl: removedAvatarUrl,
        },
      });
    } else if (parentId !== undefined) {
      const newList = [...comments];
      const index = newList.findIndex((x) => x.comId === parentId);
      const filter = newList[index].replies.filter((x) => x.comId !== id);
      newList[index].replies = filter;
      setComment(newList);
      dispatch({
        type: "delete child",
        path: `/${parentId}/${id}`,
      });
    }
  };

  const onVote = (id, parentId, vote) => {
    if (parentId === undefined) {
      dispatch({
        type: "vote",
        path: `/${id}`,
        vote: vote,
      });
    } else {
      dispatch({
        type: "vote",
        path: `/${parentId}/${id}`,
        vote: vote,
      });
    }
  };

  // const onVote = (id, parentId, vote) => {
  //   if (parentId === undefined) {
  //     const newList = [...comments];
  //     const index = newList.findIndex((x) => x.comId === id);
  //     if (newList[index].votes == null) {
  //       newList[index].votes = {};
  //       newList[index].totalUpvotes = 0;
  //     }
  //     newList[index].votes[currentUser.userId] = vote;
  //     const totalUpvotes = Object.values(newList[index].votes).reduce(
  //       (a, b) => a + b,
  //       0
  //     );
  //     newList[index].totalUpvotes = totalUpvotes;
  //     setComment(newList);
  //     dispatch({
  //       type: "vote",
  //       path: `/${id}/votes`,
  //       payload: {
  //         [currentUser.userId]: vote,
  //       },
  //       path2: `/${id}`,
  //       payload2: {
  //         totalUpvotes: totalUpvotes,
  //       },
  //     });
  //   } else if (parentId !== undefined) {
  //     const newList = [...comments];
  //     const index = newList.findIndex((x) => x.comId === parentId);
  //     const replyIndex = newList[index].replies.findIndex(
  //       (x) => x.comId === id
  //     );
  //     if (newList[index].replies[replyIndex].votes == null) {
  //       newList[index].replies[replyIndex].votes = {};
  //     }
  //     newList[index].replies[replyIndex].votes[currentUser.userId] = vote;
  //     const totalUpvotes = Object.values(
  //       newList[index].replies[replyIndex].votes
  //     ).reduce((a, b) => a + b, 0);
  //     newList[index].replies[replyIndex].totalUpvotes = totalUpvotes;
  //     setComment(newList);
  //     dispatch({
  //       type: "vote",
  //       path: `/${parentId}/${id}/votes`,
  //       payload: {
  //         [currentUser.userId]: vote,
  //       },
  //       path2: `/${parentId}/${id}`,
  //       payload2: {
  //         totalUpvotes: totalUpvotes,
  //       },
  //     });
  //   }
  // };

  const submit = (cancellor, text, parentId, edit, setText, child) => {
    if (edit) {
      editText(cancellor, text, parentId);
      handleCancel(cancellor, edit);
      setText("");
    } else {
      onSubmit(text, parentId, child);
      handleCancel(cancellor);
      setText("");
    }
  };

  return (
    <ActionContext.Provider
      value={{
        onSubmit: onSubmit,
        userImg: currentUser && currentUser.avatarUrl,
        userId: currentUser && currentUser.userId,
        handleAction: handleAction,
        handleCancel: handleCancel,
        replies: replies,
        setReplies: setReplies,
        editArr: editArr,
        onEdit: editText,
        onDelete: deleteText,
        onVote: onVote,
        user: user,
        customInput: customInput,
        profiles: profiles,
        submit: submit,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};
