import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import FriendsImg from "../../assets/images/friends.jpg";

function OrchardIntro(props) {
  return (
    <Section
      textColor={props.textColor}
      style={{ backgroundColor: `${props.bg}` }}
    >
      <Container className="d-flex justify-content-center text-center mb-n5">
        {/* <div
          // className="rounded-corners p-5"
          // style={{ backgroundColor: "#F4EAE6" }}
        > */}
        <Row>
          <Col lg={3}></Col>
          <Col lg={6}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={false}
              t
            />
            <div className="px-5 text-center">
              <p>
                Knowing how other users similar to you are doing can help you
                make more sense of your own results and find ways to improve
              </p>
              <p>
                Take advantage of an ecosystem of helpful minds. Or get
                personalized and objective advice from mentors based on your
                data and machine learning-driven recommendations
              </p>
              <img src={FriendsImg} alt="friends" width="350" opacity="0.8" />
            </div>
            <Link to={props.buttonPath}>
              <Button
                renderas="button"
                variant={props.buttonColor}
                size="md"
                className="mt-5"
              >
                {props.buttonText}
              </Button>
            </Link>
          </Col>
          <Col lg={3}></Col>
        </Row>
        {/* </div> */}
      </Container>
    </Section>
  );
}
export default OrchardIntro;
