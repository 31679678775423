import React from "react";
import "./ScoreBar.scss";

export default function ScoreBar({ goodPercent, badPercent }) {
  return (
    <div className="w-100">
      {goodPercent || badPercent ? (
        <div className="score-bar d-flex mt-1 mb-3">
          {goodPercent > 0 && (
            <div className="score good" style={{ width: `${goodPercent}%` }}>
              <div className="py-3">
                <p className="m-0 p-0 font-weight-bold">Good 😊</p>
                <p className="m-0 p-0 font-weight-bold">{goodPercent}%</p>
              </div>
            </div>
          )}

          {badPercent > 0 && (
            <div className="score bad" style={{ width: `${badPercent}%` }}>
              <div className="py-3">
                <p className="m-0 p-0 font-weight-bold">Bad 🤔</p>
                <p className="m-0 p-0 font-weight-bold">{badPercent}%</p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="score-bar my-3 d-flex my-3">No votes yet</div>
      )}
    </div>
  );
}
