import React, { useState, useEffect } from "react";
import editIcon from "../../assets/icons/edit.svg";
import {
  Button,
  ListGroup,
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useObjectVal } from "react-firebase-hooks/database";
import { useAuth } from "../../utils/auth.js";
import SearchIcon from "../../assets/icons/search.svg";
import MessageIcon from "../../assets/icons/message.png";
import { firestore } from "../../utils/firebase";
import firebase from "../../utils/firebase";
import Section from "../Section";
import CommunityVotingImg from "../../assets/images/MessageVotingPlaceholder2.jpg";
import MessageBubble from "../../components/MessageBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

function MustBeFirstMsgModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Oops...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Currently you can only select your opening messages for Orchard
          community votes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const addDBRefToMsg = (refs, msgs) => {
  for (let ii = 0; ii < refs.length; ii++) {
    msgs[ii]["ref"] = refs[ii];
  }
};

const MessageSnaps = (props) => {
  const { uid, setMessageCB, dataType } = props;
  const [expectedBatchCount, ebcLoading] = useObjectVal(
    firebase.database().ref(`messages/${uid}/${dataType}/expected_batch_count`)
  );
  const [completedBatchCount, cbcloading] = useObjectVal(
    firebase.database().ref(`messages/${uid}/${dataType}/completed_batch_count`)
  );

  useEffect(() => {
    if (
      !ebcLoading &&
      !cbcloading &&
      expectedBatchCount == completedBatchCount
    ) {
      console.log(
        `Expected ${expectedBatchCount} batches of message data; received ${completedBatchCount} batches of message data.`
      );
      const dbRef = firebase.database().ref(`messages/${uid}/${dataType}/data`);
      dbRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            const messages = Object.values(snapshot.val());
            const msgRefs = Object.keys(snapshot.val());
            addDBRefToMsg(msgRefs, messages);
            setMessageCB(messages);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setMessageCB([]);
    }
  }, [expectedBatchCount, completedBatchCount]);

  return null;
};

export default function MessageVotingSection({
  title,
  size,
  textColor,
  dataType,
  BA,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [tag, setTag] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [selectedMessages, setSelectedMessage] = useState([]);
  const [selectedMsgId, setSelectedMsgId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  let [messages, setMessages] = useState([]);
  const [voteSuccess, setVoteSuccess] = useState(null);
  const [msgError, setMsgError] = useState(null);
  const [demoError, setDemoError] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [dateArrangedCheck, setDateArranged] = useState(null);
  const [dateHappenedCheck, setDateHappened] = useState(null);
  const [showEditSuccessMsg, setShowEditSuccessMsg] = useState(false);
  const [editMsgLoading, setEditMsgLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const auth = useAuth();
  const getToken = () => {
    if (auth.user) {
      auth.user.getIdToken(true).then((token) => {
        token && setIdToken(token);
      });
    }
  };

  useEffect(() => {
    getToken();
  }, [auth.user]);

  const uid = BA.uid;

  const messages_disp = messages.slice(0, Math.min(100, messages.length));

  const handleInputChange = (e) => {
    let val = e.target.value;
    setSearchInput(val);
    if (val.length > 0) {
      let filtered = messages.filter((e) =>
        e.message?.toString().toLowerCase().includes(val.toLowerCase())
      );
      filtered.length > 0 && setSearchResults(filtered);
    }
  };

  const handleTag = (e) => {
    const val = e.target.value;
    setTag(val);
  };

  const handleClickVote = () => {
    for (const m of selectedMessages) {
      if (!m.maybe_first) {
        setVoteSuccess(false);
        setModalShow(true);
        return;
      }
    }
    const selectedMessagesArr = selectedMessages
      .map(
        (msg) =>
          (msg = {
            message: msg.message,
            timestamp: msg.timestamp,
            id: msg.message_id,
            ref: msg.ref,
          })
      )
      .sort();
    if (selectedMessages) {
      firestore
        .collection("community_messages")
        .where("messages_list", "==", selectedMessagesArr)
        .get()
        .then((querySnapshot) => {
          if (BA.demoMode) {
            setDemoError(true);
          } else {
            if (querySnapshot.docs.length === 0) {
              firestore.collection("community_messages").add({
                messages_list: selectedMessagesArr,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                votes: {},
                tag: tag,
                app_source: dataType,
                user_id: uid,
              });
            } else {
              setMsgError(true);
            }
          }
          setVoteSuccess(true);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    setMsgError(null);
  };

  const handleEditMessage = async (message_id) => {
    setEditMsgLoading(true);
    const res = await BA.editMessageData(
      parseInt(message_id),
      dateArrangedCheck === true ? 1 : 0,
      dateHappenedCheck === true ? 1 : 0,
      idToken
    );
    if (res) {
      setShowEdit(false);
      setDateArranged(null);
      setDateHappened(null);
      setShowEditSuccessMsg(true);
      setTimeout(() => {
        setShowEditSuccessMsg(false);
      }, 5000);
      setEditMsgLoading(false);
    }
  };

  const handleSelectedMsg = (item) => {
    //allow user to select up to three messages they want to add to voting
    if (selectedMessages.length === 3) {
      selectedMessages.shift();
    }

    if (selectedMessages.length <= 3) {
      //check if the same message exists. If selected same message text, then replace the message with the last selected message
      for (let idx in selectedMessages) {
        if (selectedMessages.includes(item)) {
          return;
        }
        if (selectedMessages[idx].message.includes(item.message)) {
          selectedMessages.splice(idx, 1);
        }
      }
      const messages =
        selectedMessages.length > 0 ? [...selectedMessages, item] : [item];
      messages.sort((a, b) => a.timestamp - b.timestamp);
      setSelectedMessage(messages);
      setShowEdit(false);
      setVoteSuccess(null);
    }
  };

  const handleShowEditModal = (id) => {
    setShowEdit(!showEdit);
    setSelectedMsgId(id);
  };

  const handleDelete = (msgId) => {
    const filtered = selectedMessages.filter((e) => e.message_id !== msgId);
    setSelectedMessage(filtered);
  };

  return (
    <>
      <MustBeFirstMsgModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Section id="peach-community-voting" size={size} textColor={textColor}>
        <MessageSnaps
          uid={uid}
          setMessageCB={setMessages}
          dataType={dataType}
        />
        <Container>
          {auth && auth.user ? (
            <>
              <div className="title-header">
                <div className="d-flex align-items-center">
                  <img
                    width="40"
                    src={MessageIcon}
                    alt={title}
                    className="mr-2 mb-2"
                  />
                  <h2>{title}</h2>
                </div>
                <hr></hr>
              </div>
              <Row>
                <Col lg={6} xs={12} className="search-messages">
                  <h5>Search your messages</h5>
                  <div className="d-flex">
                    {" "}
                    <img className="mr-3" src={SearchIcon} width="30" />
                    <Form.Control
                      size="lg"
                      type="text"
                      value={searchInput}
                      placeholder="Enter message"
                      onChange={(e) => handleInputChange(e)}
                      className="mt-2"
                    />
                  </div>
                  <div className="mt-5">
                    <h5>Your search results:</h5>
                    <div className="d-flex flex-row align-items-baseline">
                      <p className="btn-legend text-center p-2 px-4 rounded mt-1 mb-4">
                        your opening messages
                      </p>
                      <div
                        className="ml-2 p-2 rounded"
                        style={{ border: "1px solid" }}
                      >
                        <FontAwesomeIcon
                          icon={faFlag}
                          size="1x"
                          className="voted-alert mr-1"
                        />
                        <small>: received votes</small>
                      </div>
                    </div>
                    <div
                      style={{ height: "500px", overflow: "scroll" }}
                      className="border"
                    >
                      <ListGroup>
                        {searchInput.length > 0
                          ? searchResults.length > 0 &&
                            searchResults.map((item, index) => (
                              <ListGroup.Item
                                variant={item.maybe_first ? "warning" : ""}
                                action
                                key={`${item.message}-${index}`}
                                onClick={() => handleSelectedMsg(item)}
                              >
                                {(item.upvotes_received ||
                                  item.downvotes_received) && (
                                  <FontAwesomeIcon
                                    icon={faFlag}
                                    size="1x"
                                    className="voted-alert"
                                  />
                                )}
                                <p> {item.message}</p>
                                <small>
                                  {new Date(item.timestamp * 1000).toString()}
                                </small>
                              </ListGroup.Item>
                            ))
                          : messages_disp.length > 0 &&
                            messages_disp.map((item, index) => (
                              <ListGroup.Item
                                variant={item.maybe_first ? "warning" : ""}
                                action
                                key={`${item.message}-${index}`}
                                onClick={() => handleSelectedMsg(item)}
                              >
                                {(item.upvotes_received ||
                                  item.downvotes_received) && (
                                  <FontAwesomeIcon
                                    icon={faFlag}
                                    size="1x"
                                    className="voted-alert"
                                  />
                                )}
                                <p> {item.message}</p>
                                <small>
                                  {new Date(item.timestamp * 1000).toString()}
                                </small>
                              </ListGroup.Item>
                            ))}

                        {messages_disp.length === 0 && (
                          <p className="m-2">{`Retrieving your ${dataType} messages...`}</p>
                        )}
                      </ListGroup>
                    </div>
                  </div>
                </Col>
                <Col>
                  {selectedMessages &&
                    Object.keys(selectedMessages).length > 0 && (
                      <>
                        <h6>
                          Get votes for your message. You can select up to three
                          messages.
                        </h6>
                        <div className="bg-transparent p-4 my-4 rounded align-items-center">
                          <div>
                            {selectedMessages.map((e, index) => (
                              <div
                                key={e.message_id}
                                className="d-flex justify-content-end"
                              >
                                <div className="mb-3 pr-2">
                                  <div className="position-relative">
                                    <MessageBubble
                                      lastMsg={
                                        index === selectedMessages.length - 1
                                      }
                                      message={e.message}
                                      msgId={e.message_id}
                                      handleDelete={handleDelete}
                                      showDelete={true}
                                      appSource={dataType}
                                    />
                                  </div>
                                  <small className="text-right">
                                    {new Date(e.timestamp * 1000).toString()}
                                  </small>
                                </div>
                                <img
                                  src={editIcon}
                                  width="25"
                                  className="pointer mb-5"
                                  onClick={() =>
                                    handleShowEditModal(e.message_id)
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </div>

                        {!voteSuccess && (
                          <div>
                            <Form.Control
                              style={{ fontSize: "0.75em" }}
                              size="lg"
                              type="text"
                              value={tag}
                              as="textarea"
                              placeholder="Tag any helpful information here, e.g. 28M27F, New York"
                              onChange={(e) => handleTag(e)}
                              className="mt-2"
                            />{" "}
                            <Button
                              size="sm"
                              className="p-2 mt-3 my-1"
                              onClick={handleClickVote}
                            >
                              Get Votes
                            </Button>
                          </div>
                        )}
                        {voteSuccess && demoError && (
                          <p className="text-primary my-2">
                            Sorry, you cannot submit messages for communty
                            voting in demo mode.{" "}
                            <a href="/orchard/#community-vote">
                              {" "}
                              Click here to view messages submitted by other
                              users.
                            </a>
                          </p>
                        )}
                        {voteSuccess && msgError && (
                          <p className="text-primary my-2">
                            Sorry, your messages have already been submitted
                            before.
                          </p>
                        )}
                        {voteSuccess && !msgError && !demoError && (
                          <p>
                            Success! your messages are added to the Orchard Page
                            for community votes.{" "}
                            <a href="/orchard/messages">
                              {" "}
                              Click here to view your message
                            </a>
                            .
                          </p>
                        )}
                        {showEdit && (
                          <Form className="edit-message-info mt-4 p-3 border">
                            <h5 className="my-3">
                              Answer these questions to help Peach provide more
                              accurate insights to you
                            </h5>
                            <div className="mt-3">
                              <p>
                                1. Were you trying to set up a date in this
                                message?
                              </p>
                              <div className="d-flex my-3">
                                <Form.Check
                                  className="w-50"
                                  type="checkbox"
                                  label="Yes"
                                  name="date_arrange"
                                  checked={dateArrangedCheck === true}
                                  onChange={() => setDateArranged(true)}
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="No"
                                  name="not_date_arrange"
                                  checked={dateArrangedCheck === false}
                                  onChange={() => setDateArranged(false)}
                                />
                              </div>
                            </div>
                            <div className="my-5">
                              <p>
                                2. Did you eventually go on a date with the
                                match since this message?
                              </p>
                              <div className="d-flex">
                                <Form.Check
                                  type="checkbox"
                                  className="w-50"
                                  label="Yes"
                                  name="date_happened"
                                  checked={dateHappenedCheck === true}
                                  onChange={() => setDateHappened(true)}
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="No"
                                  name="date_happened"
                                  checked={dateHappenedCheck === false}
                                  onChange={() => setDateHappened(false)}
                                />
                              </div>
                            </div>
                            {editMsgLoading && (
                              <p className="text-primary mb-5">
                                Thank you for waiting. This will take about half
                                a minute.
                              </p>
                            )}
                            <Button
                              className="mb-4 px-5 d-flex mx-auto"
                              onClick={() => handleEditMessage(selectedMsgId)}
                            >
                              {editMsgLoading ? (
                                <Spinner animation="border" variant="primary" />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </Form>
                        )}
                        {showEditSuccessMsg && (
                          <p className="text-primary">
                            Your changes are submitted and your data is being
                            reprocessed!
                          </p>
                        )}
                      </>
                    )}
                </Col>
              </Row>
            </>
          ) : (
            <img
              src={CommunityVotingImg}
              alt="community-voting"
              className="w-100 mx-5"
            />
          )}
        </Container>
      </Section>
    </>
  );
}
