import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./StatsCard.scss";

export default function StatsCard({ cardData, cardIndex, bgColor, handleShowInsight }) {
  let { statsCardTitle, resultStr } = cardData;

  return (
    <>
      {Object.keys(cardData).length > 0 ? (
        <div
          className="card border-0 text-center p-4 m-2"
          style={{
            backgroundColor: `${bgColor}`,
          }}
        >
          <>
            <h6>
              {statsCardTitle} <strong>{resultStr}</strong>.
            </h6>

            <button
              onClick={() => handleShowInsight(cardIndex)}
              className="swipe-btn pl-2 mt-5 p-0 bg-transparent border-0 "
            >
              <small>
                <u>Show more</u>
              </small>
            </button>
          </>
        </div>
      ) : (
        <Spinner animation="border" variant="primary" />
      )}
    </>
  );
}
