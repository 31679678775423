import React from "react";
import { Container } from "react-bootstrap";
import Section from "../Section";

export default function PeachProfile({ email, bg, size, textColor, title }) {
  return (
    <Section bg={bg} textColor={textColor} size={size}>
      <Container>
        <div className="title-header d-flex justify-content-between">
          <h2>{title}</h2>
        </div>
        <hr></hr>
        <p>
          Email: {email}
          <small className="text-secondary">
            {" "}
            {"(we don't share your email with anyone)"}
          </small>
        </p>
      </Container>
    </Section>
  );
}
